import React, {Component} from 'react';
import {StyledDashboardQueriesTableStatus} from "./DashboardQueries.styled";

class QueryStatus extends Component<any, any> {
    state = {
        bg: '#fff',
        color: '#black',
        text: '-'
    }


    /*
     * This method is used to
     * load variant and render it
     */

    private loadVariant() {
        switch (this.props.variant) {
            case 'new': {
                this.setState({
                    bg: '#FEF9C3',
                    color: '#CA8A04',
                    text: 'Nowe'
                });
                break;
            }

            case 'answered': {
                this.setState({
                    bg: '#DCFCE7',
                    color: '#16A34A',
                    text: 'Odpowiedziano'
                });
                break;
            }

            case 'verified': {
                this.setState({
                    bg: '#CFFAFE',
                    color: '#0284C7',
                    text: 'Zweryfikowano'
                });
                break;
            }

            case 'set': {
                this.setState({
                    bg: '#F3F4F6',
                    color: '#4B5563',
                    text: 'Zadano'
                });
                break;
            }

            default: {
                break
            }
        }
    }


    componentDidMount() {
        this.loadVariant()
    }

    render() {
        return (
            <StyledDashboardQueriesTableStatus color={this.state.color} bg={this.state.bg}>
                {this.state.text}
            </StyledDashboardQueriesTableStatus>
        );
    }
}

export default QueryStatus;