/*
 * Project: ecrf-front.
 * Author: Levan Ostrowski
 * User: cod3venom
 * Date: 15.01.2022
 * Time: 09:25
*/

import AbstractResource from "./AbstractResource";
import axiosSecureInstance from "../../../../commons/axios/axiosSecureInstance";

export default class DocumentationService extends AbstractResource {
    constructor() {
        super(axiosSecureInstance);
    }
}