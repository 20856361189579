import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import {
  ElementResourceTransferElementResourceTransfersStatusIdRead,
  ElementResourceTransferElementResourceTransfersViewIdRead,
} from "../../../types/api.types";

interface TransferFile {
  data: any;
  status: ElementResourceTransferElementResourceTransfersStatusIdRead;
  viewLink: ElementResourceTransferElementResourceTransfersViewIdRead;
  name: any;
  randomize: any;
}

const initialState: TransferFile = {
  data: [],
  status: {},
  viewLink: {},
  name: [],
  randomize: {}
};

type updateElementParams = {
  elementId: string;
  ecrfId: string;
};

export const transferFile = createAsyncThunk(
  "transfer/file",
  async ({ elementId, ecrfId }: updateElementParams, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.put<any>(
        `/api/element_datas/${elementId}/transfer`,
        {
          ecrf: `/api/ecrves/${ecrfId}`,
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const transferName = createAsyncThunk("transfer/name", async (viewUrl: string, thunkAPI) => {
  try {
    const response = await axiosSecureInstance.get<any>(viewUrl);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const transferStatus = createAsyncThunk(
  "transfer/status",
  async (statusUrl: string, thunkAPI) => {
    try {
      const response =
        await axiosSecureInstance.get<ElementResourceTransferElementResourceTransfersStatusIdRead>(
          statusUrl
        );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const transferViewLink = createAsyncThunk(
  "transfer/viewLink",
  async (url: string, thunkAPI) => {
    try {
      const response =
        await axiosSecureInstance.get<ElementResourceTransferElementResourceTransfersViewIdRead>(
          url
        );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const elementResourceTransferSlice = createSlice({
  name: 'elementResourceTransfer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(transferFile.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(transferName.fulfilled, (state, action) => {
      state.name = action.payload;
    });
    builder.addCase(transferStatus.fulfilled, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(transferViewLink.fulfilled, (state, action) => {
      state.viewLink = action.payload;
    });
  },
});

export const selectTransferFile = (state: RootState) => state.ecrf.elementResourceTransfer.data;
export const selectTransferFileName = (state: RootState) => state.ecrf.elementResourceTransfer.name;
export const selectTransferFileStatus = (state: RootState) => state.ecrf.elementResourceTransfer.status;
export const selectTransferFileViewLink = (state: RootState) => state.ecrf.elementResourceTransfer.viewLink;

export default elementResourceTransferSlice.reducer;
