import { Box, Typography} from "@mui/material";
import {ROUTES_USERS} from "commons/constants/paths";
import {useAppDispatch} from "commons/store/hooks";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import ColoredBox from "components/ColoredBox";
import UserForm from "features/users/components/UserForm";
import {addUser} from "features/users/redux/usersSlice";
import {Formik} from "formik";
import {useSnackbar} from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

const addUserValidationSchema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    roles: yup.string().required(),
});

const UserAdd = () => {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const initialValues = {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        roles: "" as any,
        medicalCenters: [],
    };

    const handleSubmit = async (values: typeof initialValues) => {
        try {
            await dispatch(addUser(values));
            enqueueSnackbar(
                t('the-user-has-been-added-a-registration-confirmation-message-was-sent-to-the-user-s-e-mail-address-provided-in-the-form'),
                {
                    variant: 'success'
                }
            );
            navigate(ROUTES_USERS.LIST);
        } catch (error: any) {
            error &&
            enqueueSnackbar(error?.detail, {variant: 'error'});
        }
    };

    return (
        <DashboardLayoutWrapper>
            <ColoredBox p={1} width={352} maxWidth="100%" ml="auto" mr="auto">
                <Box mb={3.5}>
                    <Typography variant="h5">{t('new-user')}</Typography>
                </Box>
                <Formik
                    validationSchema={addUserValidationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    <UserForm/>
                </Formik>
            </ColoredBox>
        </DashboardLayoutWrapper>
    );
};

export default UserAdd;
