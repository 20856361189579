import styled, {css} from "styled-components";
import Drawer from "@mui/material/Drawer";
import {ReactNode} from "react";
import Typography, {TypographyProps} from "@mui/material/Typography";
import {COLOR_BG, COLOR_PRIMARY} from "../../../../constants/env";

export const StyledDrawerContentWrapper = styled.div`
  padding-top: 56px;
`;

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 350px;
    z-index: 1000;
    background-color: ${COLOR_BG};
  }
`;
export const StyledListItem = styled.li`
  padding: 6px 3px;
`;

export const StyledListLinkWrapper = styled.p<{ $isActive?: boolean }>`
  ${({$isActive}) =>
    $isActive &&
    css`
      border-right: 2px solid ${COLOR_PRIMARY};
    `}
`;

// interface StyledMenuLinkProps extends TypographyProps {
export type StyledMenuLinkProps = TypographyProps & {
    to?: string;
    href?: string;
    target?: string,
    component?: ReactNode;
}

export const StyledMenuLink = styled(Typography)<StyledMenuLinkProps>`
  text-decoration: none;
  font-size: 15px;
`;
