import { Box } from "@mui/material";
import React from "react";
import {
  StyledCenterTitle,
  StyledCenterTitleText,
  StyledGlobalMedicalStatsContainer,
  StyledStatsTab,
  StyledStatsTitleNumber,
  StyledSText1,
  StyledSText2,
} from "./DashboardStats.styled";
import { FluffyItem, PurpleItem } from "./StatsTypes";

export const MedicalCenterItem: React.FC<PurpleItem> = (props) => {
  return (
    <Box display="flex" flexDirection="column" mt={5}>
      <StyledCenterTitle>
        <StyledStatsTitleNumber> {props.number} </StyledStatsTitleNumber>
        <StyledCenterTitleText>{props.name}</StyledCenterTitleText>
      </StyledCenterTitle>

      <StyledGlobalMedicalStatsContainer>
        {props.item?.map((element: FluffyItem) => (
          <StyledStatsTab key={element.name}>
            <StyledSText1>{element.count}</StyledSText1>
            <StyledSText2>{element.name}</StyledSText2>
          </StyledStatsTab>
        ))}
      </StyledGlobalMedicalStatsContainer>
    </Box>
  );
};
