export default class ElementTable {
  /**
   * createColumns()
   * @param fields
   */
  public static createColumns(fields: any) {
    const columns: any[] = []
    fields?.forEach((el: any) => {
      switch (el.type) {
        case 'divider': {
          break;
        }
        default: {
          columns.push({
            id: el.id,
            label: el.title || el.label || el.name,
            name: el.name
          })
          break;
        }
      }
    })

    return columns;
  }
}
