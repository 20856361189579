import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import {
  UserForgotPasswordRequestInputUserUserWrite,
  UserResetPasswordRequestUserUserWrite,
  UserUserUserReadIdRead,
} from "types/api.types";
import axiosPublicInstance from "commons/axios/axiosPublicInstance";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import {getRoleByToken, getRoles, saveToken} from "commons/auth/tokens";

export interface UserUserUserReadIdReadExtended extends UserUserUserReadIdRead {
  role: string;
}

export interface UserState {
  userProfile: any;
}

const initialState: UserState = {
  userProfile: null as unknown as UserUserUserReadIdReadExtended,
};

export const login = createAsyncThunk(
  "user/login",
  // TODO swagger model for credentials
  async (values: any, thunkAPI) => {
    try {
      // TODO swagger model for Token
      const response = await axiosPublicInstance.post<any>(
        "/authentication",
        values
      );

      if (response.data.token) {
        saveToken(response.data.token);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgot-password",
  async (values: UserForgotPasswordRequestInputUserUserWrite, thunkAPI) => {
    try {
      await axiosPublicInstance.post("/api/users/forgot-password", values);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/reset-password",
  async (values: UserResetPasswordRequestUserUserWrite, thunkAPI) => {
    try {
      await axiosPublicInstance.post("/api/users/reset-password", values);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  'user/fetchUserProfile',
  async () => {
    const response = await axiosSecureInstance.get<UserUserUserReadIdRead>(
      '/api/me'
    );

    const roles = getRoles();
    // @todo roles from jwt token
    const role  = getRoleByToken();

    return {
      ...response.data,
      roles,
      role,
    };
  }
);

export const logout = createAsyncThunk("user/logout", async () => {
  await axiosSecureInstance.get("/logout");
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.userProfile = undefined;
    });
  },
});

export const selectUserProfile = (state: RootState) => state.core.user.userProfile;

export default userSlice.reducer;
