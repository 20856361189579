import React from 'react';
import { TextField, Tooltip } from "@mui/material";
import { Filter } from "../Filter";
import { useTranslation } from "react-i18next";

export interface FilterPanelProps {}

export const FilterPanel = (props: FilterPanelProps) => {
    const { t } = useTranslation();

    const filters: any[] = [
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('randomization-number')} arrow>
                    <TextField
                        label={t('randomization-number')}
                        value={filter?.['datas.ecrf.randomId'] || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'datas.ecrf.randomId')
                        }
                        fullWidth
                        variant={'outlined'}
                        size={'small'}
                    />
                </Tooltip>
            ),
            key: 'datas.ecrf.randomId',
        },
    ];

    return <Filter elements={filters} />;
};
