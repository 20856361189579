export const roles= {
    all: [
        'ROLE_SUPER_ADMIN',
        'ROLE_ADMIN',
        'ROLE_CRO',
        'ROLE_MONITOR',
        'ROLE_MEDICAL_MONITOR',
        'ROLE_COORDINATOR',
        'ROLE_DATA_MANAGER',
        'ROLE_DATA_MANAGER_RTG',
        'ROLE_DATA_MANAGER_ECHO',
        'ROLE_DATA_ENTRY',
        'ROLE_DATA_ENTRY_CARDIOLOGIST',
        'ROLE_SPONSOR',
        'ROLE_C_RESEARCHER',
        'ROLE_RESEARCHER',
        'ROLE_PHARMACIST',
        'ROLE_BLINDED_NURSE',
        'ROLE_UNBLINDED_NURSE',
        'ROLE_CENTRAL_ASSESSMENT',
        'ROLE_QUERY_MANAGER',
        'ROLE_USER',
    ],
    control: [
        'ROLE_CRO',
        'ROLE_MONITOR',
        'ROLE_MEDICAL_MONITOR',
        'ROLE_DRUG_DATA_ENTRY',
        'ROLE_CENTRAL_ASSESSMENT',
        'ROLE_SPONSOR'
    ],
    dataManager: [
        'ROLE_DATA_MANAGER',
        'ROLE_DATA_MANAGER_RTG',
        'ROLE_DATA_MANAGER_ECHO',
    ],
    monitor: [
        'ROLE_CRO',
        'ROLE_COORDINATOR',
        'ROLE_MONITOR',
        'ROLE_MEDICAL_MONITOR',
        'ROLE_SPONSOR'
    ],
    readonly: [
        'ROLE_COORDINATOR',
        'ROLE_SPONSOR'
    ],
    pharmacist: [
        'ROLE_PHARMACIST',
        'ROLE_UNBLINDED_NURSE'
    ],
    research: [
        'ROLE_CRO',
        'ROLE_MONITOR',
        'ROLE_MEDICAL_MONITOR',
        'ROLE_COORDINATOR',
        'ROLE_DATA_MANAGER',
        'ROLE_DATA_MANAGER_RTG',
        'ROLE_DATA_MANAGER_ECHO',
        'ROLE_DATA_ENTRY',
        'ROLE_DATA_ENTRY_CARDIOLOGIST',
        'ROLE_SPONSOR',
        'ROLE_C_RESEARCHER',
        'ROLE_RESEARCHER',
        'ROLE_BLINDED_NURSE',
        'ROLE_CENTRAL_ASSESSMENT',
        'ROLE_QUERY_MANAGER',
    ],
    researcher: [
        'ROLE_C_RESEARCHER',
        'ROLE_RESEARCHER',
        'ROLE_DATA_ENTRY',
    ],
    admin: [
        'ROLE_SUPER_ADMIN',
        'ROLE_ADMIN'
    ],
    staffMember: [
        'ROLE_C_RESEARCHER',
        'ROLE_RESEARCHER',
        'ROLE_DATA_ENTRY',
        'ROLE_DATA_MANAGER',
        'ROLE_DATA_MANAGER_RTG',
        'ROLE_DATA_MANAGER_ECHO',
        'ROLE_CENTRAL_ASSESSMENT',
        'ROLE_BLINDED_NURSE',
        'ROLE_PHARMACIST',
        'ROLE_UNBLINDED_NURSE',
        'ROLE_DRUG_DATA_ENTRY',
    ],
    query: [
        'ROLE_CRO',
        'ROLE_COORDINATOR',
        'ROLE_MONITOR',
        'ROLE_MEDICAL_MONITOR',
        'ROLE_SPONSOR',
        'ROLE_QUERY_MANAGER'
    ],
};

