import React, { useEffect, useState } from 'react';
import { Notification } from 'types/api.types';
import { selectUserProfile } from 'core/redux/userSlice';
import { useAppDispatch, useAppSelector } from 'commons/store/hooks';
import NavBar from './../DashboardNavBar';
import DashboardSidebarBar, { SidebarItem } from './../DashboardSidebarBar';
import DashboardNotifyBar from './../DashboardNotifyBar';
import {
    ROUTES_MEDICAL_CENTERS,
    ROUTES_USERS,
    ROUTES_DASHBOARD,
    ROUTES_DRUG,
    ROUTES_DRUG_MANAGEMENT,
    ROUTES_DOCUMENTATION_MANAGEMENT,
} from 'commons/constants/paths';
import { roles } from 'commons/auth/roles';
import { fetchNotifications, selectNotifications } from 'features/notifications/redux/notificationsSlice';
import { RESEARCH } from 'commons/constants/env';
import { useTranslation } from "react-i18next";
import { fetchNavigations, selectNavigations } from "../../../../../features/page/redux/navigationSlice";

export interface NavProps {
    notificationsData?: {
        newNotificationsCounter?: number;
        notificationsListOptions: Notification[];
    };
}

const Nav = () => {
    const dispatch = useAppDispatch();
    const navigation = useAppSelector(selectNavigations);
    const notifications = useAppSelector(selectNotifications);
    const userProfile = useAppSelector(selectUserProfile);
    const { t } = useTranslation();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isNotifyListOpen, setIsNotifyListOpen] = useState(false);
    const itemsPerPage = 15;

    const fetchData = async () => {
        if (!['EMPATHY', 'IMPROVE', 'REFSAL'].includes(RESEARCH)) {
            await dispatch(fetchNavigations({
                pagination: true,
                itemsPerPage,
                page: 1,
                location: 1,
            }));
        }
    };

    const fetch = () => {
        dispatch(fetchNotifications({ page: 1, itemsPerPage }));
    };

    useEffect(() => {
        fetchData();
        fetch();
        const interval = setInterval(async () => {
            await fetch;
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    let allowed       = userProfile.roles.some((role: string) => roles.admin.includes(role));
    let drugsAllowed  = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.control.includes(role)
    );
    let dashboard     = userProfile.roles.some((role: string) => roles.all.includes(role));
    let documentation = dashboard;

    const navVariant = () => {
        const attr = {
            "target": "_blank"
        };

        switch (RESEARCH) {
            case 'IMPROVE': {
                const sidebarItemsData: SidebarItem[] = [
                    {
                        variant: 'routerLink',
                        label: t('main-panel'),
                        subLabel: t('main-panel-desc'),
                        to: ROUTES_DASHBOARD.DASHBOARD,
                        isShowing: true,
                    },
                    {
                        variant: 'routerLink',
                        label: t('drug-management-module'),
                        subLabel: t('drug-management-module-desc'),
                        to: ROUTES_DRUG_MANAGEMENT.MODULE,
                        isShowing: drugsAllowed,
                    },
                    {
                        variant: 'routerLink',
                        // label: t('document-management-module'),
                        label: t('central-reports'),
                        subLabel: t('document-management-module-desc'),
                        to: ROUTES_DOCUMENTATION_MANAGEMENT.MODULE,
                        isShowing: documentation,
                        renderBottomLine: true,
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Plan monitorowania badania',
                        to: 'https://api.improve-mc.pl/media/docs/Monitoring Plan IMPROVE-MC 16.08.2021.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Świadoma zgoda pacjenta na udział w badaniu klinicznym',
                        to: 'https://api.improve-mc.pl/media/docs/ICF_IMPROVE-MC 5.1_z dn. 16.06.2023 (1).pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Protokół badania',
                        to: 'https://api.improve-mc.pl/media/docs/Protocol IMPROVE_MC 6.0.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Instrukcja eCRF',
                        to: 'https://api.improve-mc.pl/media/docs/Manual Improve Wersja 1.0_.pdf',
                        isShowing: true,
                        renderBottomLine: true,
                        attr: attr
                    },
                    {
                        variant: 'routerLink',
                        label: t('medical-centers'),
                        to: ROUTES_MEDICAL_CENTERS.LIST,
                        isShowing: allowed,
                    },
                    {
                        variant: 'routerLink',
                        label: t('users'),
                        to: ROUTES_USERS.LIST,
                        isShowing: allowed,
                    },
                ];

                return sidebarItemsData;
            }
            case 'EMPATHY': {
                const sidebarItemsData: SidebarItem[] = [
                    {
                        variant: 'routerLink',
                        label: t('main-panel'),
                        subLabel: t('main-panel-desc'),
                        to: ROUTES_DASHBOARD.DASHBOARD,
                        isShowing: dashboard,
                    },
                    {
                        variant: 'routerLink',
                        label: t('drug-management-module'),
                        subLabel: t('drug-management-module-desc'),
                        to: ROUTES_DRUG_MANAGEMENT.MODULE,
                        isShowing: drugsAllowed,
                    },
                    {
                        variant: 'routerLink',
                        label: t('document-management-module'),
                        subLabel: t('document-management-module-desc'),
                        to: ROUTES_DOCUMENTATION_MANAGEMENT.MODULE,
                        isShowing: documentation,
                        renderBottomLine: true,
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Informacja dla pacjenta',
                        subLabel: '- Wersja 2.2, 29.10.2021',
                        to:
                            'https://api.empathytrial.com/media/docs/EMPHATY%20informacja%20dla%20pacjenta%20ver%202.2%2029OCT2021.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Zgoda na badanie genetyczne',
                        subLabel: '- Wersja 2.1, 21.09.2021',
                        to:
                            'https://api.empathytrial.com/media/docs/Zgoda%20na%20badanie%20genetyczne%20EMPATHY%20ver%202.1%2021SEP2021.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Protokół badania',
                        subLabel: '- Wersja 3.0, 27.06.2022',
                        to:
                            'https://api.empathytrial.com/media/docs/EMPATHY%20PROTOCOL_20.07.2021MP_Wersja%20do%20submisji_11.06.2022.docx%20(1).pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Protokół badania',
                        subLabel: '- Wersja 2.0, 20.07.2021',
                        to: 'https://api.empathytrial.com/media/docs/EMPATHY%20PROTOCOL_28.07.2021MP2.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Patient identification Log',
                        subLabel: '- Wersja 1.0, 12.09.2021',
                        to:
                            'https://api.empathytrial.com/media/docs/Patient%20Identification%20Log_V1.0_12.09.2021_EMPATHY.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'IMP Temperature Record Log',
                        subLabel: '- Wersja 1.0, 12.09.2021',
                        to:
                            'https://api.empathytrial.com/media/docs/IMP%20Temperature%20Record%20Log_V1.0_12.09.2021_EMPATHY.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Laboratory Shipment Form',
                        subLabel: '- Wersja 1.0, 19.12.2021',
                        to:
                            'https://api.empathytrial.com/media/docs/Laboratory%20Shipment%20Form%20Version%201.0_19.12.2021_EMPATHY.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Monitor Visit Log',
                        subLabel: '- Wersja 1.0, 12.09.2021',
                        to:
                            'https://api.empathytrial.com/media/docs/Monitor%20Visit%20Log_V1.0_12.09.2021_EMPATHY.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Confirmation of receipt',
                        subLabel: '- Wersja 1.0, 12.09.2021',
                        to:
                            'https://api.empathytrial.com/media/docs/Confirmation%20of%20receipt%20Version%201.0_12.09.2021_EMPATHY.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Instrukcja ECRF',
                        subLabel: '- Wersja 1.0, 10.03.2022',
                        to: 'https://api.empathytrial.com/media/docs/instrukcja%20dla%20badaczy.pdf',
                        isShowing: true,
                        renderBottomLine: true,
                        attr: attr
                    },
                    {
                        variant: 'routerLink',
                        label: t('medical-centers'),
                        to: ROUTES_MEDICAL_CENTERS.LIST,
                        isShowing: allowed,
                    },
                    {
                        variant: 'routerLink',
                        label: t('users'),
                        to: ROUTES_USERS.LIST,
                        isShowing: allowed,
                    },
                ];

                return sidebarItemsData;
            }
            case 'REFSAL': {
                const sidebarItemsData: SidebarItem[] = [
                    {
                        variant: 'routerLink',
                        label: t('main-panel'),
                        subLabel: t('main-panel-desc'),
                        to: ROUTES_DASHBOARD.DASHBOARD,
                        isShowing: true,
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Lista kontaktowa',
                        to: 'https://api.refsal.pl/media/docs/Contact%20list_V3.0_04.08.2022.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Plan zarządzania bezpieczeństwem',
                        to: 'https://api.refsal.pl/media/docs/Safety%20Monitoring%20Plan_REFSAL_V3.0_04.08.2022.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Świadoma zgoda pacjenta na udział w badaniu klinicznym',
                        to: 'https://api.refsal.pl/media/docs/ICF_REFSAL_wersja%204.1%2016.08.2022_znak.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Streszczenie protokołu badania',
                        to: 'https://api.refsal.pl/media/docs/Streszczenie_badania.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Protokół badania',
                        to: 'https://api.refsal.pl/media/docs/Protokół REFSAL v 6.0_24.05.2023_CLEAN.pdf',
                        isShowing: true,
                        attr: attr
                    },
                    {
                        variant: 'pdfLink',
                        label: 'Instrukcja eCRF',
                        to: 'https://api.refsal.pl/media/docs/INSTRUKCJA%20OBS%C5%81UGI%20SYSTEMU%20eCRF.pdf',
                        isShowing: true,
                        renderBottomLine: true,
                        attr: attr
                    },
                    {
                        variant: 'routerLink',
                        label: t('medical-centers'),
                        to: ROUTES_MEDICAL_CENTERS.LIST,
                        isShowing: allowed,
                    },
                    {
                        variant: 'routerLink',
                        label: t('users'),
                        to: ROUTES_USERS.LIST,
                        isShowing: allowed,
                    },
                    {
                        variant: 'routerLink',
                        label: t('drugs'),
                        to: ROUTES_DRUG.LIST,
                        isShowing: allowed,
                    },
                ];

                return sidebarItemsData;
            }
            default: {
                const sidebarItemsData: SidebarItem[] = [
                    {
                        variant: 'routerLink',
                        label: t('main-panel'),
                        subLabel: t('main-panel-desc'),
                        to: ROUTES_DASHBOARD.DASHBOARD,
                        isShowing: true,
                    },
                    {
                        variant: 'routerLink',
                        label: t('drug-management-module'),
                        subLabel: t('drug-management-module-desc'),
                        to: ROUTES_DRUG_MANAGEMENT.MODULE,
                        isShowing: drugsAllowed,
                    },
                    {
                        variant: 'routerLink',
                        label: t('document-management-module'),
                        subLabel: t('document-management-module-desc'),
                        to: ROUTES_DOCUMENTATION_MANAGEMENT.MODULE,
                        isShowing: true,
                        renderBottomLine: true,
                    },
                ];

                const navItems: SidebarItem[] = []
                let length = navigation.length;

                for (const navItem of navigation) {
                    navItems.push({
                        variant: 'routerLink',
                        label: navItem?.title,
                        subLabel: navItem?.description,
                        to: navItem?.src,
                        isShowing: true,
                        renderBottomLine: (!--length),
                        attr: navItem?.options?.attr
                    });
                }

                const sidebarSecondItems: SidebarItem[] = [
                    {
                        variant: 'routerLink',
                        label: t('medical-centers'),
                        to: ROUTES_MEDICAL_CENTERS.LIST,
                        isShowing: allowed,
                    },
                    {
                        variant: 'routerLink',
                        label: t('users'),
                        to: ROUTES_USERS.LIST,
                        isShowing: allowed,
                    },
                ];

                return [...sidebarItemsData, ...navItems, ...sidebarSecondItems];
            }
        }
    };

    return (
        <>
            <NavBar
                onBurgerIconClick={() => setIsSidebarOpen((prev) => !prev)}
                newNotificationsCounter={notifications?.filter((el) => el.seenAt === null).length}
                onNotifyIconClick={() => setIsNotifyListOpen((prev) => !prev)}
            />
            <DashboardSidebarBar
                isOpen={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
                sidebarItemsData={navVariant()}
                userName={`${userProfile.firstname} ${userProfile.lastname}`}
                userEmail={userProfile.email}
                userRole={userProfile.roles[0]}
            />
            <DashboardNotifyBar
                isOpen={isNotifyListOpen}
                onClose={() => setIsNotifyListOpen((prev) => !prev)}
                notifications={notifications}
            />
        </>
    );
};

export default Nav;