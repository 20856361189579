import React from "react";
import { GridCheckIcon, GridCloseIcon, GridRenderCellParams } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import DataGridHelper from "./DataGridHelper";
import DateTime from "../../../core/helpers/date/DateTime";
import { DATE_FORMAT, DATETIME_FORMAT } from "../../../commons/constants/env";

interface GridCellProps {
    type?: string;
    options?: any;
    value?: any;
    params: GridRenderCellParams | any;
}

const GridCell = React.memo(function GridCell(
    props: GridCellProps,
) {
    const theme = createTheme();
    const { options, params } = props;

    const renderElement = () => {
        let data = params?.value?.value ?? params?.value;
        const valueType = DataGridHelper.retype(data?.type || props?.type);

        if (params.colDef.valueFormatter && data instanceof Object) {
            data = params.colDef.valueFormatter(params);
        }

        let valueOptions = options
        if (params.colDef.valueOptions && data instanceof Object) {
            valueOptions = params.colDef.valueOptions(params);
        }

        if (!valueOptions) {
            valueOptions = options;
        }

        switch (valueType) {
            case 'boolean':
            case 'checkbox': {
                return (data)
                    ? <GridCheckIcon style={{color: theme.palette.success.light,}} />
                    : <GridCloseIcon style={{color: grey[500]}} />;
            }
            case 'date': {
                return DateTime.toString(data, DATE_FORMAT)
            }
            case 'dateTime':
            case 'datetime': {
                return DateTime.toString(data, DATETIME_FORMAT)
            }
            case 'choice':
            case 'select':
            case 'singleSelect':
            case 'radio': {
                const option = valueOptions.find(
                    (p: any) => String(p.value) === String(data)
                        || String(p.value) === String(params.value?.value)
                )

                return option?.label || String(data);
            }
            default: {
                return data
            }
        }
    }

    return renderElement();
});

export default GridCell