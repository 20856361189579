import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch } from "commons/store/hooks";
import TextFieldFormik from "components/Form/TextFieldFormik";
import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import ColoredBox from "components/ColoredBox";
import { useTheme } from "@mui/material/styles";
import yup from "commons/yup";
import { Link } from "react-router-dom";
import { ROUTES_CORE } from "commons/constants/paths";
import { resetPassword } from "core/redux/userSlice";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const validationSchema = (t: any) => yup.object({
  code: yup.string().required(),
  plainPassword: yup
    .string()
    .min(8, t('the-password-must-consist-of-at-least-8-characters'))
    .required(),
  plainPasswordConfirmation: yup
    .string()
    .required()
    .oneOf(
      [yup.ref('plainPassword'), ''],
      t('the-passwords-do-not-match-enter-the-same-password-as-in-the-new-password-field')
    ),
});

type ResetPasswordParams = {
  token: string;
};

const ResetPassword = () => {
  const { token } = useParams<ResetPasswordParams>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { t } = useTranslation();
  const [isProcessing, setProcessing] = useState(false)

  const initialValues = {
    code: "",
    plainPassword: "",
    plainPasswordConfirmation: "",
  };

  const handleSubmit = async (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>
  ) => {
    setProcessing(true);

    try {
      await dispatch(
        resetPassword({
          code: values.code,
          token: token || '',
          plainPassword: values.plainPassword,
        })
      );
      enqueueSnackbar(t('your-password-has-been changed-go-to-the-login-page'), {
        variant: 'success'
      });
      formikHelpers.resetForm();

      navigate(ROUTES_CORE.LOGIN);
    } catch (error: any) {
      enqueueSnackbar(error?.detail, {variant: 'error'});
    }

    setProcessing(false);
  };

  return (
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      pl={2}
      pr={2}
    >
      <ColoredBox p={1} width={352} maxWidth="100%">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema(t)}
        >
          <Form>
            <Typography variant="h5">{t('password-change')}</Typography>
            <Box mt={3.5}>
              <TextFieldFormik
                margin="dense"
                name="code"
                label={t('sms-code')}
                type="text"
                variant="filled"
                fullWidth
              />
            </Box>
            <Box mt={3.5}>
              <TextFieldFormik
                margin="dense"
                name="plainPassword"
                label={t('new-password')}
                type="password"
                variant="filled"
                fullWidth
              />
            </Box>
            <Box mt={3.5}>
              <TextFieldFormik
                margin="dense"
                name="plainPasswordConfirmation"
                label={t('confirm-new-password')}
                type="password"
                variant="filled"
                fullWidth
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Box boxShadow={theme.shadows[4]}>
                <Button type="submit" color="primary" variant="contained" disabled={isProcessing}>
                  {t('change-password')}
                </Button>
              </Box>
            </Box>
            <Box mt={1.5} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                component={Link}
                to={ROUTES_CORE.LOGIN}
                sx={{ textTransform: 'unset' }}
              >
                {t('login')}
              </Button>
            </Box>
          </Form>
        </Formik>
      </ColoredBox>
    </Box>
  );
};

export default ResetPassword;
