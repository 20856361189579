import { useField } from "formik";
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { useAppSelector } from "../../commons/store/hooks";
import { selectEcrf } from "../../features/ecrf/redux/ecrfSlice";
import React, { useEffect } from "react";
import { isValid, parseISO } from "date-fns";

export interface DatePickerFormikProps
    extends Omit<DatePickerProps<any>,
        "name" | "value" | "onChange" | "onBlur" | "error"> {
    name: string;
    status?: boolean;
    data?: any;
    fullWidth?: boolean;
    helperText?: any;
    placeholder?: string;
}

const DatePickerFormik = ({
  data,
  name,
  status,
  fullWidth,
  helperText,
  placeholder,
  ...props
}: DatePickerFormikProps) => {
    const [field, meta, form] = useField(name);
    const ecrf = useAppSelector(selectEcrf);

    const { value } = meta;
    const { setValue } = form;

    /**
     * The 'referenceDate' argument is used to provide values for date parts that are not provided
     * by the 'value' argument. referenceDate will substitute zero for hours, minutes and seconds.
     * @see https://date-fns.org/v2.29.1/docs/parse
     */
    const dateFnsParse = parseISO(field.value);

    useEffect(() => {
        if (data && value !== data[0]) {
            if (data.length === 0) {
                setValue('')
            }

            if (data.length > 0) {
                setValue(data[0])

            }
        }
    }, [ecrf, data])

    return (
        <DatePicker
            label={field.name}
            disabled={status}
            defaultValue={isValid(dateFnsParse) ? dateFnsParse : null}
            onChange={(newValue) => setValue(newValue)}
            onClose={() => form.setTouched(true)}
            slotProps={{
                textField: {
                    error: meta.touched && Boolean(meta.error),
                    fullWidth: fullWidth,
                    helperText: (meta.touched && meta.error) || helperText,
                    variant: 'outlined'
                }
            }}
            {...props}
        />
    );
};

export default DatePickerFormik;
