import styled from "styled-components";
import Toolbar from "@mui/material/Toolbar";
import Badge from "@mui/material/Badge";
import MenuItem, {
    MenuItemProps as MuiMenuItemProps,
} from "@mui/material/MenuItem";
import {ReactNode} from "react";

export const StyledToolbar = styled(Toolbar)`
  height: 56px;
  min-height: 56px;
  color: #060A32;
`;

export const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 3px;
  }
`;

interface StyledMenuItemProps extends MuiMenuItemProps {
    to?: string;
    children?: ReactNode;
}

export const StyledSystemVersion = styled.div`
  font-size: 20px;
  
  @media (max-width: 900px) {
    display: none;
  }
`;

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>``;
