import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import Table from "components/Table";
import React, { useEffect, useState } from "react";
import {
    fetchCollection,
    fetchElementDataManagement,
    getSectionId,
    selectEcrf
} from "features/ecrf/redux/ecrfSlice";
import { useSnackbar } from "notistack";
import { Link, useSearchParams } from "react-router-dom";
import { ROUTES_ECRF } from "commons/constants/paths";
import { CallMade, MoreVert } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import styled from "styled-components";
import ModalForm from "components/Form/Modal/ModalForm";
import DateTime from "core/helpers/date/DateTime";
import { DATETIME_FORMAT } from "commons/constants/env";
import { FilterPanel } from "./FilterPanel";
import {useTranslation} from "react-i18next";

const MenuStyled = styled(Menu)`
  .MuiPaper-elevation8 {
    box-shadow: rgba(50, 50, 93, 0) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-top: 45px;
    margin-left: -20px;  
  }
`

const DashboardDataManagement = () => {
    const dispatch = useAppDispatch();
    const ecrf = useAppSelector(selectEcrf);
    const [searchParams, setSearchParams] = useSearchParams();
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();

    const [page, setPage] = useState(1);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const [row, setRow] = useState<any>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: any, row: any) => {
        setAnchorEl(event.currentTarget)
        setRow(row)
    };

    /**
     * Handle close
     */
    const handleClose = () => {
        setAnchorEl(null)
        setRow(null)
    };

    /**
     * Open modal
     */
    const openModal = async (type: string) => {
        handleClose()

        const dataId      = row?.id
        const ecrfId      = row?._embedded?.ecrf?.id    || row?.ecrf?.id
        const elementId   = row?._embedded?.element?.id || row?.element?.id
        const elementName = row?._embedded?.element?.name || row?.element?.name
        const elements    = row?._embedded?.elements    || row?.elements

        await dispatch(
            fetchCollection({...row, dataId, elements, ecrfId, elementId, elementName, type}),
        ).unwrap()
    };

    /**
     * Fetch data
     */
    const fetchData = async (initial?: boolean) => {
        setLoading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/hal+json',
                    Accept: 'application/hal+json',
                },
                params: {
                    pagination: true,
                    page,
                    itemsPerPage: 30,
                    ...Object.fromEntries(searchParams)
                },
            }

            const response = await dispatch(fetchElementDataManagement(config)).unwrap()
            setData(response)
        } catch (error: any) {
            enqueueSnackbar(error.detail, {variant: 'error'});
        }

        setLoading(false)
    };

    useEffect(() => {
        // fetchVisitList().then()
        fetchData(true).then()
    }, [dispatch, page, ecrf]);

    useEffect(() => {
        fetchData().then()
    }, [searchParams]);

    const columns = () => {
        const columns: any[] = [
            {
                title: <Box>{t('randomization-number')}</Box>,
                render: (row: any) => (
                    <Box
                    >
                        {row?._embedded?.ecrf?.randomId}
                    </Box>
                ),
                key: 'randomId',
                options: {
                    sort: true,
                    sortOrder: {name: 'randomId', direction: 'desc'},
                },
            },
            {
                title: <Box ml="20px">{t('visit')}</Box>,
                render: (row: any) => (
                    <Box>
                        {row?.visitName}
                    </Box>
                ),
                key: 'name',
            },
            {
                title: <Box ml="20px">{t('element')}</Box>,
                render: (row: any) => (
                    <Box>
                        {row?._embedded?.element?.label}
                    </Box>
                ),
                key: 'name',
            },
            {
                title: <Box ml="20px">{t('date')}</Box>,
                render: (row: any) => (
                    <Tooltip style={{cursor: 'help'}} title={row?.data[0] ?? ''}>
                        <Box mt="20px" mb="20px">
                            {row?.data[0] ? (String(row?.data[0])?.substring(0, 100) + '...') : ''}
                        </Box>
                    </Tooltip>

                ),
                key: 'data',
            },
            {
                title: <Box ml="20px">{t('updated-at')}</Box>,
                render: (row: any) => (
                    <Box mt="20px" mb="20px">
                        {DateTime.toIso(row?.updatedAt, DATETIME_FORMAT)}
                    </Box>
                ),
                key: 'updatedAt',
            },
        ]

        columns.push({
            title: <Box ml="20px">ECRF</Box>,
            render: (row: any) => (
                <Box textAlign={'center'}>
                    <Box display="inline-flex">
                        <Button
                            component={Link}
                            disabled={!row?._embedded?.ecrf?.id || !row?.sectionId}
                            to={{
                                pathname:(ROUTES_ECRF.DETAILS_EXTENDED(
                                    row?._embedded?.ecrf?.id || '', row?.sectionId || ''
                                )),
                                hash: '#' + row?._embedded?.element?.id || ''
                            }}
                            onClick={() => {
                                if (row?._embedded?.ecrf?.id && row?.sectionId) {
                                    dispatch(getSectionId({section: row?.sectionId}))
                                }
                            }}
                            variant="outlined"
                            color="primary"
                            size="small"
                            endIcon={<CallMade/>}
                        >
                            {t('go')}
                        </Button>
                    </Box>
                </Box>
            ),
            key: 'ecrf',
        })

        columns.push({
            title: <Box>{t('action')}</Box>,
            render: (row: any) => (
                <Box textAlign={'center'}>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? true : undefined}
                        onClick={(event: any) => handleClick(event, row)}
                        variant="outlined"
                        color="primary"
                        size="small"
                        // disabled={isLoading || isDisabledButton}
                    >
                        <MoreVert/>
                    </Button>
                </Box>
            ),
            key: 'action',
        })

        return columns
    }

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            <FilterPanel/>
            <Table
                pagination={{
                    current: page,
                    pageSize: data?.itemsPerPage,
                    total: data?.totalItems,
                }}
                onChangePage={(page) => setPage(page)}
                data={data._embedded?.item || []}
                tableName={t('data-management')}
                columns={columns()}
                isLoading={isLoading}
            />

            <MenuStyled
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{'aria-labelledby': 'basic-button'}}
                style={{boxShadow: 'none'}}
            >
                <MenuItem
                    onClick={() => openModal('save')}>
                    <Typography variant="body2"> {t('edit')} </Typography>
                </MenuItem>
            </MenuStyled>

            <ModalForm/>
        </ColoredBox>
    );
};

export default DashboardDataManagement;
