import React, { useEffect } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import List from '@mui/material/List';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, CircularProgress } from '@mui/material';
import styled from 'styled-components';

import FileListView from './FileListView';
import { COLOR_PRIMARY, COLOR_PRIMARY_LIGHT } from '../../../commons/constants/env';

export interface Item {
    id: string;
    name: string;
    children: Item[];
    files: any[];
}

interface FileManagerProps {
    selectedFileCategoryId: string | null;
    handleChangeFileCategoryId: (id: string) => void;
    data: Item[];
    files: any[] | null;
    filesLoading: boolean;
    fetchFiles: (selectedFileCategoryId: string | null) => void;
}

const FileExplorerStyled = styled(List)`
    min-width: 316px;
    width: 316px;
    padding-right: 16px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${COLOR_PRIMARY_LIGHT};
        border-radius: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0);
    }
`;

const StyledTreeItemRoot = styled(TreeItem)`
      .Mui-focused, .Mui-selected, .Mui-selected.Mui-focused {
        background-color: rgba(0,0,0,0);
      },
    }
`;

export const StyledDocumentationDate = styled.input<any>`
    padding: 8px 20px;
    border: 1px solid ${COLOR_PRIMARY};
    border-radius: 5px;
    transition: 0.3s ease;
    background: none;
    height: 30px;
    cursor: pointer;

    ::-webkit-file-upload-button {
        display: none;
    }

    ::-webkit-inner-spin-button,
    ::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    &:hover {
        background-color: ${(props) => (props.readonly ? '' : COLOR_PRIMARY_LIGHT)};
        border: ${(props) => (props.readonly ? `1px solid ${COLOR_PRIMARY}` : `1px solid ${COLOR_PRIMARY}`)};
    }
`;

export const StyledLabel = styled.label<any>`
    padding: 8px 20px;
    border: 1px solid ${COLOR_PRIMARY};
    border-radius: 5px;
    transition: 0.3s ease;
    background: none;
    height: 30px;
    cursor: pointer;

    ::-webkit-file-upload-button {
        display: none;
    }

    ::-webkit-inner-spin-button,
    ::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    &:hover {
        background-color: ${(props) => (props.readonly ? '' : COLOR_PRIMARY_LIGHT)};
        border: ${(props) => (props.readonly ? `1px solid ${COLOR_PRIMARY}` : `1px solid ${COLOR_PRIMARY}`)};
    }
`;

function FileManager(props: FileManagerProps) {
    const { selectedFileCategoryId, handleChangeFileCategoryId } = props;

    useEffect(() => {
        if (selectedFileCategoryId) {
            props.fetchFiles(selectedFileCategoryId);
        }
    }, [selectedFileCategoryId]);

    const renderTree = (data: Item[], level: string = '') => {
        return data.map((item, i) => (
            <StyledTreeItemRoot
                key={item.id}
                nodeId={item.id.toString()}
                label={
                    <div
                        onClick={() => handleChangeFileCategoryId(item.id)}
                        style={{
                            fontWeight: item.id === selectedFileCategoryId ? 'bold' : 'normal',
                            cursor: 'pointer',
                        }}>
                        <span style={{ fontWeight: 'bold', color: COLOR_PRIMARY }}>{`${
                            level ? `${level}.${i + 1}` : `${i + 1}.`
                        }`}</span>{' '}
                        {`${item.name}`}
                    </div>
                }>
                {item.children && renderTree(item.children, `${level ? `${level}.${i + 1}` : `${i + 1}`}`)}
            </StyledTreeItemRoot>
        ));
    };

    return (
        <>
            <div style={{ display: 'flex', maxHeight: 'calc(100vh - 270px)' }}>
                <FileExplorerStyled>
                    <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                        {renderTree(props.data)}
                    </TreeView>
                </FileExplorerStyled>
                {props.filesLoading ? (
                    <Box display="grid" style={{ placeItems: 'center', height: 'calc(100vh - 300px)', width: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: '20px' }}>
                        <FileListView files={props.files ?? []} />
                    </div>
                )}
            </div>
        </>
    );
}

export default FileManager;
