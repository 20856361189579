import Nav from 'commons/wrappers/DashboardLayoutWrapper/components/Nav';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { Component } from 'react';
import {
    StyledDrugsButton,
    StyledDrugsLayout,
    StyledDrugsMultiSaveItem,
    StyledDrugsMultiSaveItemTitle,
    StyledDrugsMultiSaveSelected,
    StyledDrugsMultiSaveWindow,
    StyledDrugsTable,
    StyledDrugsTableBody,
    StyledDrugsTableHead,
    StyledDrugsWrapper,
} from './DrugsManagement.styled';
import axiosSecureInstance from 'commons/axios/axiosSecureInstance';
import { Box, CircularProgress, Slide } from '@mui/material';
import { DataLoading, Filter, PickDateTime, RowItem, SelectNumber } from '../components';
import { roles } from 'commons/auth/roles';
import { useSearchParams } from "react-router-dom";
import { editManyDrugManagement } from "../redux/drugManagementSlice";
import { useAppDispatch, useAppSelector } from "../../../commons/store/hooks";
import { fetchMedicalCenters, selectMedicalCenters } from "../../medicalCenters/redux/medicalCentersSlice";
import { fetchDrugTypes } from "../../drugs/redux/drugsSlice";
import { selectUserProfile } from "../../../core/redux/userSlice";
import { useTranslation } from "react-i18next";

const withRouter = (WrappedComponent: any) => (props: any) => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const medicalCenters = useAppSelector(selectMedicalCenters);
    const userProfile = useAppSelector(selectUserProfile);
    const { t } = useTranslation();

    return (
        <WrappedComponent
            {...props}
            dispatch={dispatch}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            medicalCenters={medicalCenters}
            userProfile={userProfile}
            t={t}
        />
    );
};

export interface IDrugsManagementRowProps {
    id: number;
    drugType?: string;
    drugNumber?: string;
    medicalCenterNumber?: string;
    dateOfTheRequest?: string;
    dateOfDelivery?: string;
    dateOfDeliveryConfirmation?: string;
    patientRandomizationNumber?: string;
    status?: string;
}

/**
 * @todo rebuild to functional component, if needed
 */
class DrugsManagement extends Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    state = {
        data: [] as any,
        isDataLoading: false,
        isMoreDataLoading: false,
        page: 1,
        totalItems: 0,
        countItems: 0,
        selectedRows: [] as any[],
        multiSelectData: null as any,
    };

    private loadMedicalCenters = async () => {
        try {
            await this.props.dispatch(fetchMedicalCenters({}))
        } catch (error: any) {
            alert(error?.message)
        }
    }

    private loadDrugTypes = async () => {
        try {
            await this.props.dispatch(fetchDrugTypes({}))
        } catch (error: any) {
            alert(error?.message)
        }
    }

    /**
     * This method is used to multi save option selectRow will create array of selected rows
     */
    private selectRow(row: any) {
        let newSelectedRowsArray: any[] = this.state.selectedRows;
        if (newSelectedRowsArray.includes(row)) {
            const removeRow = newSelectedRowsArray.filter((rowData: any) => rowData.id !== row.id)
            this.setState({selectedRows: removeRow});
        } else {
            this.setState({selectedRows: [row, ...this.state.selectedRows]});
        }
    }

    /**
     * This method is used to update state of multiselect modal
     */
    private setMultiData(object: any) {
        this.setState({multiSelectData: object});
    }

    /**
     * This method is used to clear selected list
     */
    private clearMulti() {
        this.setState({selectedRows: [], multiSelectData: null});
    }

    /**
     * This method is used to save and create request with multi select values
     */
    private async saveMulti() {
        let drugManagements = []

        /* Create array of object for selected drugs */
        for (const row of this.state.selectedRows) {
            const drugData = {
                deliveryAt: this.state.multiSelectData.deliveryAt,
                deliveredAt: this.state.multiSelectData.deliveredAt,
                expiredAt: this.state.multiSelectData.expiredAt,
                id: `/api/drug_managements/${row.id}`
            }
            drugManagements.push(drugData)
        }

        if (!this.state.multiSelectData.medicalCenter) return

        await this.editMultiData(this.state.multiSelectData.medicalCenter, {drugManagements})
        this.clearMulti()
        await this.fetchInitialData(true)
    }

    /**
     * This method is used to create request with multi-select data
     */
    private async editMultiData(id: string, data: any) {
        try {
            // await axiosSecureInstance.put(`${id}/drug_managements`, data)
            await this.props.dispatch(editManyDrugManagement({id, data}));
        } catch (error: any) {
            alert(error?.detail)
        }
    }

    /**
     * Create request and return specific data with range
     */
    private async getData(page: any = 1) {
        if (this.props?.searchParams?.size > 0) {
            const response = await axiosSecureInstance.get<any>(
                `/api/drug_managements`, {
                    headers: {
                        accept: 'application/hal+json',
                    },
                    params: {
                        pagination: true,
                        page: page,
                        itemsPerPage: 100,
                        ...Object.fromEntries(this.props.searchParams)
                    },
                })

            return response.data
        } else {
            const response = await axiosSecureInstance.get<any>(
                `/api/drug_managements`, {
                    headers: {
                        accept: 'application/hal+json',
                    },
                    params: {
                        pagination: true,
                        page: page,
                        itemsPerPage: 100,
                    }
                }
            );
            return response.data
        }
    }

    /**
     * This method is used to fetch initial data and save it to state
     * @param reload
     */
    private async fetchInitialData(reload?: boolean) {
        if (!reload) this.setState({isDataLoading: true});

        try {
            this.setState({page: 1});

            const newData = await this.getData(1);
            if (reload) this.setState({data: []})

            this.setState({
                data: newData?._embedded?.item,
                page: 1,
                totalItems: newData?.totalItems,
                countItems: newData?._embedded?.item.length
            });

        } catch (error: any) {}

        this.setState({isDataLoading: false});
    }

    /**
     * Reload data
     * @param page
     */
    private async reloadData(page: any = null) {
        this.setState({isDataLoading: true});

        try {
            const newData = await this.getData(page);
            this.setState({
                data: newData?._embedded?.item,
                page: 1,
                totalItems: newData?.totalItems,
                countItems: newData?._embedded?.item.length
            });

        } catch (error: any) {}

        this.setState({isDataLoading: false});
    }

    /**
     * This method is used to fetch more data and append it to data array
     */
    private async fetchMoreData() {
        if (!this.state.isMoreDataLoading) {
            this.setState({isMoreDataLoading: true});

            const page = this.state.page + 1;
            const newData = await this.getData(page);
            this.setState({
                data: [...this.state.data, ...newData?._embedded?.item],
                page: page,
                countItems: this.state.countItems + newData?._embedded?.item.length,
                isMoreDataLoading: false
            });
        }
    }

    componentDidMount() {
        this.loadDrugTypes();
        this.loadMedicalCenters();
        this.fetchInitialData();
    }

    componentDidUpdate(prevProps: any){
        if (prevProps?.searchParams !== this.props?.searchParams) {
            this.reloadData(1);
        }
    }

    render() {
        return (
            <>
                <Nav/>
                <StyledDrugsWrapper>
                    <StyledDrugsLayout>
                        <Filter
                            displaySerial={false}
                            displayType={this.props?.userProfile?.roles.some(
                                (role: string) => roles.admin.includes(role)
                            )}
                        />
                        <StyledDrugsTable>
                            <StyledDrugsTableHead>
                                <span/>
                                <span>{this.props?.t('type')}</span>
                                <span>{this.props?.t('number-on-package')}</span>
                                {this.props.full && <span>{this.props?.t('drug-series')}</span>}
                                <span>{this.props?.t('center-number')}</span>
                                <span>{this.props?.t('date-of-request')}</span>
                                <span>{this.props?.t('drug-expiration-date')}</span>
                                <span>{this.props?.t('date-of-sending-to-the-center')}</span>
                                <span>{this.props?.t('delivery-confirmation-date')}</span>
                                <span>{this.props?.t('randomization-date')}</span>
                                <span>{this.props?.t('status')}</span>
                                <span>{this.props?.t('action')}</span>
                            </StyledDrugsTableHead>

                            <StyledDrugsTableBody>
                                {this.state.isDataLoading ? (
                                    <DataLoading/>
                                ) : (
                                    <>
                                        {this.state.data?.map((row: any) => (
                                            <RowItem
                                                key={row?.id}
                                                data={row}
                                                selectRow={this.selectRow.bind(this)}
                                                selectedRows={this.state.selectedRows}
                                                displaySerial={false}
                                                readRandomId={false}
                                                unmaskDrugType={this.props.userProfile?.roles.some(
                                                    (role: string) => roles.admin.includes(role)
                                                )}
                                            />
                                        ))}
                                    </>
                                )}
                            </StyledDrugsTableBody>

                            {this.state.totalItems === 0 && !this.state.isDataLoading &&
                                <Box display={'grid'} width={'100%'} justifyContent={'center'}>
                                    <Box mt={5}> {this.props?.t('no-data')} </Box>
                                </Box>}

                            {/* Pokaż więcej button with loading logic */}
                            {!this.state.isDataLoading &&
                                (this.state.countItems > 0 && this.state.countItems < this.state.totalItems) && (
                                <Box m={5} display="grid" style={{placeItems: 'center'}}>
                                    <StyledDrugsButton
                                        onClick={() => this.fetchMoreData()}
                                        disabled={this.state.isMoreDataLoading}
                                    >
                                        {this.state.isMoreDataLoading ? (
                                            <CircularProgress size={24}/>
                                        ) : (
                                            <MoreHorizIcon/>
                                        )}
                                        {this.props?.t('show-more')}
                                    </StyledDrugsButton>
                                </Box>
                            )}
                        </StyledDrugsTable>

                        {/* Multi-select items modal*/}
                        <Slide direction={'up'} in={this.state.selectedRows.length > 0} mountOnEnter unmountOnExit>
                            <StyledDrugsMultiSaveWindow>
                                <StyledDrugsMultiSaveSelected>
                                    {this.state.selectedRows.map(
                                        (row: any) => <Box key={row?.id}>{row?.number}{" "}</Box>
                                    )}
                                </StyledDrugsMultiSaveSelected>

                                <StyledDrugsMultiSaveItem>
                                    <StyledDrugsMultiSaveItemTitle>
                                        {this.props?.t('center-number')}
                                    </StyledDrugsMultiSaveItemTitle>
                                    <SelectNumber
                                        centersList={this.props.medicalCenters?.data}
                                        setRowData={this.setMultiData.bind(this)}
                                        rowData={this.state.multiSelectData}
                                        id={-1}
                                    />
                                </StyledDrugsMultiSaveItem>

                                <StyledDrugsMultiSaveItem>
                                    <StyledDrugsMultiSaveItemTitle>
                                        {this.props?.t('drug-expiration-date')}
                                    </StyledDrugsMultiSaveItemTitle>
                                    <PickDateTime
                                        setRowData={this.setMultiData.bind(this)}
                                        rowData={this.state.multiSelectData}
                                        fieldType="expiredAt"
                                    />
                                </StyledDrugsMultiSaveItem>

                                <StyledDrugsMultiSaveItem>
                                    <StyledDrugsMultiSaveItemTitle>
                                        {this.props?.t('date-of-sending-to-the-center')}
                                    </StyledDrugsMultiSaveItemTitle>
                                    <PickDateTime
                                        setRowData={this.setMultiData.bind(this)}
                                        rowData={this.state.multiSelectData}
                                        fieldType="deliveryAt"
                                    />
                                </StyledDrugsMultiSaveItem>

                                <StyledDrugsMultiSaveItem>
                                    <StyledDrugsMultiSaveItemTitle>
                                        {this.props?.t('delivery-confirmation-date')}
                                    </StyledDrugsMultiSaveItemTitle>
                                    <PickDateTime
                                        setRowData={this.setMultiData.bind(this)}
                                        rowData={this.state.multiSelectData}
                                        fieldType="deliveredAt"
                                    />
                                </StyledDrugsMultiSaveItem>

                                <StyledDrugsMultiSaveItem>
                                    {this.state.multiSelectData &&
                                        <StyledDrugsButton onClick={this.saveMulti.bind(this)}>
                                            {this.props?.t('save')}
                                        </StyledDrugsButton>
                                    }
                                    <StyledDrugsButton style={{marginTop: '10px'}} onClick={this.clearMulti.bind(this)}>
                                        {this.props?.t('clear')}
                                    </StyledDrugsButton>
                                </StyledDrugsMultiSaveItem>

                            </StyledDrugsMultiSaveWindow>
                        </Slide>
                    </StyledDrugsLayout>
                </StyledDrugsWrapper>
            </>
        );
    }
}

export default withRouter(DrugsManagement);
