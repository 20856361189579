import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControlLabelProps } from "@mui/material/FormControlLabel";
import Radio, { RadioProps } from "@mui/material/Radio";
import { useField } from "formik";
import React, { ReactNode } from "react";

export interface RadioFormikProps
  extends Omit<RadioProps, "name" | "onChange" | "onBlur" | "error"> {
  name: string;
  label?: ReactNode;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  status?: boolean;
}

const RadioFormik = ({
  name,
  label,
  value,
  status,
  labelPlacement,
  ...props
}: RadioFormikProps) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  return (
    <FormControlLabel
      onBlur={field.onBlur}
      control={
        <Radio
          disabled={status || props.readOnly}
          checked={field.value === value}
          onChange={() => setValue(value)}
          name={field.name}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      disabled={status || props.readOnly}
    />
  );
};

export default RadioFormik;
