/*
 * Project: ecrf-front
 * Author: Levan Ostrowski
 * User: cod3venom
 * Date: 15.01.2022
 * Time: 09:27
 */

import { AxiosInstance } from 'axios';

export interface IDocUploaderResponse {
    id: string;
    name: string;
    category: string;
    contentUrl: string;
    size: string;
    createdAt: string;
    type: number;
    version: string;
}

export interface IDocUploaderProps {
    file: File;
    category?: string;
    ecrf?: string[];
    medicalCenters: string[];
    version: string;
}

export default class DocUploader {
    /**
     * HttpClient instance
     * @protected
     */
    private httpClient: AxiosInstance;

    /**
     * @param httpClient
     * @protected
     */
    constructor(httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }

    public async upload(doc: IDocUploaderProps): Promise<IDocUploaderResponse> {
        const formData = new FormData();
        formData.set('file', doc.file);
        formData.set('version', doc.version);

        if (doc.category) {
            formData.set('category', JSON.stringify({ id: doc.category }));
        }

        if (doc.ecrf) {
            formData.set('ecrf', JSON.stringify(doc.ecrf));
        }

        let medicalCenters = [];
        for (const id of doc.medicalCenters) {
            medicalCenters.push({ id: id });
        }

        formData.set('medicalCenters', JSON.stringify(medicalCenters));

        try {
            const response = await this.httpClient.post('/api/files', formData);

            const version = doc.version;
            const category = { id: doc.category };
            let updateData: any = { version, category };

            // @todo remove, if no needed (old logic update data/info file)
            if (!doc.ecrf) {
                if (medicalCenters.length > 0 && doc.category) {
                    updateData = { medicalCenters, version, category };
                }
                await this.httpClient.put(`/api/files/${response.data?.id}`, updateData);
            }

            return { ...response.data } as IDocUploaderResponse;
        } catch (error: any) {
            throw error;
        }
    }
}
