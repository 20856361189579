import { Box, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'commons/store/hooks';
import Dependency from 'core/helpers/Dependency';
import QueriesPanel from 'features/ecrf/components/QueriesPanel';
import {
    fetchEcrf,
    fetchEcrfElements,
    selectCurrentSection,
    selectDependencies,
    selectEcrf,
    selectEcrfElements
} from 'features/ecrf/redux/ecrfSlice';
import getInitialValues from 'features/ecrf/utils/getInitialValues';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StyledEcrfForm, StyledEcrfTitle } from '../Ecrf.styled';
import FormField from '../FormField';
import { Divider } from './Divider';
import { useSnackbar } from 'notistack';
import axiosSecureInstance from 'commons/axios/axiosSecureInstance';
import { flatten } from 'lodash';
import { NavWidget } from "../Menu/NavWidget";
import ModalForm from "components/Form/Modal/ModalForm";

interface Props {
    isDataLoading: boolean;
    setIsDataLoading: (isDataLoading: boolean) => void;
    isOpenQueryPanel: null | string;
    setIsOpenQueryPanel: (isOpenQueryPanel: null | string) => void;
    setSelectedVisit: (selectedVisit: any) => void;
    updateSection: (sectionId: string, visits: any) => void;
}

export const FormContent: React.FC<Props> = ({
    isDataLoading,
    setIsDataLoading,
    isOpenQueryPanel,
    setIsOpenQueryPanel,
    updateSection
}) => {
    const dispatch          = useAppDispatch();
    const {enqueueSnackbar} = useSnackbar();

    /** This is current selected section data */
    const currentSection = useAppSelector(selectCurrentSection);

    /** ECRF preps - holding data of actual Ecrf */
    const ecrf         = useAppSelector(selectEcrf);
    const elements     = useAppSelector(selectEcrfElements);
    const dependencies = useAppSelector(selectDependencies);

    /** Parameters */
    const {id, section} = useParams<{ id: string, section: string }>();
    const [dataLoading, setDataLoading] = useState('Ładowanie danych...')
    const [sectionDependencies, setSectionDependencies] = useState<any>([]);
    const [hasElementDataInstance, setHasElementDataInstance] = useState(false);

    /**
     * Check ECRF state
     */
    const checkState = async () => {
        switch (ecrf?.state) {
            case 1:
                setIsDataLoading(true);
                setDataLoading('Inicjalizowanie danych...');
                break;
            case 2:
                setIsDataLoading(true);
                // setDataLoading('Trwa przetwarzanie zależności elementów...');
                setDataLoading('PROSZĘ CZEKAĆ, TRWA ŁADOWANIE NOWEGO PACJENTA');
                break;
            case 3:
                setIsDataLoading(true);
                setDataLoading('Trwa randomizowanie pacjenta...');
                break;
            default:
                setIsDataLoading(false);
                setDataLoading('Ładowanie danych...');
        }
    }
    /**
     * Load section data elements
     * @param section
     * @param elements
     */
    const loadData = async (section: any, elements: any = []) => {
        try {
            if (section.id) {
                // dependencies
                const depends = await Dependency.createDep(
                    // section?.elements, elements, id, section?.dependencies
                    section?.elements, elements, id, dependencies
                );

                if (depends) {
                    setSectionDependencies(depends);
                }
            }
        } catch (error: any) {
            enqueueSnackbar(
                'Nie udało się wczytać sekcji, odśwież stronę'
                + ' lub skontaktuj się z administratorem.',
                {variant: 'error'}
            );
        }

        switch (ecrf?.state) {
            case 1:
            case 2:
            case 3:
                setTimeout(async () => {
                    id && await dispatch(fetchEcrf(id));
                }, 6000);
                break;
            default:
                setIsDataLoading(false);
                setDataLoading('Ładowanie danych...');
        }
    }

    /**
     * This function will save all completed fields after clicking SAVE ALL
     * @param values
     */
    const handleSubmit = async (values: any) => {
        setIsAllSaving(true);

        const parsedValues = Object.entries(values);

        // map form values and create data object
        let data = [];
        for (const element of parsedValues) {
            const elementId: string = element[0];
            const elementData: any  = element[1];

            if (elements.find(
                (e: any) => e?.element?.id === elementId && e?.data[0] === elementData
            )) {
                continue;
            }

            if (
                elementData !== '' &&
                elementData !== null &&
                elementData !== undefined &&
                elementData.length !== 0
            ) {
                const item = {
                    data: flatten([elementData]),
                    element: `/api/elements/${elementId}`
                }

                data.push(item);
            }
        }

        if (data.length > 0) {
            await saveData(data).then();
        }

        setIsAllSaving(false);
    };

    /** Request saving the current section */
    const [isAllSaving, setIsAllSaving] = useState(false);
    const saveData = async (elements: any) => {
        try {
            // await axiosSecureInstance.put(`/api/ecrves/${id}/${currentSection.id}/elements`, {elements});
            await axiosSecureInstance.put(`/api/ecrves/${id}/elements`, {elements});
            await dispatch(fetchEcrfElements({id, sectionId: section}));

            enqueueSnackbar(`Saved ${new Date().toLocaleTimeString()}`, {variant: 'info'});
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.response?.data?.detail, {
                variant: 'error',
            });
        }
    };

    useEffect(() => {
        // if (currentSection?.elements && currentSection?.dependencies && elements) {
        if (currentSection?.elements && dependencies && elements) {
            loadData(currentSection, elements).then();
        }
    }, [ecrf, elements]);

    useEffect(() => {
        checkState()
    }, [ecrf, currentSection, elements]);

    useEffect(() => {
        if (isDataLoading) {
            setIsDataLoading(true);
            setSectionDependencies([]);
        }
    }, [currentSection]);

    // @todo rebuild this, optimize
    const initialValues = getInitialValues(currentSection?.elements || [], elements || []);

    return (
        <>
            <StyledEcrfForm>
                {isDataLoading ? (
                    <Box display="grid" style={{ placeItems: 'center', height: 'calc(100vh - 300px)' }}>
                        <Box display="grid" style={{ placeItems: 'center', gap: '15px' }}>
                            <CircularProgress />
                            <StyledEcrfTitle>{dataLoading}</StyledEcrfTitle>
                        </Box>
                    </Box>
                ) : (
                    <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
                        <Form>
                            {/*<FormikEffect ecrfId={id}/>*/}
                            {currentSection?.elements?.length === 0 && <Box style={{marginTop: '40px'}}>Brak elementów</Box>}
                            {sectionDependencies && sectionDependencies.length > 0 && (
                                <Box>
                                    {sectionDependencies.slice().map((element: any) => {
                                        /** Divider is Header type */
                                        if (element.type === 'divider') {
                                            return <Divider key={element?.id} label={element.label}/>;
                                        }

                                        /** Find data of element and interpose it */
                                        /** @todo data assign in dependencies builder */
                                        const data = elements.findLast(
                                            (data: any) => data?.element?.id === element.id
                                        );

                                        /** Render element - check type and display it */
                                        return (
                                            <FormField
                                                element={element}
                                                key={element?.id}
                                                ecrfId={ecrf?.id}
                                                elementStatus={data?.status}
                                                elementData={data}
                                                hasActionPanel={element?.helper === 0}
                                                hasElementDataInstance={hasElementDataInstance}
                                                hasQueryPanel={element?.helper === 0}
                                                isOpenQueryPanel={isOpenQueryPanel}
                                                setIsOpenQueryPanel={(elementId) => setIsOpenQueryPanel(elementId)}
                                                isAllSaving={isAllSaving}
                                                setIsAllSaving={setIsAllSaving}
                                                updateSection={updateSection}
                                            />
                                        );
                                    })}
                                </Box>
                            )}

                            {/** Navigation widget */}
                            <NavWidget
                                isAllSaving={isAllSaving}
                                setIsAllSaving={setIsAllSaving}
                                updateSection={updateSection}
                            />
                        </Form>
                    </Formik>
                )}
            </StyledEcrfForm>

            {/** Modal form */}
            <ModalForm />

            {/** QueriesPanel window */}
            <QueriesPanel
                currentEcrfId={id || ''}
                isOpen={!!isOpenQueryPanel}
                queryId={
                    elements?.find((data: any) => data?.element?.id === isOpenQueryPanel)?.comment?.id || null
                }
                onClose={() => setIsOpenQueryPanel(null)}
                elementDataId={
                    // TODO swagger model for element data
                    elements?.find((data: any) => data?.element?.id === isOpenQueryPanel)?.id ||
                    null
                }
                element={
                    isOpenQueryPanel
                        ? currentSection?.elements?.find((element: any) => element.id === isOpenQueryPanel)
                        : undefined
                }
            />
        </>
    );
};
