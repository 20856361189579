import { Box, Modal } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'commons/store/hooks'
import {
    fetchCollection,
    selectCollection
} from 'features/ecrf/redux/ecrfSlice'
import { useEffect, useState } from 'react'
import ModalFormField from './ModalFormField'

const ModalForm = () => {
    const dispatch = useAppDispatch()
    const collection = useAppSelector(selectCollection)
    const actions: any[] = ['add', 'duplicate', 'edit', 'save']
    const [isOpen, setIsOpen] = useState(false)

    const handleClose = async () => {
        setIsOpen(false)
        if (collection && Object.keys(collection).length
            && actions.includes(collection?.type || '')
        ) {
            await dispatch(fetchCollection(null))
        }
    }

    useEffect(() => {
        if (
            collection && Object.keys(collection).length
            && actions.includes(collection?.type || '')
        ) {
            setIsOpen(true)
        }
    }, [collection])

    const getModal = () =>{
        return (
            <ModalFormField
                title={(collection && collection?.elementName) || ''}
                handleClose={handleClose}
                collection={collection}
            />
        )
    }

    return (
        <Modal
            open={isOpen}
            onClose={(_, reason) => {
                // reason === 'backdropClick' &&
                handleClose()
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{overflow: 'scroll'}}
        >
            <Box m={3} ml="10vw" mr="10vw" p={3} bgcolor="white">
                {getModal()}
            </Box>
        </Modal>
    )
}

export default ModalForm;