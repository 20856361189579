import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import {
  UserUserOutput,
  UserUserUserReadIdRead,
  UserUserUserWrite,
} from "types/api.types";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { UserRoles } from "types/enum.types";
import { HalResource } from "types/halResource.types";

export interface UsersState {
  users: {
    itemsPerPage: number;
    totalItems: number;
    data: UserUserUserReadIdRead[];
  };
  userDetails: UserUserUserReadIdRead | null;
}

type UserUserUserWriteOverwrite = Omit<UserUserUserWrite, "roles"> & {
  roles: UserRoles;
};

const initialState: UsersState = {
  users: {
    itemsPerPage: 0,
    totalItems: 0,
    data: [],
  },
  userDetails: null,
};

type fetchUsersParams = {
  page: number;
};

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async ({ page }: fetchUsersParams) => {
    const response = await axiosSecureInstance.get<
      HalResource<UserUserUserReadIdRead[]>
    >("/api/users", {
      headers: {
        accept: "application/hal+json",
      },
      params: {
        page,
        pagination: true,
      },
    });
    return response.data;
  }
);

export const fetchUser = createAsyncThunk(
  "users/fetchUser",
  async (id: string) => {
    const response = await axiosSecureInstance.get<UserUserUserReadIdRead>(
      `/api/users/${id}`
    );
    return response.data;
  }
);

export const addUser = createAsyncThunk(
  "users/addUser",
  async (values: UserUserUserWriteOverwrite, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.post<UserUserOutput>(
        `/api/users`,
        {
          ...values,
          roles: [values.roles],
          medicalCenters: values.medicalCenters?.map(
            (medicalCenterId) => `/api/medical_centers/${medicalCenterId}`
          ),
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

type editUserParams = {
  id: string | undefined;
  values: UserUserUserWriteOverwrite;
};

export const editUser = createAsyncThunk(
  "users/editUser",
  async ({ id, values }: editUserParams, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.put<UserUserOutput>(
        `/api/users/${id}`,
        {
          ...values,
          roles: [values.roles],
          medicalCenters: values.medicalCenters?.map(
            (medicalCenterId) => `/api/medical_centers/${medicalCenterId}`
          ),
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id: string) => {
    await axiosSecureInstance.delete(`/api/users/${id}`);
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users.data = action.payload._embedded?.item || [];
      state.users.itemsPerPage = action.payload.itemsPerPage;
      state.users.totalItems = action.payload.totalItems;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.userDetails = action.payload;
    });
  },
});

export const selectUsers = (state: RootState) => state.users.users.users;
export const selectUserDetails = (state: RootState) => state.users.users.userDetails;

export default usersSlice.reducer;
