import { StyledDrawer, StyledDrawerContentWrapper } from "./QueriesPanel.styled";
import { Box, Button, IconButton } from "@mui/material";
import ColoredIconWrapper from "components/ColoredIconWrapper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import { Form, Formik, FormikHelpers } from "formik";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import {
    addQuery,
    fetchEcrfElements,
    fetchElementDatas,
    fetchQueries,
    putElementData,
    resetElementDatas,
    resetQueries,
    selectElementDatas,
    selectQueries,
} from "features/ecrf/redux/ecrfSlice";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import { getRoleByRoleId } from "commons/constants/ditionaries";
import DateTime from "core/helpers/date/DateTime";
import TextAreaFormik from "components/Form/TextAreaFormik";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DATETIME_FORMAT } from "commons/constants/env";
import { selectUserProfile } from "core/redux/userSlice";
import ColoredBox from "components/ColoredBox";
import { Visibility } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export interface QueriesPanelProps {
    isOpen: boolean;
    onClose: () => void;
    // TODO swagger model for ecrf element
    element?: any | undefined;
    elementDataId: string | null;
    currentEcrfId: string | null;
    queryId?: string | null;
}

const QueriesPanel = ({
  isOpen,
  onClose,
  element,
  elementDataId,
  currentEcrfId,
  queryId,
}: QueriesPanelProps) => {
    const dispatch    = useAppDispatch();
    const queries     = useAppSelector(selectQueries);
    const elementData = useAppSelector(selectElementDatas);
    const userProfile = useAppSelector(selectUserProfile);

    const [isLoading, setIsLoading]     = useState(true);
    const [isSending, setIsSending]     = useState(false);
    const {id = currentEcrfId, section} = useParams<{ id: string, section: string }>();
    const {enqueueSnackbar}             = useSnackbar();
    const { t } = useTranslation();

    let isReadonly: boolean = userProfile.roles.some(
        (role: string) => (['ROLE_COORDINATOR', 'ROLE_SPONSOR'].includes(role))
    );

    const formInitialValues = {
        content: '',
    };

    const fetch = useCallback(async () => {
        if (elementDataId) {
            const data = await dispatch(fetchElementDatas(elementDataId)).unwrap();

            if (data?.comment?.id) {
                await dispatch(fetchQueries(data?.comment?.id)).unwrap();
            }
        }

        setIsLoading(false);
    }, [dispatch, elementDataId]);

    useEffect(() => {
        if (!queryId) {
            dispatch(resetQueries())
            dispatch(resetElementDatas())
        }

        setIsLoading(true);
        fetch();
    }, [dispatch, elementDataId, fetch, isOpen]);

    /**
     * This function is used to add new query or reply to an existing query
     * @param values
     * @param actions
     */
    const handleAddQuery = async (values: typeof formInitialValues, actions: FormikHelpers<typeof formInitialValues>) => {
        if (!values.content) {
            return null;
        }

        setIsSending(true);

        try {
            if (elementData && elementData?.id === elementDataId && elementData?.comment?.id) {
                await dispatch(addQuery({
                    content: values.content, parent: `/api/comments/${elementData?.comment?.id}`
                })).unwrap()
            } else if (elementData?.id) {
                await dispatch(addQuery({
                    content: values.content, element: `/api/element_datas/${elementData?.id}`
                })).unwrap()
            } else {
                await dispatch(putElementData({
                    elementId: element?.id || '',
                    ecrfId: id || '',
                    data: [],
                    comment: {
                        content: values.content
                    }
                })).unwrap()
            }

            actions.resetForm({});
            await fetch();

            if (id && section) {
                await dispatch(fetchEcrfElements({id, sectionId: section}));
            }

            enqueueSnackbar(successMessageFactory(),{variant: 'success',});
        } catch (err: any) {
            enqueueSnackbar(err?.detail, {variant: 'error'});
        }

        setIsSending(false)
    };


    const successMessageFactory = () => {
        if (element?.title === undefined && elementData?.element?.title === undefined) return t('query-added')

        return `${t('query-has-been-added-to-the-item')} ${element?.title || elementData?.element?.title}`
    }

    return (
        <StyledDrawer anchor="right" open={isOpen} onClose={onClose} variant="persistent">
            <StyledDrawerContentWrapper>
                <Box pt={1} pb={2}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Typography variant="subtitle2" color="primary" onClick={() => onClose()}>
                            <Button endIcon={<ChevronRightIcon />}>{t('collapse-panel')}</Button>
                        </Typography>
                    </Box>

                    <Box p={2} pt={0}>
                        <Box mb={2}>
                            <ColoredIconWrapper color="primary">
                                <QuestionAnswerIcon />
                            </ColoredIconWrapper>
                        </Box>
                        <Typography variant="button">{t('queries-panel')}</Typography>
                        <Typography variant="body2" color="textSecondary">{t('queries-panel-desc')}</Typography>
                    </Box>

                    <Box p={2} borderTop={1} borderColor="rgba(0, 0, 0, 0.12)">
                        <Typography variant="subtitle2">
                            <strong>{t('field')}:</strong>
                        </Typography>
                        <Typography variant="subtitle2">{element?.label || elementData?.element?.label}</Typography>
                    </Box>

                    {isLoading && (
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    )}

                    {!isLoading && (
                        <>
                            <Box p={2} borderTop={1} borderColor="rgba(0, 0, 0, 0.12)" key={queries.id}>
                                {queries?.owner?.id && (
                                    <>
                                        <Box display="flex" mb={1} alignItems="center">
                                            <Box mr={1} mt={2} mb={1} display="flex" style={{gap: "10px"}}>
                                                <Typography variant="subtitle2">
                                                    {queries?.owner?.id ? queries?.owner?.firstname : ''}{' '}
                                                    {queries?.owner?.id ? queries?.owner?.lastname : ''}
                                                    <br/><small>({queries?.owner?.id ? getRoleByRoleId(queries?.owner?.roles[0]) : '-'})</small>
                                                </Typography>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {queries.createdAt && DateTime.toIso(queries.createdAt, DATETIME_FORMAT)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography variant={"body2"}>{queries?.content}</Typography>
                                    </>
                                )}
                                {queries?.children?.map((i: any) => (
                                    <Box>
                                        <Box mr={1} mt={2} key={i.id} mb={1} display="flex" style={{gap: "10px"}}>
                                            <Typography variant="subtitle2">
                                                {i?.owner?.id ? i?.owner?.firstname : ''}{' '}
                                                {i?.owner?.id ? i?.owner?.lastname : ''}
                                                <br/><small>({i?.owner?.id ? getRoleByRoleId(i?.owner?.roles[0]) : '-'})</small>
                                            </Typography>

                                            <Typography variant="subtitle2" color="textSecondary">
                                                {i.createdAt && DateTime.toIso(i.createdAt, DATETIME_FORMAT)}
                                            </Typography>
                                        </Box>
                                        <Typography variant={"body2"}>{i?.content}</Typography>
                                    </Box>
                                ))}
                            </Box>
                            {!isReadonly ? (
                                <Box p={2}>
                                    <Formik initialValues={formInitialValues} onSubmit={handleAddQuery}>
                                        <Form>
                                            <Box display="flex" alignItems="center">
                                                <TextAreaFormik
                                                    name="content"
                                                    placeholder={t('add-reply') + '...'}
                                                    fullWidth
                                                    variant="outlined"
                                                    rows={6}
                                                    disabled={isSending}
                                                />
                                                <Box ml={1}>
                                                    <IconButton type="submit">
                                                        {isSending ? <CircularProgress size={24} /> : <SendIcon />}
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Form>
                                    </Formik>
                                </Box>
                            ) : (
                                <ColoredBox
                                    p={2}
                                    m={2}
                                    style={{placeItems: "center"}}
                                    display="grid"
                                    gridTemplateColumns="0fr 1fr"
                                >
                                    <Visibility style={{fontSize: "30px"}} />
                                    <Typography style={{marginLeft: "10px"}} variant="body2">
                                        {t('you-are-in-preview-mode')}
                                    </Typography>
                                </ColoredBox>
                                )}
                        </>
                    )}
                </Box>
            </StyledDrawerContentWrapper>
        </StyledDrawer>
    );
};

export default QueriesPanel;
