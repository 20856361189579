import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
    label: string;
}

export const Divider: React.FC<Props> = ({ label }) => {
    return (
        <Box ml={1} mb={1} mt={5}>
            <Typography variant={'h5'}>{label}</Typography>
        </Box>
    );
};
