import { Box, Button, CircularProgress } from "@mui/material";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import DashboardEcrves from 'features/dashboard/components/DashboardEcrves';
import DashboardQueries from 'features/dashboard/components/DashboardQueries';
import DashboardAdverseEvents from 'features/dashboard/components/DashboardAdverseEvents';
import DashboardProvide from 'features/dashboard/components/DashboardProvide';
import DashboardStats from 'features/dashboard/components/DashboardStats';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from '@mui/system';
import React, {useState, useEffect, useCallback} from "react";
import { ROUTES_ECRF } from "commons/constants/paths";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import { addEcrf } from "features/dashboard/redux/dashboardSlice";
import { selectUserProfile } from "core/redux/userSlice";
import AddIcon from '@mui/icons-material/Add';
import {
    COLOR_BG_NAV,
    COLOR_PRIMARY_LIGHT,
    DISPLAY_PROVIDE,
    PROJECT_NAME
} from "commons/constants/env";
import { useParams } from 'react-router-dom';
import ConfirmationAction from "components/ConfirmationAction";
import { roles } from 'commons/auth/roles';
import { useSnackbar } from "notistack";
import DashboardDataManagement from "../../components/DashboardDataManagement";
import { useTranslation, withTranslation } from 'react-i18next';

const CustomToggleButton = styled(ToggleButton)(({ theme }) => `
  color: ${theme.palette.secondary.main};
  padding: 6px 16px;
  font-size: 0.875rem;
  border-color: ${theme.palette.primary.light};

  &:hover {
    color: ${theme.palette.primary.dark};
    background: ${COLOR_BG_NAV};
    border-color: ${COLOR_BG_NAV};
  }

  &.Mui-selected {
    color: ${theme.palette.primary.main};
    background: ${theme.palette.primary.light};

    &:hover {
      color: ${theme.palette.primary.dark};
      background: ${COLOR_PRIMARY_LIGHT};
    }
  }
`);

const Dashboard = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userProfile = useAppSelector(selectUserProfile);
    const activeProvide: boolean  = userProfile.roles.some((role: string) => roles.pharmacist.includes(role));
    const {type = activeProvide ? 'provide-drug' : 'ecrves'} = useParams<{
        type: 'ecrves' | 'queries' | 'data-management' | 'adverse-events' | 'provide-drug' | 'statistics'
    }>();
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();

    let displayProvide: boolean = (DISPLAY_PROVIDE && DISPLAY_PROVIDE !== 'FALSE')
        && userProfile.roles.some(
            (role: string) => (
                roles.admin.includes(role) ||
                roles.pharmacist.includes(role) ||
                roles.monitor.includes(role)
            ) && role !== 'ROLE_SPONSOR'
        );
    let displayDashboards: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.research.includes(role)
    );
    let displayAddEcrf: boolean    = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.researcher.includes(role)
    );
    let displayDM: boolean  = userProfile.roles.some(
        (role: string) => (
            roles.admin.includes(role) ||
            roles.dataManager.includes(role)
        )
    );
    let displayAE: boolean  = userProfile.roles.some(
        (role: string) => (
            roles.admin.includes(role) ||
            roles.monitor.includes(role) ||
            roles.researcher.includes(role)
        )
    );
    let displayQueries: boolean = userProfile.roles.some(
        (role: string) => (
            roles.admin.includes(role) ||
            roles.monitor.includes(role) ||
            roles.research.includes(role)
        )
    );
    let displayStats: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.monitor.includes(role)
    );

    /**
     * This function is used to create new ecrf
     */
    const [isDataLoading, setIsDataLoading] = useState(false)
    const handleCreateEcrf = async () => {
        setIsDataLoading(true)

        try {
            const ecrf = await dispatch(addEcrf()).unwrap();
            if (ecrf.id) {
                navigate(ROUTES_ECRF.DETAILS(ecrf.id));
            }
        } catch (error: any) {
            enqueueSnackbar(error?.detail, {variant: 'error'});
        }

        setIsDataLoading(false)
    };

    /**
     * This function is used to control actual visible dashboard change if route is changed
     */
    const activeSectionController = () => {
        switch (type) {
            case 'ecrves': {
                if (displayDashboards) {
                    navigate(`/dashboard/${type}`)
                } else {
                    navigate(`/dashboard/provide-drug`)
                }
                break;
            }
            case 'queries': {
                if (displayDashboards) {
                    navigate(`/dashboard/${type}`)
                } else {
                    navigate(`/dashboard/provide-drug`)
                }
                break;
            }
            case 'data-management': {
                if (displayDashboards && displayDM) {
                    navigate(`/dashboard/${type}`)
                } else {
                    navigate(`/dashboard/provide-drug`)
                }
                break;
            }
            case 'adverse-events': {
                if (displayDashboards) {
                    navigate(`/dashboard/${type}`)
                } else {
                    navigate(`/dashboard/provide-drug`)
                }
                break;
            }
            case 'provide-drug': {
                if (displayProvide) {
                    navigate(`/dashboard/${type}`)
                } else {
                    navigate(`/dashboard/ecrves`)
                }
                break;
            }
            case 'statistics': {
                navigate(`/dashboard/${type}`)
                break;
            }
            default: {
                if (displayDashboards) {
                    navigate(`/dashboard/ecrves`)
                } else {
                    navigate(`/dashboard/provide-drug`)
                }
                break;
            }
        }
    }

    /**
     * This function is used to change actual section, also will change route
     * @param value
     */
    const changeDashboardTab = (
        value: 'ecrves' | 'queries' | 'data-management' | 'adverse-events' | 'provide-drug' | 'statistics'
    ) => {
        navigate(`/dashboard/${value}`)
    }

    useEffect(() => {
        document.title = `${PROJECT_NAME} - Dashboard`;
        activeSectionController();

        return () => {
            document.title = PROJECT_NAME;
        };
    }, [dispatch]);

    const renderContent = useCallback(() => {
        switch (type) {
            case 'ecrves':
                return (<DashboardEcrves/>);
            case 'queries':
                return (<DashboardQueries/>);
            case 'data-management':
                return (<DashboardDataManagement/>);
            case 'adverse-events':
                return (<DashboardAdverseEvents/>);
            case 'provide-drug':
                return (<DashboardProvide/>);
            case 'statistics':
                return (<DashboardStats/>);
        }
    }, [type]);

    return (
        <DashboardLayoutWrapper>
            <Box mb={1} display="flex" justifyContent="space-between">
                <ToggleButtonGroup
                    size="small"
                    color="secondary"
                    aria-label="outlined primary button group"
                >
                    {displayDashboards && (
                        <CustomToggleButton
                            selected={type === 'ecrves'}
                            onClick={() => changeDashboardTab('ecrves')}
                            value={type}
                        >
                            {t('study-participants')}
                        </CustomToggleButton>
                    )}

                    {(displayDashboards && displayQueries) && (
                        <CustomToggleButton
                            selected={type === 'queries'}
                            onClick={() => changeDashboardTab('queries')}
                            value={type}
                        >
                            {t('queries')}
                        </CustomToggleButton>
                    )}

                    {(displayDashboards && displayDM) && (
                        <CustomToggleButton
                            selected={type === 'data-management'}
                            onClick={() => changeDashboardTab('data-management')}
                            value={type}
                        >
                            {t('data-management')}
                        </CustomToggleButton>
                    )}

                    {(displayDashboards && displayAE) && (
                        <CustomToggleButton
                            selected={type === 'adverse-events'}
                            onClick={() => changeDashboardTab('adverse-events')}
                            value={type}
                        >
                            {t('adverse-events')}
                        </CustomToggleButton>
                    )}

                    {displayProvide && (
                        <CustomToggleButton
                            selected={type === 'provide-drug'}
                            onClick={() => changeDashboardTab('provide-drug')}
                            value={type}
                        >
                            {t('provide-drug')}
                        </CustomToggleButton>
                    )}

                    {displayStats && (
                        <CustomToggleButton
                            selected={type === 'statistics'}
                            onClick={() => changeDashboardTab('statistics')}
                            value={type}
                        >
                            {t('statistics')}
                        </CustomToggleButton>
                    )}
                </ToggleButtonGroup>

                {type === 'ecrves' && displayAddEcrf && (
                    <ConfirmationAction
                        isProcessing={isDataLoading}
                        onClick={handleCreateEcrf}
                        confirmationTitle={t('are-you-sure-you-want-to-add-a-new-study-participant')}
                    >
                        {({handleClick}) => (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClick}
                                startIcon={isDataLoading ? <CircularProgress size={16} /> : <AddIcon/>}
                                disabled={isDataLoading}
                            >
                                {t('add-a-study-participant')}
                            </Button>
                        )}
                    </ConfirmationAction>
                )}

            </Box>
            {renderContent()}
        </DashboardLayoutWrapper>
    );
};

export default Dashboard;