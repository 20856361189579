import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { RootState } from "commons/store/store";

export interface DrugManagementState {
    drugManagement: any[];
    drugManagementDetails: any | null;
}

const initialState: DrugManagementState = {
    drugManagement: [],
    drugManagementDetails: null
};

type DrugManagementsParams = {
    page: number
    itemsPerPage: number
}

export const fetchDrugManagements = createAsyncThunk(
    'drugManagement/fetchDrugManagements',
    async ({page, itemsPerPage}: DrugManagementsParams) => {
        const response =
            await axiosSecureInstance.get<Notification[]>(
                `/api/drug_managements`, {
                    params: {
                        pagination: true,
                        page,
                        itemsPerPage
                    },
                });
        return response.data;
    }
);

export const fetchDrugManagement = createAsyncThunk(
    'drugManagement/fetchDrugManagement',
    async (id: string) => {
        const response = await axiosSecureInstance.get<any>(
            `/api/drug_managements/${id}`
        );
        return response.data;
    }
);

type EditDrugManagement = {
    id: string;
    data: any[];
};

export const editDrugManagement = createAsyncThunk(
    'drugManagement/editDrugManagement',
    async ({ id, data }: EditDrugManagement, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.put<any>(
                `/api/drug_managements/${id}`,
                data
            );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const editManyDrugManagement = createAsyncThunk(
    'drugManagement/editManyDrugManagement',
    async ({ id, data }: EditDrugManagement, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.put<any>(
                `${id}/drug_managements`,
                data
            );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const drugManagementSlice = createSlice({
    name: 'drugManagement',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDrugManagements.fulfilled, (state: DrugManagementState, action) => {
            state.drugManagement = action.payload;
        });
        builder.addCase(fetchDrugManagement.fulfilled, (state, action) => {
            state.drugManagementDetails = action.payload;
        });
    },
});

export const selectDrugManagement = (state: RootState) => state.drugManagement.drugManagement.drugManagement;
export const selectDrugManagementDetails = (state: RootState) => state.drugManagement.drugManagement.drugManagementDetails;

export default drugManagementSlice.reducer;
