import {
    Grid,
    MenuItem,
    TextField
} from "@mui/material";
import {
    StyledSectionTitle,
    StyledInfoSectionSubtitle,
    StyledDepartmentDescription,
    StyledSplitButtonWrapper,
} from "../Default/Home.styled";
import GoogleMap from "./GoogleMap";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import {
    fetchMedicalCenters, fetchPublicMedicalCenters,
    selectMedicalCenters,
} from "features/medicalCenters/redux/medicalCentersSlice";
import { uniq } from "lodash";
import { MedicalCenter as MedicalCenterProp } from "types/api.types";

function MedicalCenter() {
    const dispatch = useAppDispatch();
    const medicalCenters = useAppSelector(selectMedicalCenters);
    const [page, setPage] = useState(1);
    const [medicalCenterList, setMedicalCenterList] = useState<string[]>([]);
    const [medicalCenter, setMedicalCenter] = useState<string | undefined>("");
    const [selectedCenter, setSelectedCenter] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const fetch = async () => {
        setLoading(true)

        const centers = await dispatch(fetchPublicMedicalCenters({page})).unwrap();

        const medicalCenterCityList = uniq(
            centers._embedded?.item.map(
                (center: MedicalCenterProp) => center.name
            )
        );
        medicalCenterCityList && setMedicalCenter(medicalCenterCityList[0]);
        medicalCenterCityList && setMedicalCenterList(medicalCenterCityList);

        // set default values
        setSelectedCenter(centers._embedded?.item[0]);
        setLoading(false)
    };

    useEffect(() => {
        fetch();
    }, []);

    const selectCenter = async (value: string) => {
        setMedicalCenter(value);
        const actualCenter = medicalCenters.data.find(
            (center: MedicalCenterProp) => center?.name === value
        );
        setSelectedCenter(actualCenter);
    };

    return (
        <Grid container spacing={2} style={{marginTop: '200px'}}>
            <Grid item xs={12} md={6}>
                <GoogleMap
                    lat={parseFloat(selectedCenter?.longitude) || 0}
                    lng={parseFloat(selectedCenter?.latitude) || 0}
                />
                {/*<GoogleMap lat={selectedCenter?.longitude} lng={selectedCenter?.latitude}/>*/}
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledSectionTitle isForDepartments className="section-title" textAlign="left">
                    Ośrodki badawcze
                </StyledSectionTitle>
                <StyledInfoSectionSubtitle>
                    Ośrodek {medicalCenter}
                </StyledInfoSectionSubtitle>
                <StyledDepartmentDescription>
                    {selectedCenter?.name}
                </StyledDepartmentDescription>
                <StyledDepartmentDescription>
                    {selectedCenter?._embedded?.address?.street}
                    <br/>
                    {selectedCenter?._embedded?.address?.postcode} {selectedCenter?._embedded?.address?._embedded?.city?.name}
                    {/* <br />
                    telefon: <StyledAnchor href="tel:225966155"></StyledAnchor>
                    <br />
                    e-mail:{" "}
                <StyledAnchor href="mailto:neonatologia@poczta.szpitalkarowa.pl"></StyledAnchor> */}
                </StyledDepartmentDescription>
                <StyledSplitButtonWrapper>
                    <TextField
                        name="medicalCenter"
                        label="Ośrodek"
                        select
                        value={medicalCenter}
                        onChange={(e) => selectCenter(e.target.value)}
                        disabled={loading}
                    >
                        {medicalCenterList.map((center: string) => (
                            <MenuItem key={center} value={center}>
                                Ośrodek {center}
                            </MenuItem>
                        ))}
                    </TextField>
                </StyledSplitButtonWrapper>
            </Grid>
        </Grid>
    );
}

export default MedicalCenter;
