import { useField } from "formik";
import { SelectProps } from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { map } from "lodash";
import { TextFieldFormik } from "./index";
import ListSubheader from "@mui/material/ListSubheader";
import React from "react";

export interface SelectFormikProps
  extends Omit<SelectProps, 'name' | 'value' | 'onChange' | 'onBlur' | 'error'> {
  name: string;
  helperText?: string;
  options: Array<{ key: string; value: string }>;
  status?: boolean;
}

const SelectFormik = ({
  name,
  helperText,
  options,
  status,
  ...props
}: SelectFormikProps) => {
  const [field, meta, form] = useField(name);

  return (
      <TextFieldFormik
          name={field.name}
          fullWidth
          status={status}
          label={props.label}
          placeholder={props?.placeholder}
          helperText={helperText}
          select
      >
          {map(options, (value, key) => {
              if (typeof value === 'object' && value !== null) {
                  return [
                      <ListSubheader>{String(key)}</ListSubheader>,
                      map(value, (subValue, subKey) => (
                          <MenuItem key={String(subValue)} value={String(subValue)}>
                              {String(subKey)}
                          </MenuItem>
                      )),
                  ];
              }

              return (
                  <MenuItem key={String(value)} value={String(value)}>
                      {String(key)}
                  </MenuItem>
              );
          })}
      </TextFieldFormik>
  );
};

export default SelectFormik;
