import styled from 'styled-components';
import { COLOR_BG_DARK, COLOR_BG_NAV, COLOR_PRIMARY, COLOR_PRIMARY_LIGHT } from '../../../commons/constants/env';

export const StyledTableWrapper = styled.div`
    padding: 25px;
    max-width: calc(95vw - 440px);
    background-color: ${COLOR_BG_DARK};
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
`;

// i tu
export const StyledTableHead = styled.div<any>`
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.size}, minmax(300px, 1fr)) 50px`};
`;

export const StyledTableHeadAndRow = styled.div<any>`
    scrollbar-width: thin;
    overflow-x: auto;

    &::-webkit-scrollbar {
        all: unset;
        height: 10px;
        padding-top: 15px;
        background-color: #fff;
    }

    ::-webkit-scrollbar-thumb {
        background: ${COLOR_PRIMARY_LIGHT};
        height: 3px;
        border-radius: 9999px;
        width: 30px;
        cursor: auto;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${COLOR_PRIMARY};
        cursor: auto;
    }
`;

// tu
export const StyledTableRow = styled.div<any>`
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.size}, minmax(300px, 1fr)) 50px`};
`;

export const StyledTableHeadItem = styled.div`
    padding: 20px 30px;
    display: flex;
    align-items: center;
    border: 1px solid rgb(118, 118, 118);
    font-weight: bold;
`;

export const StyledTableRowItem = styled.div`
padding: 20px 30px;
border: 1px solid #E0E0E0;
display: flex;
flex-wrap: wrap;
align-items: center;
transition: .2s ease;

&:hover {
background-color: ${COLOR_BG_NAV};
`;
