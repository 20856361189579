import { roles } from 'commons/auth/roles';

export default class ClearValues {
    /**
     * CLEAR VALUE display controller
     * @param profileRoles
     * @param elementType
     * @param value
     * @param elementStatus
     * @param ecrfStatus
     */
    public static displayController(
        profileRoles: any,
        elementType: string,
        value: any,
        elementStatus: number,
        ecrfStatus: number
    ) {
        // elements
        switch (elementType) {
            case 'adverse_event':
            case 'button':
            case 'dicom_button':
            case 'close_observation_period':
            case 'collection':
            case 'randomization':
            case 'table': {
                return false;
            }
        }

        // roles
        if (profileRoles.some((role: string) => roles.dataManager.includes(role) || roles.monitor.includes(role))) {
            return false;
        }

        /** block if value is not initial, or status ecrf is 4 */
        // if (!value || value?.data[0] === '' || value?.data === [] || ecrfStatus === 4) {
        if (!value || value?.data[0] === '' || value?.data?.length === 0 || ecrfStatus === 4) {
            return false;
        }

        // element status
        switch (elementStatus) {
            case 1:
            case 3: {
                return false;
            }
        }

        return true;
    }
}
