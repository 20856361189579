import React, {useEffect, useState} from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import axiosSecureInstance from '../../../commons/axios/axiosSecureInstance';
import TableGridRow from './TableGridRow';
import AddIcon from '@mui/icons-material/Add';
import {
    StyledTableHead,
    StyledTableHeadAndRow,
    StyledTableHeadItem,
    StyledTableWrapper,
} from './TableGridFormik.styled';
import ScrollContainer from 'react-indiana-drag-scroll';
import Dependency from "../../../core/helpers/Dependency";
import { useAppDispatch } from "../../../commons/store/hooks";
import {
    fetchElementDataCollections,
    fetchElementDatas,
    resetElementDatas
} from "../../../features/ecrf/redux/ecrfSlice";
import {useSnackbar} from "notistack";
import {RowHistoryModal} from "../RowHistoryModal";
import {useTranslation} from "react-i18next";

const TableGridFormik: React.FC<any> = (props) => {
    const dispatch = useAppDispatch()
    const { enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();

    const [isDataLoading, setDataLoading]     = useState(false);
    const [isDataReloading, setDataReloading] = useState(false);
    const [isInitialLoading, setInitialLoading] = useState(false);

    /** State is holding actual dependencies */
    const [data, setData] = useState<any>({});
    const [dependencies, setDependencies] = useState<any>({});
    const [openHistory, setOpenHistory] = useState<any>(false);

    /**
     * This method is used to set value of reloading
     */
    const changeReloadingValue = async () => {
        setDataReloading(true);
        setDataReloading(false);
    }

    /**
     * This method is used to fetch element data and save it to state
     * @param id
     */
    const fetchElementData = async (id: string) => {
        if (!id) return;

        setInitialLoading(true);

        try {
            const elementData = await dispatch(fetchElementDatas(id)).unwrap();
            const collections = await dispatch(fetchElementDataCollections({id: id})).unwrap()

            await handleDependencies(collections || []);
            setData(elementData || [])
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.message, {variant: 'warning'});
        }

        setInitialLoading(false);
        return [];
    }

    /**
     * This method is used to add new row to element
     * @param collections
     */
    const addNewRow = async (collections: any) => {
        let collectionArray = [];

        for (let i = 0; i < collections.length + 1; i++) {
            let elementsArray = [];

            if (i === collections.length) {
                /* Add new object to collections */
                for (const element of props.children) {
                    const elementObject = {
                        ecrf: '/api/ecrves/' + props.ecrfId,
                        element: '/api/elements/' + element?.id,
                        data: [''],
                    };

                    elementsArray.push(elementObject);
                }
            } else {
                /* Create object from actual exists collections */
                for (const element of collections[i].elements) {
                    const elementObject = {
                        ecrf: '/api/ecrves/' + element.ecrf.id,
                        element: '/api/elements/' + element.element.id,
                        data: element.data,
                    };

                    elementsArray.push(elementObject);
                }
            }

            const collectionObject = { elements: elementsArray };

            collectionArray.push(collectionObject);
        }

        await createNewRow(collectionArray, props.elementData.id);
        await fetchElementData(props.elementData.id);
    }

    /**
     * This method is used to put initial data and create first row
     * @param children
     */
    const createFirstRecord = async (children?: any) => {
        setDataLoading(true);

        try {
            const response = await axiosSecureInstance.put(`/api/element_datas/element/${props.name}`, {
                data: [0],
                ecrf: `/api/ecrves/${props.ecrfId}`,
            });

            const id = response.data?.id;

            if (!children) return;

            let collectionArray = [];
            for (const element of children) {
                const elementObject = {
                    ecrf: '/api/ecrves/' + props.ecrfId,
                    element: '/api/elements/' + element.id,
                    data: [''],
                };
                collectionArray.push(elementObject);
            }

            await axiosSecureInstance.put(`/api/element_datas/${id}`, {
                data: ['table'],
                collection: [{ elements: collectionArray }],
            });

            await fetchElementData(id);
        } catch (error: any) {
            enqueueSnackbar(error?.response?.data?.detail, {variant: 'warning'});
        }

        setDataLoading(false);
    }

    /**
     * @todo use object from api types
     * This method is used to add now row - copy last collection and append new blank
     * @param collections
     * @param dataId
     */
    const createNewRow = async (collections: any, dataId: string) => {
        try {
            await axiosSecureInstance.put(`/api/element_datas/${dataId}`, {
                data: [0],
                collection: [...collections],
            });
        } catch (error: any) {
            enqueueSnackbar(error?.response?.data?.detail, {variant: 'warning'});
        }
    }

    /**
     * Handle new row
     */
    const handleAddRow = async () => {
        setDataLoading(true);

        if (data.collection && data.collection.length > 0) {
            const elementData = await dispatch(fetchElementDatas(props.elementData.id)).unwrap();
            await addNewRow(elementData.collection);
        } else {
            await createFirstRecord(props.children);
        }

        setDataLoading(false);
    }

    /**
     * Handle dependencies
     * @param collections
     */
    const handleDependencies = async (collections: any = []) => {
        if (!collections) {
            collections = data?.collection;
        }

        if (collections && collections.length > 0) {
            const dependencies = await Dependency.createDataCollection(
                props.element, collections
            );

            setDependencies(dependencies);
        } else {
            setDependencies([]);
        }
    }

    /**
     * Fetch history data of selected element
     * @param id
     */
    const handleHistory = async (id: string) => {
        setOpenHistory(id)
    };

    useEffect(() => {
        if (props.elementData?.id) {
            fetchElementData(props.elementData?.id);
        }

        /** clear state after unmount */
        return () => {
            dispatch(resetElementDatas());
        };
    }, [props.elementData])

    useEffect(() => {
        if (props.isRefreshElement) {
            if (props.elementData?.id) {
                setDependencies({})
                fetchElementData(props.elementData?.id).then();
                props.setRefreshElement(false);
            }
        }

        /** clear state after unmount */
        return () => {
            dispatch(resetElementDatas());
        };
    }, [props.isRefreshElement]);

    return (
        <>
            {/** children - columns names */}
            {props.children ? (
                <ScrollContainer ignoreElements={'input'}>
                    <StyledTableHeadAndRow>
                        <StyledTableWrapper>
                            <StyledTableHead size={props.children.length}>
                                {props?.children?.map((element: any) => (
                                    <StyledTableHeadItem key={element.id}>{element?.title}</StyledTableHeadItem>
                                ))}
                            </StyledTableHead>

                            {dependencies?.length > 0 && !isDataReloading && (
                                <>
                                    {dependencies.map((row: any) => (
                                        <TableGridRow
                                            fetchData={fetchElementData.bind(this)}
                                            columns={props?.children}
                                            row={row}
                                            dataId={data.id}
                                            changeReloadingValue={changeReloadingValue.bind(this)}
                                            handleHistory={handleHistory.bind(this)}
                                            status={props.status}
                                            options={props.element.options}
                                        />
                                    ))}
                                </>
                            )}

                            {(isInitialLoading || isDataLoading) && (
                                <Box display={'grid'} justifyContent={'center'} p={3}>
                                    <CircularProgress />
                                </Box>
                            )}

                            {props.element?.options?.addRow !== false && (
                                <Box width="100%" display={'grid'} justifyContent={'center'}>
                                    <Button
                                        startIcon={
                                            isDataLoading ? <CircularProgress size={12} /> : <AddIcon />
                                        }
                                        disabled={isDataLoading || props.status}
                                        color={'primary'}
                                        size={'small'}
                                        style={{ marginTop: '30px' }}
                                        onClick={handleAddRow}>
                                        {t('add-a-row')}
                                    </Button>
                                </Box>
                            )}
                        </StyledTableWrapper>
                    </StyledTableHeadAndRow>

                    {/** Modal history */}
                    <RowHistoryModal children={props?.children} open={openHistory} onClose={() => setOpenHistory(false)}/>
                </ScrollContainer>
            ) : (
                <Box display="block" width={'100%'} justifyContent={'center'}>
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

export default TableGridFormik;
