import { Box, Button } from "@mui/material";
import { ROUTES_ECRF } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import Table from "components/Table";
import {
    fetchEcrfVisits,
    resetEcrfVisits,
} from "features/dashboard/redux/dashboardSlice";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import DateTime from "core/helpers/date/DateTime";
import { selectUserProfile } from "core/redux/userSlice";
import { ecrfStatusDictionary } from "features/ecrf/views/EcrfForm/Menu/LeftPanel";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CallMadeIcon from '@mui/icons-material/CallMade';
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";
import { EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead } from "types/api.types";
import { roles } from 'commons/auth/roles';
import { FilterPanel } from "./FilterPanel";
import ObservationList from "./ObservationList";
import { DATETIME_FORMAT } from "commons/constants/env";
import { enqueueSnackbar } from 'notistack'
import Ecrf from "../../../../core/helpers/Ecrf";
import { fetchMedicalCenters } from "../../../medicalCenters/redux/medicalCentersSlice";
import { useTranslation } from "react-i18next";

const DashboardEcrves = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        dispatch(resetEcrfVisits())
        setOpen(false)
    };

    const [ecrves, setEcrves] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    })

    const loadMedicalCenters = async () => {
        await dispatch(fetchMedicalCenters({}))
    }

    /**
     * This function is used to fetch ecrves list
     */
    const [isDataLoading, setIsDataLoading] = useState(false)
    const fetchData = async (initial?: boolean) => {
        setIsDataLoading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/hal+json',
                    Accept: 'application/hal+json',
                },
                params: {
                    pagination: true,
                    page,
                    itemsPerPage: itemsPerPage,
                    ...Object.fromEntries(searchParams)
                },
            }

            const response = await axiosSecureInstance
                .get<HalResource<EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead[]>>
                (`/api/ecrves`, config)

            setEcrves(response.data)
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.response?.data?.detail, {variant: 'info'})
        }

        setIsDataLoading(false)
    }

    /**
     * This function is used to fetch data of ecrf's visits
     */
    const [isVisitsLoading, setIsVisitsLoading] = useState(false)
    const fetchVisits = async (id: string) => {
        setIsVisitsLoading(true)
        handleOpen();
        await dispatch(fetchEcrfVisits(id))
        setIsVisitsLoading(false)
    }

    /**
     * Check user role and hide export button for
     * All users except: Admin, Coordinator, Monitor, CRO, Sponsor
     */
    const userProfile = useAppSelector(selectUserProfile);

    const displayAE: boolean = userProfile.roles.some(
        (role: string) => (
            roles.admin.includes(role) ||
            roles.monitor.includes(role) ||
            roles.researcher.includes(role)
        )
    );

    // const displayExportButton: boolean = userProfile.roles.some(
    //     (role: string) => roles.admin.includes(role) || roles.monitor.includes(role)
    // );

    const displayMedicalCenter: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.research.includes(role)
    );

    // const displayObservationList: boolean = userProfile.roles.some(
    //     (role: string) => !roles.dataManager.includes(role) && !roles.control.includes(role)
    // );

    useEffect(() => {
        loadMedicalCenters().then()
        fetchData().then()
    }, [dispatch, page]);

    useEffect(() => {
        fetchData().then()
    }, [searchParams]);

    const columns = () => {
        const columns: any[] = [
            {
                title: <Box>{t('screening-number')}</Box>,
                render: (row: any) => (
                    <Box
                        style={{
                            opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%',
                            textAlign: "center",
                        }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                            }}
                            component={Link}
                            to={ROUTES_ECRF.DETAILS(row?.id || '')}
                        >{row?.screeningNumber}</Button>
                    </Box>
                ),
                key: 'participantNumber',
            },
            {
                title: <Box ml="20px">{t('randomization-date')}</Box>,
                render: (row: any) => (
                    <Box minWidth="120px"
                         style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                        {row?.randomAt && (
                            <Box>
                                {DateTime.toIso(row?.randomAt, DATETIME_FORMAT)}
                            </Box>
                        )}
                    </Box>
                ),
                key: 'randomAt',
            },
            {
                title: <Box ml="20px">{t('randomization-number')}</Box>,
                render: (row: any) => (
                    <Box minWidth="120px"
                         style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                        {row?.randomAt && <Box>{row?.randomId}</Box>}
                    </Box>
                ),
                key: 'randomNumber',
            },
            {
                title: <Box ml="20px">{t('center-number')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}
                    >{row?._embedded?.medicalCenter?.number}</Box>
                ),
                key: 'medicalCenter',
            },
            {
                title: <Box ml="20px">{t('status')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                        {t(ecrfStatusDictionary[row?.status])}
                    </Box>
                ),
                key: 'ecrfStatus',
            },
            {
                title: <Box ml="20px">{t('researcher-surname')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}
                    >{row?._embedded?.owner?.lastname}</Box>
                ),
                key: 'secondName',
            },
            {
                title: <Box ml="20px">{t('observation-list')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                        <div>
                            <Button
                                disabled={isVisitsLoading
                                    // || !displayListObservation
                            }
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={<FormatListBulletedIcon/>}
                                onClick={() => {
                                    fetchVisits(row?.id);
                                }}
                            >
                                {t('choose')}
                            </Button>
                        </div>
                    </Box>
                ),
                key: 'list',
            },
        ]

        displayAE && columns.push({
            title: <Box textAlign={'center'} ml="20px">{t('ae')}</Box>,
            render: (row: any) => (
                <Box textAlign={'center'}
                     style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                    <Box display="inline-flex">
                        <Button
                            component={Link}
                            disabled={!row?.adverseEventSection}
                            to={ROUTES_ECRF.DETAILS_EXTENDED(row.id, row?.adverseEventSection)}
                            variant="outlined"
                            color="primary"
                            size="small"
                            endIcon={<CallMadeIcon/>}
                        >
                            {t('go')}
                        </Button>
                    </Box>
                </Box>
            ),
            key: 'aesae',
        })

        columns.push({
            title: <Box textAlign={'center'} minWidth="60px">{t('queries')}</Box>,
            render: (row: any) => (
                <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%', placeItems: "center"}}
                     textAlign={'center'}
                     display="grid">
                    <Box
                        fontWeight={row?.unreadComments > 0 ? 700 : 400}
                        display="flex"
                        textAlign="center"
                    >
                        {row?.unreadComments}
                    </Box>
                </Box>
            ),
            key: 'queries',
        })

        columns.push({
            title: <Box ml="20px">{t('form')}</Box>,
            noWrap: true,
            render: (row: any) => (
                <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
                    <Box display="inline-flex">
                        <Button
                            component={Link}
                            to={ROUTES_ECRF.DETAILS(row?.id || "")}
                            variant="outlined"
                            color="primary"
                            size="small"
                            endIcon={<CallMadeIcon/>}
                        >
                            {t('go')}
                        </Button>
                    </Box>
                </Box>
            ),
            key: 'actions',
        })

        return columns
    }

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            <FilterPanel displayMedicalCenter={displayMedicalCenter} />
            <Table
                pagination={{
                    current: page,
                    pageSize: ecrves.itemsPerPage,
                    total: ecrves.totalItems,
                }}
                onChangePage={(page) => setPage(page)}
                data={ecrves._embedded?.item || []}
                tableName={t('study-participants')}
                columns={columns()}
                isLoading={isDataLoading}
            />

            <ObservationList loading={isVisitsLoading} open={open} handleClose={handleClose}/>
        </ColoredBox>
    );
};

export default DashboardEcrves;
