import React from 'react';
import {useTranslation} from "react-i18next";

interface Error {
    message: string;
}

export const SnackbarErrors = ({ title, errors = [] }: { title: string; errors: Error[] }) => {
    const { t } = useTranslation();
    return (
        <div>
            <p style={{ marginBottom: '10px' }}>
                <b>{title ?? t('an-error-occurred')}</b>
            </p>
            {errors.map((error: Error) => (
                <div style={{ marginBottom: '10px' }}>{error.message}</div>
            ))}
        </div>
    );
};
