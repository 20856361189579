import styled from 'styled-components';
import {COLOR_BG_DARK, COLOR_BG_NAV} from "../../../../commons/constants/env";

/* Main body */

export const StyledDashboardQueriesWrapper = styled.div`
    width: 100%;
    color: #243A4E;
    font-size: 13px;
    padding-bottom: 30px;
`;

export const StyledDashboardQueriesTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
`;


/* Table */

export const StyledDashboardQueriesTable = styled.div<any>`
    width: ${(props) => (props.isOpen ? 'calc(100% - 350px)' : '100%')};
    transition: 0.2s ease;
`;

export const StyledDashboardQueriesTableHead = styled.div`
    margin-top: 22px;
    position: -webkit-sticky;
	position: sticky;	
	z-index: 1;
	top: 55px;
    background-color: ${COLOR_BG_DARK};
    color: #8E9AA4;
    font-weight: bold;
    text-align: center;
    display: grid;
    grid-template-columns: 30px 40px repeat(7, minmax(100px, 1fr)) 300px  repeat(2, minmax(130px, 1fr));
    padding: 22px 30px;
    place-items: center;
    border-radius: 3px;
    width: calc(100% - 60px);
    min-width: 1400px;
`;

export const StyledDashboardQueriesTableBody = styled.div`
    width: 100%;
`;

export const StyledDashboardQueriesTableRow = styled.div<any>`
    width: calc(100% - 60px);
    min-width: 1400px;
    text-align: center;
    display: grid;
    grid-template-columns: 30px 40px repeat(7, minmax(100px, 1fr)) 300px  repeat(2, minmax(130px, 1fr));
    place-items: center;
    margin: 10px 0px;
    padding: 10px 30px;
    transition: .2s ease;
    border-radius: 3px;
    background-color: ${(props) => (props?.status === 'new' ? '#FEFCE8' : '#fff')};
    opacity: ${(props) => (props?.status === 'verified' ? '50%' : '100%')};
    color: ${(props) => (props?.status === 'new' ? '#CA8A04' : '#243A4E;')};
    
     &:hover {
        background-color:${(props) => (props?.status === 'new' ? '#FEF9C3' : COLOR_BG_DARK)};
        opacity: 100%;
    }
`;

export const StyledDashboardQueriesTableCell = styled.span`
    text-align: center;
    margin: 0px 10px;
`;

export const StyledDashboardQueriesTableCellIcons = styled.span`
    text-align: center;
    margin: 0px 10px;
    display: flex;
    gap: 20px;
`;

export const StyledDashboardQueriesTableCellIcon = styled.span<any>`
    display: grid;
    place-items: center;
    border-radius: 3px;
    border: 1px solid #D3D8DC;
    padding: 6px;
    cursor: ${(props) => (props.status ? 'pointer' : 'not-allowed')};
    transition: .2s ease;
    opacity: ${(props) => (props.status ? '100%' : '30%')};
    
    &:hover {
      border: ${(props) => (props.status ? '1px solid #243A4E' : '1px solid #D3D8DC')};
      
    }
`;

export const StyledDashboardQueriesTableStatus = styled.span<any>`
    font-size: 12px;
    padding: 7px 21px;
    background-color: ${(props) => (props.bg)};
    color: ${(props) => (props.color)};
    border-radius: 1223px;
`;

export const StyledDashboardQueriesDetails = styled.div<any>`
    margin: 30px;
    border-left: 2px solid ${COLOR_BG_NAV};
    padding-left: 30px;
    position: relative;
    
    &:before {
        content: "";
        position: absolute;
        background-color: #4ADE80;
        width: 7px;
        height: 7px;
        border-radius: 1111px;
        top: -18px;
        left: -4px;
    }
`;

export const StyledDashboardQueriesDetailsTop = styled.div<any>`
    margin: 0px 30px 30px 30px;
    border-left: 2px solid ${COLOR_BG_NAV};
    padding-left: 30px;
    position: relative;
    
    &:before {
        content: "";
        position: absolute;
        background-color: #FACC15;
        width: 7px;
        height: 7px;
        border-radius: 1111px;
        top: -18px;
        left: -4px;
    }
`;

export const StyledDashboardQueriesDetailsTitle = styled.div<any>`
    font-size: 12px;
    opacity: 50%;
    margin-bottom: 12px;
`;

export const StyledDashboardQueriesDetailsContent = styled.span<any>`
    padding: 10px 30px;
    background-color: ${COLOR_BG_DARK};
    border-radius: 10px;
    max-width: 700px;
`;

export const StyledDashboardQueriesDetailsDate = styled.span<any>`
    font-size: 12px;
    opacity: 50%;
    white-space: nowrap;
`;