import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import * as util from "util";

interface Element {
  data: any;
  name: string;
  children: any;
  history: any;
}

const initialState: Element = {
  data: [],
  name: '',
  children: [],
  history: '',
};

export const fetchElementChildren = createAsyncThunk(
    'element/fetchElementChildren',
    async ({id, sectionId}: any, thunkAPI) => {
      try {
        const response = await axiosSecureInstance.get<any[]>(
            `/api/elements/${id}/childrens`
        );
        return response.data
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
)

export const fetchElementHistory = createAsyncThunk(
  'element/history',
  async (elementDataId: string, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.get<any>(
        `/api/element_histories?element=${elementDataId}`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const elementSlice = createSlice({
  name: 'element',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchElementChildren.fulfilled, (state, action) => {
      state.children = action.payload;
    });
    builder.addCase(fetchElementHistory.fulfilled, (state, action) => {
      state.history = action.payload;
    });
  },
});

export const selectElementData = (state: RootState) => state.ecrf.element.data;
export const selectElementName = (state: RootState) => state.ecrf.element.name;
export const selectElementHistory = (state: RootState) => state.ecrf.element.history;

export default elementSlice.reducer;
