import styled, { css } from "styled-components";

export const StyledDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

interface StyledContentProps {
  centerChildrenVertically?: boolean;
}

export const StyledContent = styled.div<StyledContentProps>`
  flex-grow: 1;
  margin-top: 56px;
  padding-top: 32px;
  padding-bottom: 32px;
  ${({ centerChildrenVertically }) =>
    centerChildrenVertically &&
    css`
      display: flex;
      align-items: center;
    `}
`;
