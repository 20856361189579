import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import { map } from "lodash";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import DataGridHelper from "./DataGridHelper";

interface GridCellExpandProps {
    type?: string;
    options: any;
    value?: any;
    width?: number;
    params: GridRenderEditCellParams;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    }),
);

function isOverflown(element: Element): boolean {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

const GridEditCellExpand = React.memo(function GridEditCellExpand(
    props: GridCellExpandProps,
) {
    const apiRef = useGridApiContext();
    const classes = useStyles();
    const wrapper = React.useRef<HTMLDivElement | null>(null);

    const { type, width, options, params } = props;
    const { id, field, value, colDef, hasFocus, api } = props.params;
    const [valueState, setValueState] = React.useState(value);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();

    const handleRef = React.useCallback((el: HTMLElement | null) => {
        setAnchorEl(el);
    }, []);

    const handleChange = async (event: any) => {
        const isValid = await apiRef.current.setEditCellValue({
            id,
            field,
            value: {
                // id: ed?.element?.id,
                type: event.target.type || type,
                // options: ed?.element?.options,
                options: options,
                value: event.target.value
            },
        });

        // if (isValid) {
        //     apiRef.current.stopCellEditMode({ id, field });
        // }
    };

    const renderElement = () => {
        const valueData = DataGridHelper.valueGetter(value)

        switch (type) {
            case 'checkboxes':
            case 'checkbox_multiple': {
                return (
                    <FormGroup>
                        {map(options?.choices, (val, key) => {
                            return (
                                <FormControlLabel
                                    control={<Checkbox
                                        defaultChecked={Array.isArray(valueData) && value.includes(val)}
                                        onChange={handleChange}
                                    />}
                                    label={key}
                                />
                            )
                        })}
                    </FormGroup>
                );
            }
            case 'radio': {
                return (
                    <FormControl>
                        <RadioGroup value={String(valueData)} onChange={handleChange} style={{ padding: 4 }}>
                            {map(options, ({value, label}) => {
                                return (<FormControlLabel value={String(value)} control={<Radio />} label={String(label)} key={label} />)
                            })}
                        </RadioGroup>
                    </FormControl>
                );
            }
            default:
                return valueData
        }
    }

    return (
        <div
            ref={wrapper}
            className={classes.root}
        >
            <div
                ref={handleRef}
                style={{
                    height: 1,
                    width: width || colDef.computedWidth,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            {anchorEl && (
                <Popper
                    open
                    anchorEl={anchorEl}
                    style={{ width }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
                    >
                        <Typography component="span" variant="body2" style={{ padding: 9 }}>
                            {renderElement()}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
});

export default GridEditCellExpand