import TextFieldFormik from "components/Form/TextFieldFormik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import { Form, useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import {
  fetchCitiesDictionary,
  fetchCountriesDictionary,
  selectCitiesDictionary,
  selectCountriesDictionary,
} from "core/redux/dictionariesSlice";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";

export interface InitialValuesState {
  name: string;
  address: {
    street: string;
    number: string;
    city: string;
    postcode: string;
    country: string;
  };
  latitude: string;
  longitude: string;
  number: string;
}

const MedicalCenterForm = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectCountriesDictionary);
  const cities = useAppSelector(selectCitiesDictionary);
  const { values } = useFormikContext<InitialValuesState>();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchCountriesDictionary());
  }, [dispatch]);

  useEffect(() => {
    if (values.address.country) {
      dispatch(fetchCitiesDictionary(values.address.country));
    }
  }, [dispatch, values.address.country]);

  return (
    <Form>
      <Box>
        <TextFieldFormik
          margin="dense"
          name="name"
          label={t('name')}
          variant="filled"
          fullWidth
        />
          <TextFieldFormik
          margin="dense"
          name="number"
          label={t('center-number')}
          variant="filled"
          fullWidth
        />
      </Box>
      <Box mt={2}>
        <Typography variant="body2">{t('address')}:</Typography>
        <TextFieldFormik
          margin="dense"
          name="address.country"
          label={t('country')}
          variant="filled"
          fullWidth
          select
        >
          {countries.map((country: any) => (
            <MenuItem key={country.id} value={country.id}>
              {country.name}
            </MenuItem>
          ))}
        </TextFieldFormik>
        <TextFieldFormik
          margin="dense"
          name="address.city"
          label={t('city')}
          variant="filled"
          fullWidth
          select
          disabled={!values.address.country}
        >
          {cities.map((city: any) => (
            <MenuItem key={city.id} value={city.id}>
              {city.name}
            </MenuItem>
          ))}
        </TextFieldFormik>
        <TextFieldFormik
          margin="dense"
          name="address.street"
          label={t('street')}
          variant="filled"
          fullWidth
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextFieldFormik
            margin="dense"
            name="address.postcode"
            label={t('postcode')}
            variant="filled"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormik
            margin="dense"
            name="address.number"
            label={t('number')}
            variant="filled"
            fullWidth
          />
        </Grid>
      </Grid>

      <Box mt={2}>
        <Typography>{t('geographical-coordinates')}</Typography>
        <TextFieldFormik
          margin="dense"
          name="latitude"
          label={t('latitude')}
          variant="filled"
          fullWidth
        />
        <TextFieldFormik
          margin="dense"
          name="longitude"
          label={t('longitude')}
          variant="filled"
          fullWidth
        />
      </Box>

      <Box mt={2} display="flex" justifyContent="flex-end">
        <Box boxShadow={theme.shadows[4]}>
          <Button type="submit" color="primary" variant="contained">
            {t('save')}
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default MedicalCenterForm;
