/*
 * Project: ecrf-fron
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 22.04.2022
 * Time: 09:53
*/

import axiosSecureInstance from "commons/axios/axiosSecureInstance";

export default class ProvideService {
    /**
     * This method is used to fetch allocated drugs
     * @param ecrfId
     * @param elementId
     */
    public async allocation(ecrfId: string, elementId: string) {
        try {
            return await axiosSecureInstance.get<any>(`/api/ecrves/${ecrfId}/${elementId}/drug/allocation`, {
                headers: {
                    accept: 'application/hal+json',
                }
            });
        } catch (err: any) {
            return err?.response
        }
    }

    /**
     * This method is used to provide selected drugs
     * @param id
     * @param data
     */
    public async provide(id: string, data: any): Promise<any[]> {
        try {
            const response = await axiosSecureInstance.put(`/api/ecrves/${id}/drug/provide`, data);
            return response as any
        } catch (err: any) {
            return err?.response
        }
    }

    /**
     * This method is used to save element data after provide drugs
     * @param elementId
     * @param ecrfId
     * @param data
     */
    public async putElementData(elementId: string, ecrfId: string, data: any): Promise<any[]> {
        try {
            const response = await axiosSecureInstance.put(`/api/element_datas/element/${elementId}`, {
                data,
                ecrf: `/api/ecrves/${ecrfId}`,
            });
            return response as any
        } catch (err: any) {
            return err?.response
        }
    }
}