import { roles } from 'commons/auth/roles';

export default class ElementHistory {
  /**
   * This method is used to control visibility of 'show changes' button
   */
  public static controller(elementType: string, profileRoles: any, isTable?: boolean, isData?: any) {
    if (!isData) {
      return false;
    }

    switch (elementType) {
      case 'adverse_event':
      case 'button':
      case 'data_grid':
      case 'dicom_button':
      case 'close_observation_period':
      case 'collection':
      case 'randomization':
      case 'table': {
        return false;
      }
    }

    if (isTable) {
      return false;
    }

    // roles
    return !!profileRoles.some((role: string) => roles.admin.includes(role) || roles.monitor.includes(role));
  }
}
