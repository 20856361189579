import abm from "./images/abm.png";
import umw from "./images/umw.png";
import bio50 from "./images/50bio.png";
import neoVinci from "./images/neovinci.png";
import projectRefsal from "commons/assets/projekt-refsal.png";
import headerImg from "./images/header.jpg";
import { useRef } from "react";
import {
    Box,
    Button,
    Container,
    Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTES_CORE } from "commons/constants/paths";
import {
    StyledHeaderSection,
    StyledHeaderImg,
    StyledHeaderHolder,
    StyledHeaderTitle,
    StyledHeaderSubtitle,
    StyledSectionTitle,
    StyledInfoSectionDescription,
    StyledAnchor,
    StyledRefsalLogo,
    StyledNavbarList,
    StyledNavbarListItem,
    StyledFooter,
    StyledFooterDescription,
    StyledFooterSection,
    StyledFooterTitle,
    StyledFooterMenu,
    StyledFooterMenuLink,
    StyledFooterImg,
    StyledFooterImgSpan,
    StyledContactWayTitle,
    StyledFooterAnchor,
} from "./Home.styled";
import MedicalCenter from "../components/MedicalCenter";
import { PROJECT_NAME, PROJECT_PHONE } from "../../../../commons/constants/env";

function Home() {
    let info: any = useRef();

    return (
        <div>
            <header>
                <Container maxWidth="lg">
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid container item lg={3} xs={12} spacing={0}>
                            <StyledRefsalLogo src={projectRefsal} alt="Projekt Refsal logo"/>
                        </Grid>
                        <Grid container item lg={9} xs={12} spacing={0}>
                            <StyledNavbarList>
                                <StyledNavbarListItem>
                                    <Button
                                        onClick={() =>
                                            window.scrollTo({
                                                behavior: "smooth",
                                                top: info.current.offsetTop,
                                            })
                                        }
                                        size="medium"
                                    >
                                        INFORMACJE O BADANIU
                                    </Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        to={ROUTES_CORE.LOGIN}
                                        component={Link}
                                    >
                                        LOGOWANIE
                                    </Button>
                                </StyledNavbarListItem>
                            </StyledNavbarList>
                        </Grid>
                    </Grid>
                </Container>
            </header>

            <StyledHeaderSection>
                <StyledHeaderImg src={headerImg} alt="Background img"/>
                <StyledHeaderHolder>
                    <Container maxWidth="lg">
                        <StyledHeaderTitle>
                            PROJEKT <b>REFSAL</b>
                        </StyledHeaderTitle>
                        <StyledHeaderSubtitle>
                            Finansowane w ramach programu: działalność badawczo-rozwojowa w zakresie niekomercyjnych
                            badań klinicznych.
                        </StyledHeaderSubtitle>
                        <br/>
                        <StyledHeaderSubtitle>
                            Ocena częstości występowania przetrwałego nadciśnienia płucnego u
                            noworodków w grupie noworodków urodzonych pomiędzy 32 a 42
                            tygodniem ciąży leczonych salbutamolem
                        </StyledHeaderSubtitle>
                    </Container>
                </StyledHeaderHolder>
            </StyledHeaderSection>
            <section ref={info}>
                <Container maxWidth="lg">
                    <StyledSectionTitle textAlign="center">
                        Informacje o badaniu
                    </StyledSectionTitle>
                    <StyledInfoSectionDescription>
                        TTN (przemijający szybki oddech noworodka) jest jedną z
                        najczęstszych przyczyn niewydolności oddechowej noworodków. TTN
                        występuje u ok. 10% noworodków urodzonych między 33 a 34 tygodniem
                        ciąży. Istnieją również doniesienia o "złośliwym TTN", w którym
                        dzieci rozwijają przetrwałe nadciśnienie płucne noworodków (PPHN).
                        Noworodki z TTN zwykle wymagają nieinwazyjnego wspomagania
                        oddychania (np. donosowym ciągłym dodatnim ciśnieniem w drogach
                        oddechowych, nCPAP) i mogą wymagać wyższych stężeń tlenu w
                        mieszaninie oddechowej w celu utrzymania prawidłowego poziomu
                        utlenowania. Dotychczasowe badania kliniczne sugerują, że wczesne
                        zastosowanie ciśnień rozprężających oraz zastosowanie wziewnego
                        salbutamolu może złagodzić przebieg ciężkiej postaci TTN, aczkolwiek
                        brak jednoznacznych wyników uniemożliwia stosowanie tego preparatu
                        jako standardowej terapii.
                    </StyledInfoSectionDescription>
                    <MedicalCenter />
                </Container>
            </section>
            <StyledFooter>
                <Container maxWidth="lg">
                    <StyledFooterSection>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <StyledFooterTitle>Menu:</StyledFooterTitle>
                                <StyledFooterMenu>
                                    <StyledFooterMenuLink href="#">
                                        INFORMACJE O BADANIU
                                    </StyledFooterMenuLink>
                                </StyledFooterMenu>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <StyledFooterTitle>Kontakt:</StyledFooterTitle>
                                <StyledContactWayTitle>e-mail: </StyledContactWayTitle>
                                <StyledAnchor href="mailto:sekretariat@refsal.pl">
                                    sekretariat@refsal.pl
                                </StyledAnchor>
                                <br/>

                                <StyledContactWayTitle>telefon: </StyledContactWayTitle>
                                <StyledFooterAnchor href={`tel:${PROJECT_PHONE}`}>
                                    {PROJECT_PHONE}
                                </StyledFooterAnchor>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <StyledFooterTitle>Logowanie do eCRF:</StyledFooterTitle>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        to={ROUTES_CORE.LOGIN}
                                        component={Link}
                                    >
                                        LOGOWANIE
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </StyledFooterSection>
                    <StyledFooterSection>
                        <StyledFooterDescription>
                            NAZWA BENEFICJENTA: WARSZAWSKI UNIWERSYTET MEDYCZNY - Lider
                            <br/>
                            <br/>
                            <Box fontWeight={'bold'} mb={1}>
                                Konsorcjum w składzie:
                            </Box>
                            <Box display={'grid'} lineHeight={'23px'} fontSize={'.9rem'}>
                                - Szpital Kliniczny im. ks. Anny Mazowieckiej w Warszawie
                                <br/>
                                - Samodzielny Publiczny Szpital Kliniczny nr 2 PUM w Szczecinie
                                <br/>
                                - Samodzielny Publiczny Zakład Opieki Zdrowotnej Szpital Uniwersytecki w Krakowie
                                <br/>
                                - Uniwersytet Medyczny im. Karola Marcinkowskiego w Poznaniu
                                <br/>
                                - Szpital Uniwersytecki im. Karola Marcinkowskiego Zielonej Górze
                                <br/>
                                - Szpital Uniwersytecki nr 2 im. dr Jana Bizela w Bydgoszczy
                                <br/>
                                - Kliniczny Szpital Wojewódzki nr 2 im. Św. Jadwigi Królowej w Rzeszowie
                                <br/>
                                - Szpital Specjalistyczny nr 2 w Bytomiu
                                <br/>
                                - Children's Hospital of Eastern Ontario, Kanada
                            </Box>
                            <br/>
                            <Box fontWeight={'bold'} fontSize={'1.1rem'} m={1}>
                                WARTOŚĆ PROJEKTU: 14 814 484,00 PLN
                            </Box>
                            <Box fontWeight={'bold'} fontSize={'1.1rem'} m={1}>
                                WARTOŚĆ DOFINANSOWANIA: 14 814 484,00 PLN
                            </Box>
                            <Box fontSize={'.9rem'}>
                                OKRES REALIZACJI: 01.10.2020 - 30.09.2024
                            </Box> <br/>
                            Projekt finansowany przez Agencję Badań Medycznych, Polska, numer projektu
                            2019/ABM/01/00058-00
                        </StyledFooterDescription>

                        <Grid container spacing={3} justifyContent={'center'}>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg
                                    src={projectRefsal}
                                    alt={PROJECT_NAME}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImgSpan>
                                    Kontraktowa organizacja badawcza (CRO):
                                </StyledFooterImgSpan>
                                <StyledFooterImg src={bio50} alt="50Bio logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg src={umw} alt="UMW logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImg src={abm} alt="ABM logo"/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImgSpan>
                                    Organizacja techniczna:
                                </StyledFooterImgSpan>
                                <StyledFooterImg src={neoVinci} alt="Neo-Vinci logo"/>
                            </Grid>
                        </Grid>
                    </StyledFooterSection>
                </Container>
            </StyledFooter>
        </div>
    );
}

export default Home;
