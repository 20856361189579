import React, {Component} from 'react';
import { StyledFileStatus } from "../views/Documentation.styled";
import { useTranslation } from "react-i18next";

const withRouter = (WrappedComponent: any) => (props: any) => {
    const { t } = useTranslation();

    return (
        <WrappedComponent
            {...props}
            t={t}
        />
    );
};

class FileStatus extends Component<any, any> {
    state = {
        bg: '#fff',
        color: '#black',
        text: '-'
    }

    /**
     * This method is used to load variant and render it
     */
    private loadVariant() {
        switch (this.props.variant) {
            case 1: {
                this.setState({bg: '#FEF9C3', color: '#CA8A04', text: this.props?.t('approved')});
                break;
            }
            case 2: {
                this.setState({bg: '#DCFCE7', color: '#16A34A', text: this.props?.t('opened')});
                break;
            }
            case 3: {
                this.setState({bg: '#DCFCE7', color: '#16a389', text: this.props?.t('outdated')});
                break;
            }
            case 0:
            default: {
                this.setState({bg: '#bbbbba', color: '#333232', text: this.props?.t('draft')});
                break;
            }
        }
    }

    componentDidMount() {
        this.loadVariant()
    }

    componentDidUpdate(prevProps: any){
        if (prevProps !== this.props) {
            this.loadVariant()
        }
    }

    render() {
        return (
            <StyledFileStatus color={this.state.color} bg={this.state.bg}>
                {this.state.text}
            </StyledFileStatus>
        );
    }
}

export default withRouter(FileStatus);