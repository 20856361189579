import React, {useState} from "react";
import {
    GridEditBooleanCell,
    GridEditDateCell,
    GridEditInputCell,
    GridEditSingleSelectCell,
    GridRenderEditCellParams,
    useGridApiContext,
} from "@mui/x-data-grid";
import GridEditCellExpand from "./GridEditCellExpand";
import DataGridHelper from "./DataGridHelper";

interface GridCellProps {
    type: string;
    options: any;
    value?: any;
    params: GridRenderEditCellParams | any;
}

const GridEditCell = React.memo(function GridEditCell(
    props: GridCellProps,
) {
    const apiRef = useGridApiContext();
    const ref = React.useRef();

    const { type, options, params} = props;
    const { id, field, value, colDef, hasFocus, api } = props.params;
    const [valueOptions, setValueOptions] = useState<any>({})

    React.useLayoutEffect(() => {
        if (hasFocus) {
            // ref.current?.focus();
        }
    }, [hasFocus]);

    const handleChange = async (event: any) => {
        // console.log('GridEditCell handleChange')

        const t = (event.target.type || value?.type || type);
        const v = event.target.value;

        // console.log(t)
        // console.log(v)
        // console.log(DataGridHelper.retype(t))
        // console.log(DataGridHelper.formatValue(t, v))

        const isValid = await apiRef.current.setEditCellValue({
            id,
            field,
            value: {
                // id: ed?.element?.id,
                // type: event.target.type || value?.type || type,
                type: DataGridHelper.retype(t),
                options: valueOptions || options,
                // value: DataGridHelper.formatValue(t, v)
                value: v
            },
        });

        // if (isValid) {
        //     apiRef.current.stopCellEditMode({ id, field });
        // }
    };

    const renderElement = () => {
        let data = params?.value
        const valueType = DataGridHelper.retype(value?.type || data?.type || type)

        if (typeof params?.value !== 'string' && params.colDef.valueFormatter && data instanceof Object) {
            data = params.colDef.valueFormatter(params);
        }

        switch (valueType) {
            // unsupported column elements
            case 'checkboxes':
            case 'checkbox_multiple':
            case 'select_multiple': {
                return data || ''
            }
            case 'boolean':
            case 'checkbox': {
                return <GridEditBooleanCell
                    {...params}
                    ref={ref}
                    id={params?.id}
                    value={data}
                    onValueChange={handleChange}
                />
            }
            case 'date':
            case 'dateTime':
            case 'datetime':
            case 'datetime-local':
            case 'time': {
                return <GridEditDateCell
                    {...params}
                    id={params?.id}
                    value={data}
                    // onValueChange={handleChange}
                />
            }
            case 'radio': {
                return <GridEditCellExpand
                    type={valueType}
                    options={options ?? []}
                    value={data}
                    width={params.colDef?.minWidth || 170}
                    params={params}
                />
            }
            case 'select':
            case 'singleSelect': {
                return <GridEditSingleSelectCell
                    {...params}
                    id={params?.id}
                    value={data}
                    onValueChange={handleChange}
                />
            }
            default:
                return <GridEditInputCell
                    {...params}
                    // id={params?.row?.id || params?.id}
                    id={params?.id}
                    type={valueType}
                    value={data}
                    onValueChange={handleChange}
                />
        }
    }

    return renderElement();
});

export default GridEditCell