import styled from 'styled-components';
import {COLOR_BG_DARK, COLOR_PRIMARY, COLOR_PRIMARY_LIGHT, COLOR_SECONDARY} from "../../../commons/constants/env";

/** Main layout container */
export const StyledDocumentationWrapper = styled.div`
    margin-top: 120px;
    //margin-bottom: 120px;
    display: grid;
    color: #060a32;
    place-items: center;
`;

export const StyledDocumentationLayout = styled.div`
    width: 100%;
    max-width: 90vw;
    color: #060a32;
`;

export const StyledDocumentationTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
    padding-bottom: 20px;
`;

export const StyledDocumentationTitle2 = styled.div`
    font-size: 20px;
    opacity: 50%;
`;

/** Buttons */
export const StyledDocumentationButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
    justify-content: start;
`;

/** Table */
export const StyledDocumentationTable = styled.div`
    display: grid;
`;

export const StyledDocumentationTableHead = styled.div`
    display: grid;
    background-color: ${COLOR_BG_DARK};
    color: ${COLOR_SECONDARY};
    place-items: center stretch;
    text-align: left;
    padding: 10px 0;
    margin-top: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-bottom: 5px;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
`;

export const StyledDocumentationTableBody = styled.div`
    display: grid;
`;

export const StyledDocumentationTableRow = styled.div`
    display: grid;
    font-size: 14px;
    padding: 15px 0;
    text-align: left;
    grid-template-columns: 2fr 1fr 1fr;
    transition: 0.2s ease;
    border-radius: 5px;
    background: #f7f7f7;

    &:hover {
        background-color: #f0f0f0;
    }
`;

export const StyledDocumentationSelect = styled.div<any>`
    display: flex;
    padding: 8px 20px;
    gap: 12px;
    border: 1px solid ${COLOR_PRIMARY};
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: ${(props) => (props.readonly ? '' : COLOR_PRIMARY_LIGHT)};
        border: ${(props) => (props.readonly ? `1px solid ${COLOR_PRIMARY}` : `1px solid ${COLOR_PRIMARY}`)};
    }
`;

export const StyledDocumentationDate = styled.input<any>`
    display: flex;
    padding: 8px 20px;
    border: 1px solid ${COLOR_PRIMARY};
    border-radius: 5px;
    cursor: text;
    transition: 0.3s ease;
    align-items: center;
    background: none;
    height: 20px;
    align-items: center;

    ::-webkit-file-upload-button {
        display: none;
    }

    ::-webkit-inner-spin-button,
    ::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

     &:hover {
        background-color: ${(props) => (props.readonly ? '' : COLOR_PRIMARY_LIGHT)};
        border: ${(props) => (props.readonly ? `1px solid ${COLOR_PRIMARY}` : `1px solid ${COLOR_PRIMARY}`)};
    }
`;

export const StyledDocumentationButton = styled.div<any>`
    display: flex;
    padding: 8px 30px;
    color: ${COLOR_PRIMARY};
    gap: 12px;
    background-color: ${COLOR_PRIMARY_LIGHT};
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid ${COLOR_PRIMARY_LIGHT};

      &:hover {
        border: ${(props) => (props.readonly ? `1px solid ${COLOR_PRIMARY}` : `1px solid ${COLOR_PRIMARY}`)};
    }
`;

export const StyledDocumentationModalWrapper = styled.div`
    background-color: ${COLOR_BG_DARK};
    padding: 30px;
    padding-bottom: 80px;
    padding-top: 80px;
    border-radius: 10px;
    display: grid;
    place-items: center;
`;

export const StyledDocumentationModalText = styled.div`
    color: #aeb2c2;
    font-size: 12px;
    font-weight: bold;
    margin-top: 35px;
    margin-bottom: 12px;
`;

export const StyledDocumentationModalText2 = styled.div`
    display: flex; 
    gap: 10px;
    font-size: 13px;
    padding: 3px 3px;
    border-radius: 5px;
    transition: 0.3s ease;
    align-items: center;
    padding-right: 70px;
    max-width: 400px;
`;

export const StyledDocumentationModalCentersWrapper = styled.div`
    max-height: 30vh;
    overflow-y: scroll;
    margin-top: 20px;
    
    &::-webkit-scrollbar {
        all: unset;
        height: 5px;
        background-color: #f7faff;
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #d9e6fe;
        height: 3px;
        border-radius: 9999px;
        width: 5px;
        cursor: auto;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #175aef;
        cursor: auto;
    }
`;

export const StyledFileStatus = styled.span<any>`
    font-size: 12px;
    padding: 5px 15px;
    background-color: ${(props) => (props.bg)};
    color: ${(props) => (props.color)};
    border-radius: 20px;
`;
