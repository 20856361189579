import { Collapse, Link } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { StyledEcrfSectionsItem } from '../Ecrf.styled';
import { MenuItemChild } from './MenuItemChild';
import { ROUTES_ECRF } from "../../../../../commons/constants/paths";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
    section: any;
    selectedSection: any;
    setSelectedSection: (selectedSection: any) => void;
    isDataLoading: boolean;
    setIsDataLoading: (isDataLoading: boolean) => void;
    displayCount: boolean;
}

export const MenuItem: React.FC<Props> = ({
  section,
  selectedSection,
  isDataLoading,
  setIsDataLoading,
  setSelectedSection,
  displayCount
}) => {
    const navigate = useNavigate();
    const {id, section: sectionId} = useParams<{ id: string, section: string }>();
    const [expandSection, setExpandSection] = useState(false);

    /**
     * Select section contents and save it to global state
     */
    const changeSection = async (sectionId: any) => {
        if (isDataLoading) return;

        setSelectedSection(sectionId);
        id && navigate(ROUTES_ECRF.DETAILS_EXTENDED(id, sectionId || ''));
    }

    /**
     * This function will expand section if selected is a child
     */
    const initialExpandValue = () => {
        for (const element of section.children) {
            if (element.id === sectionId) {
                setExpandSection(true);
                return;
            }
        }
    }

    useEffect(() => {
        initialExpandValue();
    }, [selectedSection])

    return (
        <>
            <StyledEcrfSectionsItem
                section={section}
                selected={section?.id === sectionId}
                onClick={() => {if (!isDataLoading) {
                    setExpandSection(!expandSection)
                    changeSection(section?.id)
                }}}
                // href={ROUTES_ECRF.DETAILS_EXTENDED(id || '', section?.id)}
            >
                {/*<Link href={ROUTES_ECRF.DETAILS_EXTENDED(id || '', section?.id)}>*/}
                    {section?.name}{" "} {displayCount ? `(${section?.countElementsWithData}/${section?.countElements})` : ''}
                {/*</Link>*/}

                {section?.children.length > 0 && <>{expandSection ? <ExpandLess /> : <ExpandMore />}</>}
            </StyledEcrfSectionsItem>

            {section?.children.length > 0 && (
                <Collapse in={expandSection} unmountOnExit>
                    {section?.children.map((childrenSection: any) => (
                        <MenuItemChild
                            key={childrenSection?.id}
                            section={childrenSection}
                            selectedSection={selectedSection === childrenSection?.id}
                            setSelectedSection={setSelectedSection}
                            isDataLoading={isDataLoading}
                            setIsDataLoading={setIsDataLoading}
                            displayCount={displayCount}
                            changeSection={changeSection}
                        />
                    ))}
                </Collapse>
            )}
        </>
    );
};
