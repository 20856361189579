export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const EVENT_STORE_DEBUG_MODE = process.env.REACT_APP_EVENT_STORE_DEBUG_MODE;

export const COLOR_PRIMARY = process.env.REACT_APP_COLOR_PRIMARY || '#474747';
export const COLOR_PRIMARY_LIGHT = process.env.REACT_APP_COLOR_PRIMARY_LIGHT || '#d1d1d1';
export const COLOR_PRIMARY_DARK = process.env.REACT_APP_COLOR_PRIMARY_DARK || '#808080';
export const COLOR_SECONDARY = process.env.REACT_APP_COLOR_SECONDARY || '#474747';
export const COLOR_SECONDARY_LIGHT = process.env.REACT_APP_COLOR_SECONDARY_LIGHT || '#d1d1d1';
export const COLOR_SECONDARY_DARK = process.env.REACT_APP_COLOR_SECONDARY_DARK || '#808080';
export const COLOR_BG = process.env.REACT_APP_COLOR_BG || '#FFF';
export const COLOR_BG_DARK = process.env.REACT_APP_COLOR_BG_DARK || '#F8FAFB';
export const COLOR_BG_NAV = process.env.REACT_APP_COLOR_BG_NAV || '#F8FAFB';

export const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME || 'Neo-Vinci ECRF System';
export const PROJECT_EMAIL = process.env.REACT_APP_PROJECT_EMAIL || 'example@neo-vinci.pl';
export const PROJECT_PHONE = process.env.REACT_APP_PROJECT_PHONE || '000-000-000';
export const PROJECT_PRIMARY_EMAIL = process.env.REACT_APP_PROJECT_PRIMARY_EMAIL || PROJECT_EMAIL;
export const PROJECT_PRIMARY_PHONE = process.env.REACT_APP_PROJECT_PRIMARY_PHONE || PROJECT_PHONE;
export const PROJECT_TECHNICAL_EMAIL = process.env.REACT_APP_PROJECT_TECHNICAL_EMAIL || PROJECT_EMAIL;
export const PROJECT_TECHNICAL_PHONE = process.env.REACT_APP_PROJECT_TECHNICAL_PHONE || PROJECT_PHONE;
export const PROJECT_LANG = process.env.REACT_APP_PROJECT_LANG || 'en';

export const RESEARCH = process.env.REACT_APP_RESEARCH || 'Neo-Vinci ECRF System';
export const DISPLAY_PROVIDE = process.env.REACT_APP_DISPLAY_PROVIDE || false;
// export const DRUG_PROVIDE_DISPLAY = process.env.REACT_APP_DISPLAY_PROVIDE || false;
export const DISPLAY_MULTISAVE = process.env.REACT_APP_DISPLAY_MULTISAVE || true;

export const DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT || 'dd.MM.yyyy';
export const DATETIME_FORMAT = process.env.REACT_APP_DATETIME_FORMAT || 'dd.MM.yyyy, HH:mm';
export const TIME_FORMAT = process.env.REACT_APP_TIME_FORMAT || 'HH:mm';
export const TZ = process.env.TZ || 'Europe/Warsaw';
