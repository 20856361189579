import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import { MedicalCenter } from "types/api.types";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";
import { omit } from "lodash";
import axiosPublicInstance from "commons/axios/axiosPublicInstance";

export interface MedicalCentersState {
  medicalCenters: {
    itemsPerPage: number;
    totalItems: number;
    data: MedicalCenter[];
  };
  medicalCenterDetails: MedicalCenter | null;
}

const initialState: MedicalCentersState = {
  medicalCenters: {
    itemsPerPage: 0,
    totalItems: 0,
    data: [],
  },
  medicalCenterDetails: null,
};

interface MedicalCenterValues {
  name: string;
  address: {
    street: string;
    number: string;
    city: string;
    postcode: string;
  };
  longitude: string;
  latitude: string;
  number: string;
}

type fetchMedicalCentersParams = {
  page?: number;
};

export const fetchPublicMedicalCenters = createAsyncThunk(
  "medical_centers/fetchMedicalCenters",
  async ({ page }: fetchMedicalCentersParams) => {
    const response = await axiosPublicInstance.get<
      HalResource<MedicalCenter[]>
    >("/api/medical_centers", {
      headers: {
        accept: "application/hal+json",
      },
      params: {
        page,
      },
    });
    return response.data;
  }
);

export const fetchMedicalCenters = createAsyncThunk(
  "medical_centers/fetchMedicalCenters",
  async ({ page }: fetchMedicalCentersParams) => {
    const response = await axiosSecureInstance.get<
      HalResource<MedicalCenter[]>
    >("/api/medical_centers", {
      headers: {
        accept: "application/hal+json",
      },
      params: {
        page,
      },
    });
    return response.data;
  }
);

export const fetchMedicalCenter = createAsyncThunk(
  "medical_centers/fetchMedicalCenter",
  async (id: string) => {
    const response = await axiosSecureInstance.get<MedicalCenter>(
      `/api/medical_centers/${id}`
    );
    return response.data;
  }
);

export const addMedicalCenter = createAsyncThunk(
  "medical_centers/addMedicalCenter",
  async (values: MedicalCenterValues, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.post(`/api/medical_centers`, {
        ...values,
        address: {
          ...omit(values.address, "country"),
          city: `/api/cities/${values.address?.city}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

type editMedicalCenterParams = {
  id: string | undefined;
  values: MedicalCenterValues;
};

export const editMedicalCenter = createAsyncThunk(
  "medical_centers/editMedicalCenter",
  async ({ id, values }: editMedicalCenterParams, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.put(
        `/api/medical_centers/${id}`,
        {
          ...values,
          address: {
            ...omit(values.address, "country"),
            city: `/api/cities/${values.address?.city}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteMedicalCenter = createAsyncThunk(
  "medical_centers/deleteMedicalCenter",
  async (id: string) => {
    await axiosSecureInstance.delete(`/api/medical_centers/${id}`);
  }
);

export const medicalCentersSlice = createSlice({
  name: "medicalCenters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMedicalCenters.fulfilled, (state, action) => {
      state.medicalCenters.data = action.payload._embedded?.item || [];
      state.medicalCenters.itemsPerPage = action.payload.itemsPerPage;
      state.medicalCenters.totalItems = action.payload.totalItems;
    });
    builder.addCase(fetchMedicalCenter.fulfilled, (state, action) => {
      state.medicalCenterDetails = action.payload;
    });
  },
});

export const selectMedicalCenters = (state: RootState) =>
  state.medicalCenters.medicalCenters.medicalCenters;

export const selectMedicalCenterDetails = (state: RootState) =>
  state.medicalCenters.medicalCenters.medicalCenterDetails;

export default medicalCentersSlice.reducer;
