import React from 'react';
import { StyledDrugsDate } from '../views/DrugsManagement.styled';
import { Data } from './RowItem';

interface Props {
    readonly?: boolean;
    id?: number;
    setRowData: (rowData: Data) => void;
    rowData: any;
    fieldType?: string;
    actualValue?: string;
    isMulti?: boolean;
}

export const PickDateTime: React.FC<Props> = ({readonly, setRowData, id, rowData, fieldType, actualValue}) => {
    /** Edit row edit-data */
    const handleChangeDate = async (e: any) => {
        let newData: any = {};

        if (fieldType === 'deliveryAt') {
            newData = Object.assign({}, rowData, {deliveryAt: e.target.value});
        } else if (fieldType === 'deliveredAt') {
            newData = Object.assign({}, rowData, {deliveredAt: e.target.value});
        } else if (fieldType === 'expiredAt') {
            newData = Object.assign({}, rowData, {expiredAt: e.target.value});
        }

        setRowData(newData);
    };

    const initialData = () => {
        if (actualValue) {
            return actualValue.slice(0, 16);
        } else return;
    };

    return (
        <StyledDrugsDate
            readonly={readonly}
            id={id}
            disabled={readonly}
            type="datetime-local"
            value={initialData()}
            onChange={(e: any) => handleChangeDate(e)}
        />
    );
};
