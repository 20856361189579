/*
 * Project: refsal-ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 03.03.2022
 * Time: 12:52
 */

import styled from 'styled-components';
import {
    COLOR_BG,
    COLOR_BG_NAV,
    COLOR_SECONDARY
} from "../../../../commons/constants/env";

export const StyledListTitle = styled.div`
    font-weight: bold;
    font-size: 30px;
    color: ${COLOR_SECONDARY};
    margin-left: 50px;
`;

export const StyledListTable = styled.div`
    width: 100%;
    color: ${COLOR_SECONDARY};
    margin-top: 30px;
`;

export const StyledListTableHead = styled.div<any>`
    color: ${COLOR_SECONDARY};
    display: grid;
    align-items: center;
    grid-template-columns: 60px 1fr 1fr 60px;
    background-color: ${COLOR_BG};
    border-radius: 3px;
    padding: 15px 30px;
    font-size: 14px;
    margin: 10px;
    transition: .2s ease;
    cursor: ${(props) => (props.hasChild ? 'pointer' : '')};
    
    &:hover {
        background-color: ${COLOR_BG_NAV};
    }
`;

export const StyledListTableHeadTitle = styled.div<any>`
    color: ${COLOR_SECONDARY};
    display: grid;
    align-items: center;
    grid-template-columns: 60px 1fr 1fr 30px;
    border-radius: 3px;
    padding: 5px 30px;
    font-size: 14px;
`;
