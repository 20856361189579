import { useAppSelector } from 'commons/store/hooks';
import React, {useEffect, useState} from 'react';
import { StyledEcrfVisits, StyledEcrfVisitsItem } from '../Ecrf.styled';
import { ROUTES_ECRF } from "../../../../../commons/constants/paths";
import {useNavigate, useParams} from "react-router-dom";
import { selectVisits } from "../../../redux/ecrfSlice";

interface Props {
    selectedVisit: any;
    setSelectedVisit: (selectedVisit: any) => void;
    isDataLoading: boolean;
}

export const VisitsPanel: React.FC<Props> = ({
    selectedVisit,
    setSelectedVisit,
    isDataLoading
}) => {
    const navigate = useNavigate();
    const visits = useAppSelector(selectVisits);
    const {id} = useParams<{ id: string }>();

    const handleClick = async (visit: any) => {
        if (isDataLoading) return;

        setSelectedVisit(visit);
        (id && visit?.sections[0]?.id) && navigate(ROUTES_ECRF.DETAILS_EXTENDED(id, visit?.sections[0]?.id));
    };

    return (
        <StyledEcrfVisits>
            {visits.map((visit: any) => (
                <StyledEcrfVisitsItem
                    key={visit?.id}
                    onClick={() => {handleClick(visit)}}
                    selected={selectedVisit?.id === visit?.id}
                >
                    {visit?.name}
                </StyledEcrfVisitsItem>
            ))}
        </StyledEcrfVisits>
    );
};
