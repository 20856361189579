/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 07.03.2022
 * Time: 11:38
*/

import React, {Component} from 'react';
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import {
    StyledUploaderDesc,
    StyledUploaderField,
    StyledUploaderFieldDesc,
    StyledUploaderFieldSelected,
    StyledUploaderFieldTitle,
    StyledUploaderFilesSize,
    StyledUploaderFilesWrapper,
    StyledUploaderModalWrapper,
    StyledUploaderTitle
} from "./S3UploaderFormik.styled";
import { Box, CircularProgress } from "@mui/material";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FileItem from "./FileItem";
import axiosSecureInstance from "../../../commons/axios/axiosSecureInstance";
import PublishIcon from '@mui/icons-material/Publish';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import GetAppIcon from '@mui/icons-material/GetApp';
import {API_URL} from "../../../commons/constants/env";
import { enqueueSnackbar, closeSnackbar } from 'notistack'
import {useTranslation} from "react-i18next";
import FileHelper from "../File/FileHelper";

interface S3UploaderFormikInterface {
    bucket?: any[],
    status?: boolean;
    data?: any;
    element?: any;
    ecrfId?: string,
    files: any[],
    filesSize: number,
    update: () => void;
    enqueueSnackbar?: any;
    isDataFetching?: boolean;
    isModalOpen?: boolean;
    isUploading?: boolean;
    isUploaded?: boolean;
}

const withRouter = (WrappedComponent: any) => (props: any) => {
    const { t } = useTranslation();

    return (
        <WrappedComponent
            {...props}
            t={t}
        />
    );
};

class S3UploaderFormik extends Component<any, any> {
    /**
     * uploadLimit is holding actual file size limit
     * @private
     */
    private readonly uploadLimit = 2147483648

    constructor(props: any) {
        super(props);

        this.state = {
            isModalOpen: false,
            files: [],
            isUploading: false,
            isUploaded: false,
            bucket: [],
            isDataFetching: false,
            filesSize: 0,
            status: false,
            readonly: false
        }
    }

    /**
     * This method is used to open modal
     * @private
     */
    private openModal() {
        this.setState({isModalOpen: true, isUploaded: false});
    }

    /**
     * This method is used to close modal
     * @private
     */
    private closeModal() {
        this.setState({isModalOpen: false, files: [], filesSize: 0});
    }

    /**
     * This method is used to handle select files from input and save it to state
     * @param e
     * @private
     */
    private handleSelectFiles(e: any) {
        e.preventDefault();
        e.stopPropagation();

        let files: any[] = [...this.state.files]
        let size: number = this.state.filesSize

        for (const file of e.target.files) {
            files.push(file)
            size = size + file.size
        }

        this.setState({files: files, isUploaded: false, filesSize: size});
    }

    /**
     * This method is used to remove item from files list
     * @param name
     * @private
     */
    private removeFromList(name: string) {
        let files: any[] = []
        let size = 0

        for (const file of this.state.files as any) {
            if (file.name !== name) {
                files.push(file)
                size = size + file.size
            }
        }

        this.setState({files: files, filesSize: size});
    }

    /**
     * This method is used to upload files to S3 storage
     * @private
     */
    private removeFromBucket(name: string) {
        const files = this.state.bucket.filter((file: any) => file.name !== name)
        this.setState({files: files});
    }

    /**
     * This method is used to upload files to S3 storage
     * @private
     */
    private async uploadFiles() {
        if (this.state.filesSize > this.uploadLimit) return

        try {
            let formData = new FormData()

            for (let i = 0; i < this.state.files.length; i++) {
                formData.append(`file${i}`, this.state.files[i])
            }

            this.setState({isUploading: true});
            await axiosSecureInstance.post(`/api/element_datas/${this.props.ecrfId}/${this.props.element.id}/upload`, formData)

            await this.props.update()
            this.getFilesData(this.props.data.id).then()

            this.setState({isUploading: false, isUploaded: true, files: []});
        } catch (error: any) {
            this.setState({isUploading: false});
            enqueueSnackbar(error?.response?.data?.detail || error?.message, {
                variant: 'warning',
            })
        }
    }

    /**
     * This method is used to get data of uploader element
     * @param id
     * @private
     */
    private async getFilesData(id: string) {
        this.setState({isDataFetching: true});

        try {
            // const response = await axiosSecureInstance.get(`/api/element_resource_transfers?element=${id}`)
            const response = await axiosSecureInstance.get(`/api/element_resource_transfers?element.element=${this.props.element.id}&element.ecrf=${this.props.ecrfId}`)
            this.setState({bucket: response.data});
            // this.setState({files: response.data});
        } catch (error: any) {
            alert(error?.response?.data?.detail)
        }

        this.setState({isDataFetching: false});
    }

    /**
     * This method is used to download all files
     * @private
     */
    private downloadAllFiles() {
        window.open(`${API_URL}/api/element_datas/${this.props.data.id}/download`)
    }

    private displayFileFormat() {
        return this.props?.t('file-format') + ': AVI, DICOM, JPG, PDF, ZIP'
    }

    componentDidMount() {
        if (this.props.data) {
        // if (this.props.data && (this.props.data?.transfersCount > 0)) {
            this.getFilesData(this.props.data.id).then()
        }
    }

    render() {
        return (
            <>
                <Button
                    onClick={this.openModal.bind(this)}
                    variant="outlined"
                    fullWidth
                    disabled={this.state.isDataFetching || (this.props.readonly && this.state.bucket?.length === 0)}
                >
                    {
                        (this.state.bucket?.length > 0)
                        ? this.props?.t('view-files')
                        : this.state.isDataFetching ? <CircularProgress size={22} /> : this.props?.t('upload-files')
                    }
                </Button>
                <Modal style={{overflowY: 'auto', width: "100%", display: 'grid', justifyItems: 'center'}}
                    open={this.state.isModalOpen}>
                    <Box m={3} maxWidth={'600px'} p={3}>
                        <StyledUploaderModalWrapper>
                            <StyledUploaderTitle>
                                {this.props?.t('upload-files')}
                            </StyledUploaderTitle>
                            <StyledUploaderDesc>
                                {this.props?.t('modal-file-upload-desc')}
                            </StyledUploaderDesc>
                            <StyledUploaderDesc>
                                1. {this.props?.t('modal-file-upload-desc-1')}
                            </StyledUploaderDesc>
                            <StyledUploaderDesc>
                                2. {this.props?.t('modal-file-upload-desc-2')}
                            </StyledUploaderDesc>
                            <StyledUploaderDesc>
                                3. {this.props?.t('modal-file-upload-desc-3')}
                            </StyledUploaderDesc>
                            <StyledUploaderDesc>
                                4. {this.props?.t('modal-file-upload-desc-4')}
                            </StyledUploaderDesc>

                            {/* <--- Initial upload view ---> */}
                            {!this.props.data && this.state.files?.length === 0 && (
                                <>
                                    <Button
                                        component={'label'}
                                        style={{all: 'unset', width: '100%'}}
                                        disabled={this.props.status}
                                    >
                                        <StyledUploaderField
                                            onChange={this.handleSelectFiles.bind(this)}
                                            component={'label'}>
                                            <InsertDriveFileOutlinedIcon style={{fontSize: '60px', color: '#929DA7'}} />
                                            <StyledUploaderFieldTitle>
                                                {this.props?.t('select-files-here')}
                                            </StyledUploaderFieldTitle>
                                            <StyledUploaderFieldDesc>
                                                {this.displayFileFormat()}
                                            </StyledUploaderFieldDesc>
                                            <StyledUploaderFieldDesc>
                                                {this.props?.t('max-files-size')}: 2GB
                                            </StyledUploaderFieldDesc>
                                            <Button
                                                variant={'outlined'}
                                                component={'label'}
                                                style={{marginTop: '15px'}}
                                                disabled={this.props.status}
                                            >
                                                {this.props?.t('select-files')}
                                                <input multiple type={'file'} hidden />
                                            </Button>
                                        </StyledUploaderField>
                                    </Button>
                                    <Button style={{marginTop: '25px'}} onClick={() => this.closeModal()} size={'small'}>
                                        {this.props?.t('cancel')}
                                    </Button>
                                </>
                            )}

                            {/* <--- Loaded files view ---> */}
                            {!this.state.isUploaded && (this.state.files?.length > 0) && (
                                <StyledUploaderFieldSelected>
                                    <StyledUploaderFieldTitle>
                                        {this.state.files.length > 0
                                            ? this.props?.t('selected-files')
                                            : this.props?.t('select-files')
                                        }
                                    </StyledUploaderFieldTitle>
                                    <StyledUploaderFilesWrapper>
                                        {[].slice.call(this.state.files).map((file: any) =>
                                            <FileItem key={file.id} isUploading={this.state.isUploading}
                                                name={file.name} size={file.size} id={file.id}
                                                removeRow={this.removeFromList.bind(this)} />
                                        )}

                                        <StyledUploaderFilesSize limit={this.state.filesSize > this.uploadLimit}>
                                            {this.props?.t('files-size-limit')}: {FileHelper.formatBytes(this.state.filesSize)}{" "}
                                            / {FileHelper.formatBytes(this.uploadLimit)}
                                        </StyledUploaderFilesSize>
                                    </StyledUploaderFilesWrapper>

                                    <Box mt={1} display='flex' gap={'20px'}>
                                        <Button onClick={() => this.closeModal()} size={'small'}>
                                            {this.props?.t('cancel')}
                                        </Button>
                                        <Button
                                            component={'label'}
                                            onChange={this.handleSelectFiles.bind(this)}
                                            startIcon={<AddRoundedIcon />} size={'small'}
                                            disabled={this.props.status || this.state.isUploading}
                                        >
                                            {this.props?.t('add-more')}
                                            <input multiple accept="image/*,video/*" type={'file'} hidden />
                                        </Button>
                                        <Button
                                            disabled={this.state.isUploading
                                                || this.state.filesSize > this.uploadLimit || this.props.status}
                                            component={'label'}
                                            onClick={this.uploadFiles.bind(this)} size={'small'}
                                            startIcon={this.state.isUploading ? <CircularProgress size={14} /> :
                                                <PublishIcon />} color={'primary'}
                                        >
                                            {this.state.isUploading ? this.props?.t('uploading') : this.props?.t('upload-files')}
                                        </Button>
                                    </Box>
                                </StyledUploaderFieldSelected>
                            )}

                            {/* <--- View for positive upload status ---> */}
                            {this.state.isUploaded && (
                                <StyledUploaderFieldSelected style={{marginBottom: '-50px'}}>
                                    <StyledUploaderTitle>
                                        {this.props?.t('files-uploaded-successfully')}!
                                    </StyledUploaderTitle>
                                </StyledUploaderFieldSelected>
                            )}

                            {/* <--- View for preview and download files */}
                            {this.props.data && (
                                <>
                                    {(this.state.files?.length === 0) && (
                                        <StyledUploaderFieldSelected>
                                            <StyledUploaderFieldTitle style={{marginBottom: '10px'}}>
                                                {this.props?.t('uploaded-files')}
                                            </StyledUploaderFieldTitle>
                                            <StyledUploaderFilesWrapper>
                                                {[].slice.call(this.state.bucket).map((file: any) =>
                                                    <FileItem name={file.filename} size={file.size} link={file.link}
                                                        key={file.id} id={file.id} dataId={this.props.data.id}
                                                        update={this.getFilesData.bind(this)} />
                                                )}

                                                {/* <--- Spinner when data is fetching --- >*/}
                                                {this.state.isDataFetching && (
                                                    <Box m={3} display={'grid'} justifyContent={'center'}>
                                                        <CircularProgress />
                                                    </Box>
                                                )}
                                            </StyledUploaderFilesWrapper>

                                            <Box mt={1} display='flex' gap={'20px'}>
                                                <Button onClick={() => this.closeModal()} size={'small'}>
                                                    {this.props?.t('close')}
                                                </Button>
                                                <Button
                                                    component={'label'}
                                                    onChange={this.handleSelectFiles.bind(this)}
                                                    startIcon={<AddRoundedIcon />}
                                                    size={'small'}
                                                    disabled={this.props.status || this.props.readonly}
                                                >
                                                    {this.state.bucket?.length > 0
                                                        ? this.props?.t('add-more-files')
                                                        : this.props?.t('add-file')
                                                    }
                                                    <input multiple accept="image/*,video/*" type={'file'} hidden />
                                                </Button>
                                                <Button onClick={this.downloadAllFiles.bind(this)} size={'small'}
                                                    startIcon={<GetAppIcon />} color={'primary'}>
                                                    {this.props?.t('download')}
                                                </Button>
                                            </Box>
                                        </StyledUploaderFieldSelected>
                                    )}
                                </>
                            )}
                        </StyledUploaderModalWrapper>
                    </Box>
                </Modal>
            </>
        );
    }
}

export default withRouter(S3UploaderFormik);