import React, { useState } from 'react';
import { Box, Button, CircularProgress, Tooltip } from '@mui/material';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import TableGridFormField from './TableGridFormField';
import { StyledTableRow, StyledTableRowItem } from './TableGridFormik.styled';
import axiosSecureInstance from '../../../commons/axios/axiosSecureInstance';
import ConfirmationAction from 'components/ConfirmationAction/ConfirmationAction';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import { History, Save} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { flatten } from "lodash";
import { roles } from "../../../commons/auth/roles";
import { useAppSelector } from "../../../commons/store/hooks";
import { selectUserProfile } from "../../../core/redux/userSlice";
import getInitialValues from 'features/ecrf/utils/getInitialValues';
import {useTranslation} from "react-i18next";

const TableGridRow: React.FC<any> = (props: any) => {
    const { enqueueSnackbar } = useSnackbar();
    const userProfile = useAppSelector(selectUserProfile);
    const [isSending, setSending] = useState(false);
    const [isRemoving, setRemoving] = useState(false);
    const { t } = useTranslation();

    // /**
    //  * This method is used to get initial values of collection
    //  * @param elements
    //  */
    // const getInitialValues = (elements: any) => {
    //     let initialValues: any = {};
    //
    //     for (let item = 0; item < elements.length; item++) {
    //         if (elements[item]?.element?.type === 'checkbox_multiple') {
    //             initialValues[elements[item].element.id] = elements[item].data || [];
    //         } else {
    //             initialValues[elements[item].element.id] = elements[item].data[0] || '';
    //         }
    //     }
    //
    //     return initialValues;
    // };

    // const formInitialValues = getInitialValues(props.row?.elements);
    const initialValues = getInitialValues(props.columns, props.row?.elements || [], true);

    /**
     * This function is used to save actual row
     * @param values
     * @param actions
     */
    const handleSaveRow = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        setSending(true);

        try {
            const data = props.row?.elements?.map((el: any) => {
                return {
                    id: `/api/element_datas/${el.id}`,
                    data: flatten([values[el.element.id]])
                }
            })

            await axiosSecureInstance.put<any>(`/api/element_data_collections/${props.row?.id}`, {
                elements: [...data],
            });

            await reloadData();

            enqueueSnackbar(`${t('saved')} ${new Date().toLocaleString('pl-PL')}`, {variant: 'info'});
        } catch (err: any) {
            enqueueSnackbar(err?.detail, {variant: 'error'});
        }

        setSending(false);
    };

    /**
     * This function is used to remove actual row after confirmation
     * @param id
     */
    const removeRow = async (id: string) => {
        setRemoving(true);

        await axiosSecureInstance.delete<any>(`/api/element_data_collections/${id}`);
        await reloadData();

        setRemoving(false);
    };

    /**
     * Reload data element
     */
    const reloadData = async () => {
        await props.fetchData(props.dataId);
        await props.changeReloadingValue();
    };

    return (
        <Box>
            <Formik initialValues={initialValues} onSubmit={handleSaveRow}>
                {(form) => <Form onSubmit={form.handleSubmit}>
                    <StyledTableRow size={props.columns.length}>
                        {props.columns
                            .map((element: any, index: any) => {
                                const data = props.row?.elements.find(
                                    (d: any, i: any) =>
                                        (element?.id === d.element?.id && index === d.element?.position) ||
                                        index === d.element?.position
                                        || index === i
                                        // element?.position === d.element?.position
                                );

                                return (<StyledTableRowItem key={element.id}>
                                    <TableGridFormField
                                        style={{width: '100%'}}
                                        rowId={props.row?.id}
                                        data={data?.data}
                                        options={data?.element?.options || element?.options}
                                        type={data?.element?.type || element?.type}
                                        name={data?.element?.id || element?.id || ''}
                                        label={data?.element?.label || element?.label}
                                        dataId={data?.id}
                                        status={props.status || data?.mode === 'block'}
                                        disabled={data?.mode === 'block' || isSending}
                                        reloadData={reloadData.bind(this)}
                                    />
                                </StyledTableRowItem>)
                            })}

                        <Box ml={3} display={'flex'} alignItems={'center'}>
                            {(props.options?.saveRow) && (
                                <Tooltip title={t('save-record')} arrow>
                                    <span>
                                        <Button
                                            variant={'outlined'}
                                            color={'primary'}
                                            disabled={isSending || props.status}
                                            onClick={form.submitForm}
                                        >
                                            {isSending ? <CircularProgress size={24} /> : <Save/>}
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                            {userProfile.roles.some(
                                (role: string) => roles.admin.includes(role) || roles.monitor.includes(role)
                            ) && (
                                <Tooltip title={t('history-record')} arrow>
                                    <span>
                                        <Button
                                            variant={'outlined'}
                                            color={'primary'}
                                            disabled={props.status}
                                            onClick={() => props.handleHistory(props.row.id)}
                                        >
                                            <History />
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                            {props.options?.removeRow && (
                                <ConfirmationAction
                                    confirmationTitle={t('are-you-sure-you-want-to-delete-this-record')}
                                    onClick={() => removeRow(props.row.id)}
                                    isProcessing={isRemoving}
                                >
                                    {({ handleClick }) => (
                                        <Tooltip title={t('remove-record')} arrow>
                                            <span>
                                                <Button
                                                    onClick={handleClick}
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                    disabled={isRemoving || props.status}>
                                                    <DeleteSweepRoundedIcon />
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    )}
                                </ConfirmationAction>
                            )}
                        </Box>
                    </StyledTableRow>
                </Form>
                }
            </Formik>
        </Box>
    );
};

export default TableGridRow;
