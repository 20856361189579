/**
 * Dictionary statuses
 * list en: default - assigned - sent - delivered - damaged - dispensed
 * list pl: wolny - przypisany - wysłany - otrzymany - uszkodzony - wydany
 */

export const statusDictionary: string[] = [
    'dm-status-default',
    'dm-status-assigned',
    'dm-status-sent',
    'dm-status-delivered',
    'dm-status-damaged',
    'dm-status-dispensed'
]

export const statusDescriptions: string[] = [
    'dm-status-default-desc',
    'dm-status-assigned-desc',
    'dm-status-sent-desc',
    'dm-status-delivered-desc',
    'dm-status-damaged-desc',
    'dm-status-dispensed-desc'
]