import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "commons/store/store";
import {
    EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead,
    ElementDataEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead,
    ElementDataElementDataElementDataReadIdReadStatusReadCommentRead,
    ElementDependency,
    SectionSectionSectionReadSectionElementsIdReadNameReadPositionReadStatusReadElement,
    VisitVisitVisitReadIdReadNameReadPositionRead,
} from "types/api.types";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "../../../types/halResource.types";

export interface EcrfState {
    // TODO swagger model for ecrf
    ecrf: any;
    visits: VisitVisitVisitReadIdReadNameReadPositionRead[];
    elements: ElementDataEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead[];
    currentSection: any | null;
    queries: any;
    selectedSection: any;
    dependencies: any;
    collectionDependencies: any;
    adverseEvents: any;
    selectedCollection: fetchCollectionParams | null;
    selectedElementDatas: any;
    addedElementData: any;
    editedElementData: any;
    elementDataCollection: any;
    elementDataCollections: any;
    collectionElementDatas: any;
    selectedElement: any;
    adverseEventsFields: any;
    randomize: any;
}

const initialState: EcrfState = {
    ecrf: null,
    visits: [],
    elements: [],
    currentSection: null,
    queries: {},
    selectedSection: {},
    dependencies: [],
    collectionDependencies: [],
    adverseEvents: {},
    selectedCollection: null,
    selectedElementDatas: {},
    addedElementData: {},
    editedElementData: {},
    elementDataCollection: {},
    elementDataCollections: {},
    collectionElementDatas: {},
    selectedElement: {},
    adverseEventsFields: [],
    randomize: null,
};

export type EcrfFormParams = {
    id: string;
    section: string;
};

export const fetchEcrf = createAsyncThunk('ecrf/fetchEcrf', async (id: string) => {
    const response =
        await axiosSecureInstance.get<EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead>(
            `/api/ecrves/${id}`
        );

    // test mercure
    // const url = new URL('https://127.0.0.1:8000/.well-known/mercure');
    // url.searchParams.append('topic', 'https://127.0.0.1:8000/api/ecrves');
    //
    // const eventSource = new EventSource(url);
    //
    // // The callback will be called every time an update is published
    // eventSource.onmessage = function ({data}) {
    //     console.log(data);
    // };

    return response.data;
});

export const fetchVisits = createAsyncThunk(
    "ecrf/fetchVisits",
    async (researchId?: string | null | undefined) => {
        const response = await axiosSecureInstance.get<VisitVisitVisitReadIdReadNameReadPositionRead[]>(
            `/api/visits`,
            {
                params: {
                    research: researchId,
                },
            }
        );
        return response.data;
    }
);

export const fetchDrugVisits = createAsyncThunk(
    'ecrf/fetchDrugVisits',
    async (researchId?: string | null | undefined) => {
        const response = await axiosSecureInstance.get<VisitVisitVisitReadIdReadNameReadPositionRead[]>(
            `/api/visits/drug`,
            {
                params: {
                    research: researchId,
                },
            }
        );
        return response.data;
    }
);

/* Endpoint for ecrf elements */
export const fetchEcrfElements = createAsyncThunk(
    "ecrf/fetchEcrfElements",
    async ({id, sectionId}: any, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<ElementDataEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead[]>(
                `/api/ecrves/${id}/elements`,
                {
                    params: {
                        'element.section': sectionId,
                    },
                }
            );
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

/* New endpoint for visits = replaced in store also */
export const fetchVisitsWithCounters = createAsyncThunk(
    "ecrf/fetchVisitsWithCounters",
    async (id: string | undefined, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<any>(`/api/visits/${id}/ecrf`)
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const fetchSection = createAsyncThunk(
    "ecrf/fetchSection",
    async (id: string, thunkAPI) => {
    try {
        const response =
            await axiosSecureInstance.get<SectionSectionSectionReadSectionElementsIdReadNameReadPositionReadStatusReadElement>(
                `/api/sections/${id}`
            );
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const fetchSectionByEcrf = createAsyncThunk(
    "ecrf/fetchSection",
    async ({id, ecrfId}: {id: string, ecrfId: string | undefined}, thunkAPI) => {
    try {
        const response =
            await axiosSecureInstance.get<SectionSectionSectionReadSectionElementsIdReadNameReadPositionReadStatusReadElement>(
                `/api/sections/${id}`,
                {params: {ecrf: ecrfId}}
            );
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

type PostElementDataParams = {
    element: string;
    ecrf: string;
    data?: Array<any>;
    comment?: any;
    collection?: Array<any>;
    entries?: Array<any>;
    status?: number;
};

export const postElementData = createAsyncThunk(
    'ecrf/postElementData',
    async (params: PostElementDataParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.post(`/api/element_datas`, params);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// TODO swagger model for element values
type PutElementDataParams = {
    elementId: string;
    ecrfId: string;
    data: Array<any>;
    status?: number;
    comment?: any;
};

export const putElementData = createAsyncThunk(
    'ecrf/putElementData',
    async ({elementId, ecrfId, data, comment}: PutElementDataParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.put(`/api/element_datas/element/${elementId}`, {
                data,
                ecrf: `/api/ecrves/${ecrfId}`,
                comment: comment
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const putDataToElement = createAsyncThunk(
    "ecrf/putDataToElement",
    async ({elementDataId, data}: any, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.put(`/api/element_datas/${elementDataId}`, {
                data,
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type UpdateElementDataParams = {
    id?: string;
    element?: string;
    ecrf?: string;
    data?: Array<any>;
    comment?: any;
    collection?: Array<any>;
    entries?: Array<any>;
    status?: number;
};

export const updateElementData = createAsyncThunk(
    'ecrf/updateElementData',
    async (params: UpdateElementDataParams, thunkAPI) => {
        try {
            const id = params?.id
            delete params?.id

            const response = await axiosSecureInstance.put(`/api/element_datas/${id}`, params);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type ProvideDrugParams = {
    id: string;
    drugs: Array<any>;
};

export const provideDrug = createAsyncThunk(
    'ecrf/provideDrug',
    async ({id, drugs}: ProvideDrugParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.put(`/api/ecrves/${id}/drug/provide`, {
                drugs,
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type updateElementStatusParams = {
    elementId: string;
    status: number;
    ecrfId: string;
};

export const updateElementStatus = createAsyncThunk(
    "ecrf/putElementData",
    async ({elementId, ecrfId, status}: updateElementStatusParams) => {
        const response = await axiosSecureInstance.put(`/api/element_datas/element/${elementId}`, {
            status,
            ecrf: `/api/ecrves/${ecrfId}`,
        });
        return response.data;
    }
);

export const fetchQueries = createAsyncThunk(
    'ecrf/fetchQueries',
    async (id: string | null) => {
        const response = await axiosSecureInstance.get<any[]>(`/api/comments/${id}`);
        return response.data;
    }
);

export const fetchDependencies = createAsyncThunk(
    'ecrf/fetchDependencies',
    async (sectionId: string | undefined, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<ElementDependency[]>(
                `/api/element_dependencies`,
                {
                    params: {
                        'parents.section': sectionId,
                    },
                }
            );
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const fetchCollectionDependencies = createAsyncThunk(
    'ecrf/fetchCollectionDependencies',
    async (elementId: string | undefined, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<ElementDependency[]>(
                `/api/element_dependencies`,
                {
                    params: {
                        'parents.childWithElement': elementId,
                    },
                }
            );
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const fetchDependency = createAsyncThunk("ecrf/fetchDependency", async (id: string) => {
    const response = await axiosSecureInstance.get<any>(`/api/element_dependencies/${id}`);
    return response.data;
});

type addQueryParams = {
    element?: string;
    parent?: string;
    content: string;
};

export const addQuery = createAsyncThunk(
    "ecrf/addQuery",
    async ({content, parent, element}: addQueryParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.post(`/api/comments`, {
                element,
                parent,
                content,
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type AdverseEventsParams = {
    // page: number;
    // randomId?: string | null | undefined;
};

export const fetchAdverseEvents = createAsyncThunk(
    'ecrf/fetchAdverseEvents',
    async (config: AdverseEventsParams, thunkAPI) => {
        try {
            // const response = await axiosSecureInstance.get(`/api/element_data_collections/adverse_events`, {
            //     headers: {
            //         accept: 'application/hal+json',
            //     },
            //     params: {
            //         pagination: true,
            //         page,
            //         itemsPerPage: 30,
            //         'datas.ecrf.randomId': randomId,
            //     },
            // });
            const response = await axiosSecureInstance
                .get(`/api/element_data_collections/adverse_events`, config);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
});

export const fetchAdverseEventsFields = createAsyncThunk(
    'ecrf/fetchAdverseEventsFields', async () => {
        const response = await axiosSecureInstance.get('/api/elements/ae/fields');
        return response.data;
    }
);

export type ButtonOptions = {
    buttonText: string;
    confirmText?: string;
    confirmDisabledText?: string;
    alertText?: string;
    elementData: any;
    buttonDisabledText?: string;
    description?: string;
    type?: string | undefined;
    disabledCondition?: string;
    modal?: boolean;
    autosave?: boolean;
};

export type fetchCollectionParams = {
    id?: string | null
    children?: any[]
    collectionId?: string
    elements?: any[]
    dataId?: string
    ecrfId: string
    elementId?: string
    elementName: string
    elementType?: string
    sectionId?: string
    status: any
    options?: ButtonOptions;
    type: 'add' | 'clone' | 'create' | 'edit' | 'duplicate' | 'save'
};

export const fetchCollection = createAsyncThunk('ecrf/fetchCollection', (row: fetchCollectionParams | null) => {
    return Object.assign({}, row);
});

export const fetchElementDatas = createAsyncThunk(
    'ecrf/fetchElementDatas',
    async (id: string, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get(`/api/element_datas/${id}`);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type CollectionElementDatasParams = {
    elementId: string | null | undefined;
    ecrfId?: string | null | undefined;
};

export const fetchCollectionElementDatas = createAsyncThunk(
    'ecrf/fetchCollectionElementDatas',
    async ({elementId, ecrfId}: CollectionElementDatasParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<any[]>(
                `/api/element_datas`,
                {
                    params: {
                        'element.childWithElement': elementId,
                        'ecrf': ecrfId,
                    },
                }
            );
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const fetchElementDatasChildren = createAsyncThunk(
    'ecrf/fetchElementDatasChildren',
    async (id: string, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<any[]>(
                `/api/element_datas/${id}/children`
            );
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const fetchElement = createAsyncThunk("ecrf/fetchElement", async (elementId: string) => {
    const response = await axiosSecureInstance.get(`/api/elements/${elementId}`);
    return response.data;
});

type editCollectElementDataParams = {
    dataId: string;
    collections: Array<any>;
};

export const editCollectElementData = createAsyncThunk(
    'ecrf/editCollectElementData',
    async ({dataId, collections}: editCollectElementDataParams) => {
        const response = await axiosSecureInstance.put(`/api/element_datas/${dataId}`, {
            data: [0],
            collection: [...collections],
        });
        return response.data;
    }
);

export const deleteElementDataCollection = createAsyncThunk(
    'ecrf/deleteElementDataCollection',
    async (id: string, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.delete(`/api/element_data_collections/${id}`);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type FetchElementDataCollectionsParams = {
    id: string;
    params?: any;
};

export const fetchElementDataCollections = createAsyncThunk(
    'ecrf/fetchElementDataCollections',
    async ({id, params = []}: FetchElementDataCollectionsParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get(
                `/api/element_datas/${id}/collections`,
                {
                    params: params,
                }
            );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchElementDataCollection = createAsyncThunk(
    'ecrf/fetchElementDataCollection',
    async (id: string, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get(`/api/element_data_collections/${id}`);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type PostElementDataCollectionsParams = {
    datas: Array<any>;
    elements?: Array<any>;
};

export const postElementDataCollections = createAsyncThunk(
    'ecrf/postElementDataCollections',
    async ({datas, elements = []}: PostElementDataCollectionsParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.post(`/api/element_data_collections`, {
                datas: [...datas],
                elements: [...elements],
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type PutCollectElementDataParams = {
    id: string | number;
    elements: Array<any>;
};

export const putCollectElementData = createAsyncThunk(
    'ecrf/putCollectElementData',
    async ({id, elements}: PutCollectElementDataParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.put(`/api/element_data_collections/${id}`, {
                elements: [...elements],
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type PostElementDataCollectionParams = {
    elementId: string;
    ecrfId: string;
    elements?: Array<any>;
};

export const postElementDataCollection = createAsyncThunk(
    'ecrf/postElementDataCollection',
    async ({elementId, ecrfId, elements}: PostElementDataCollectionParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.post(`/api/element_datas`, {
                data: [],
                ecrf: `/api/ecrves/${ecrfId}`,
                element: `/api/elements/${elementId}`,
                collection: [
                    {
                        elements,
                    },
                ],
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type putElementDataCollectionParams = {
    dataId: string;
    collections: Array<any>;
};

export const putElementDataCollection = createAsyncThunk(
    'ecrf/putElementDataCollection',
    async ({dataId, collections}: putElementDataCollectionParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.put(`/api/element_datas/${dataId}`, {
                collection: [...collections],
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

type ElementDataManagementParams = {
    // page: number;
    // randomId?: string | null | undefined;
    // visitId?: string | null | undefined;
};

export const fetchElementDataManagement = createAsyncThunk(
    'ecrf/fetchElementDataManagement',
    async (config: ElementDataManagementParams, thunkAPI) => {
        try {
            // const response = await axiosSecureInstance.get<HalResource<ElementDataElementDataElementDataReadIdReadStatusReadCommentRead[]>>
            // (`/api/element_datas/data_management`, {
            //     headers: {
            //         accept: 'application/hal+json',
            //     },
            //     params: {
            //         pagination: true,
            //         page,
            //         itemsPerPage: 100,
            //         'ecrf.randomId': randomId,
            //         'element.section.visit': visitId,
            //         // 'order[updatedAt]': 'desc',
            //         // 'order[ecrf.participantNumber]': 'asc',
            //         // 'order[element.position]': 'asc',
            //     },
            // });
            const response = await axiosSecureInstance.get<HalResource<ElementDataElementDataElementDataReadIdReadStatusReadCommentRead[]>>
            (`/api/element_datas/data_management`, config);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const randomizePatient = createAsyncThunk(
    'ecrf/randomize',
    async (ecrfId: string, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.put<any>(`/api/ecrves/${ecrfId}/randomize`, {});
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data);
        }
    }
);

export const ecrfSlice = createSlice({
    name: 'ecrf',
    initialState,
    reducers: {
        getSectionId(state, action: PayloadAction<any>) {
            state.selectedSection = action.payload;
        },
        resetEcrf(state) {
            state.ecrf = initialState.ecrf;
        },
        resetCurrentSection(state) {
            state.currentSection = initialState.currentSection;
        },
        resetElementDatas(state) {
            state.selectedElementDatas = initialState.selectedElementDatas;
        },
        resetEditElementData(state) {
            state.editedElementData = initialState.editedElementData;
        },
        resetElementDataCollection(state) {
            state.elementDataCollection = initialState.elementDataCollection;
        },
        resetElementDataCollections(state) {
            state.elementDataCollections = initialState.elementDataCollections;
        },
        resetCollection(state) {
            state.selectedCollection = initialState.selectedCollection;
        },
        resetDependencies(state) {
            state.dependencies = initialState.dependencies;
        },
        resetQueries(state) {
            state.queries = initialState.queries
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEcrf.fulfilled, (state: EcrfState, action) => {
            state.ecrf = action.payload;
        });
        builder.addCase(fetchVisitsWithCounters.fulfilled, (state, action) => {
            state.visits = action.payload;
        });
        builder.addCase(fetchEcrfElements.fulfilled, (state, action) => {
            state.elements = action.payload;
        });
        builder.addCase(fetchSection.fulfilled, (state, action) => {
            state.currentSection = action.payload;
        });
        builder.addCase(fetchQueries.fulfilled, (state, action) => {
            state.queries = action.payload;
        });
        builder.addCase(fetchDependencies.fulfilled, (state, action) => {
            state.dependencies = action.payload;
        });
        builder.addCase(fetchCollectionDependencies.fulfilled, (state, action) => {
            state.collectionDependencies = action.payload;
        });
        builder.addCase(fetchAdverseEvents.fulfilled, (state, action) => {
            state.adverseEvents = action.payload;
        });
        builder.addCase(fetchCollection.fulfilled, (state, action) => {
            state.selectedCollection = action.payload;
        });
        builder.addCase(fetchElementDatas.fulfilled, (state, action) => {
            state.selectedElementDatas = action.payload;
        });
        builder.addCase(postElementData.fulfilled, (state, action) => {
            state.editedElementData = action.payload;
        });
        builder.addCase(putElementData.fulfilled, (state, action) => {
            state.editedElementData = action.payload;
        });
        builder.addCase(fetchElementDataCollection.fulfilled, (state, action) => {
            state.elementDataCollection = action.payload;
        });
        builder.addCase(fetchElementDataCollections.fulfilled, (state, action) => {
            state.elementDataCollections = action.payload;
        });
        builder.addCase(fetchCollectionElementDatas.fulfilled, (state, action) => {
            state.collectionElementDatas = action.payload;
        });
        builder.addCase(fetchElement.fulfilled, (state, action) => {
            state.selectedElement = action.payload;
        });
        builder.addCase(fetchAdverseEventsFields.fulfilled, (state, action) => {
            state.adverseEventsFields = action.payload;
        });
        builder.addCase(randomizePatient.fulfilled, (state, action) => {
            state.randomize = action.payload;
        });
    },
});

export const selectEcrf = (state: RootState) => state.ecrf.ecrf.ecrf;

export const selectEcrfElements = (state: RootState) => state.ecrf.ecrf.elements;

export const selectVisits = (state: RootState) => state.ecrf.ecrf.visits;

export const selectDependencies = (state: RootState) => state.ecrf.ecrf.dependencies;

export const selectCurrentSection = (state: RootState) => state.ecrf.ecrf.currentSection;

export const selectQueries = (state: RootState) => state.ecrf.ecrf.queries;

export const selectSectionId = (state: RootState) => state.ecrf.ecrf.selectedSection;

export const selectAdverseEvents = (state: RootState) => state.ecrf.ecrf.adverseEvents;

export const selectCollection = (state: RootState) => state.ecrf.ecrf.selectedCollection;

export const selectElementDatas = (state: RootState) => state.ecrf.ecrf.selectedElementDatas;

export const addedElementData = (state: RootState) => state.ecrf.ecrf.addedElementData;

export const editedElementData = (state: RootState) => state.ecrf.ecrf.editedElementData;

export const selectElementDataCollection = (state: RootState) => state.ecrf.ecrf.elementDataCollection;

export const {
    getSectionId,
    resetEcrf,
    resetCurrentSection,
    resetElementDatas,
    resetEditElementData,
    resetElementDataCollection,
    resetElementDataCollections,
    resetCollection,
    resetDependencies,
    resetQueries
} = ecrfSlice.actions;

export default ecrfSlice.reducer;
