import Typography from "@mui/material/Typography";
import { ROUTES_DRUG } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import DrugForm from "features/drugs/components/DrugForm";
import {
  editResource,
  fetchResource,
  selectResourceDetails,
} from "features/drugs/redux/drugsSlice";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import yup from "commons/yup";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import Box from "@mui/material/Box";
import { InitialValuesState } from "features/drugs/components/DrugForm/DrugForm";

type ResourceEditParams = {
  id: string;
};

const validationSchema = yup.object({
  name: yup.string().required(),
});

const DrugEdit = () => {
  const { id } = useParams<ResourceEditParams>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const drugDetails = useAppSelector(selectResourceDetails);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    name: drugDetails?.name || "",
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchResource(id));
    }
  }, [dispatch, id]);

  const handleSubmit = async (values: InitialValuesState) => {
    try {
      await dispatch(editResource({ id, values }));
      enqueueSnackbar("Lek został zaktualizowany", {variant: 'success'});
      navigate(ROUTES_DRUG.LIST);
    } catch (error: any) {
      enqueueSnackbar(error?.detail, {variant: 'error'});
    }
  };

  return (
    <DashboardLayoutWrapper centerChildrenVertically>
      <ColoredBox p={1} width={352} maxWidth="100%" ml="auto" mr="auto">
        <Box mb={3.5}>
          <Typography variant="h5">Edytuj ośrodek badawczy</Typography>
        </Box>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <DrugForm />
        </Formik>
      </ColoredBox>
    </DashboardLayoutWrapper>
  );
};

export default DrugEdit;
