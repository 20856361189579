import styled, {css} from "styled-components";

export const StyledFooterWrapper = styled.footer`
  max-width: 1660px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
`;

export const StyledFooterContnetWrapper = styled.div`
  border-top: 1px solid #979797;
`;

interface StyledFooterImgProps {
    marginRight?: number;
}

export const StyledFooterImg = styled.img<StyledFooterImgProps>`
  height: 20px;
  object-fit: contain;
  ${({marginRight}) =>
    marginRight &&
    css`
      margin-right: ${marginRight}px;
    `}

  @media(min-width: ${({theme}) => theme.breakpoints.values.lg}px) {
    height: 65px;
  }
`;

export const StyledFooterSectionTitle = styled.p`
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
  line-height: 24px;
`;

export const StyledFooterSectionText = styled.p`
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 24px;
`;

export const StyledFooterGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: calc(100% + 24px);
  margin: 0 -12px 0;
  justify-content: center;
`;

interface StyledFooterGridItemProps {
    isLast?: boolean;
}

export const StyledFooterGridItem = styled.div<StyledFooterGridItemProps>`
  padding: 12px;
  box-sizing: border-box;
  flex-basis: 100%;

  @media (min-width: ${({theme}) => theme.breakpoints.values.md}px) {
    flex-basis: 33.33%;
    ${({isLast}) =>
    isLast &&
    css`
        flex-basis: unset;
      `}
  }
  @media (min-width: 1600px) {
    flex-basis: unset;
    flex-grow: 1;
  }
`;
