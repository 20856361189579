import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import {
  CityCityReadIdReadNameRead,
  CountryCountryReadIdReadNameRead,
  MedicalCenter,
} from "types/api.types";

export interface DictionariesState {
  countries: CountryCountryReadIdReadNameRead[];
  cities: CityCityReadIdReadNameRead[];
  medicalCenters: MedicalCenter[];
}

const initialState: DictionariesState = {
  countries: [],
  cities: [],
  medicalCenters: [],
};

export const fetchCountriesDictionary = createAsyncThunk(
  "dictionaries/fetchCountriesDictionary",
  async () => {
    const response = await axiosSecureInstance.get("/api/countries");

    return response.data;
  }
);

export const fetchCitiesDictionary = createAsyncThunk(
  "dictionaries/fetchCitiesDictionary",
  async (countryId: string) => {
    const response = await axiosSecureInstance.get("/api/cities", {
      params: { country: countryId },
    });

    return response.data;
  }
);

export const fetchMedicalCentersDictionary = createAsyncThunk(
  "dictionaries/fetchMedicalCentersDictionary",
  async () => {
    const response = await axiosSecureInstance.get("/api/medical_centers");

    return response.data;
  }
);

export const dictionariesSlice = createSlice({
  name: "dictionaries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountriesDictionary.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(fetchCitiesDictionary.fulfilled, (state, action) => {
      state.cities = action.payload;
    });
    builder.addCase(
      fetchMedicalCentersDictionary.fulfilled,
      (state, action) => {
        state.medicalCenters = action.payload;
      }
    );
  },
});

export const selectCountriesDictionary = (state: RootState) =>
  state.core.dictionaries.countries;

export const selectCitiesDictionary = (state: RootState) =>
  state.core.dictionaries.cities;

export const selectMedicalCentersDictionary = (state: RootState) =>
  state.core.dictionaries.medicalCenters;

export default dictionariesSlice.reducer;
