import {ReactNode} from "react";
import {
    StyledDashboardWrapper,
    StyledContent,
} from "./DashboardLayoutWrapper.styled";
import Container from "@mui/material/Container";
import FooterEmpathy from "components/Footer/Empathy";
import FooterImprove from "components/Footer/Improve";
import FooterRefsal from "components/Footer/Refsal";
import FooterDefault from "components/Footer/Default";
import Nav from "./components/Nav";
import {RESEARCH} from "../../constants/env";

export interface DashboardLayoutWrapperProps {
    centerChildrenVertically?: boolean;
    children: ReactNode;
}

const DashboardLayoutWrapper = ({children, centerChildrenVertically}: DashboardLayoutWrapperProps) => {
    const renderFooter = () => {
        switch (RESEARCH) {
            case 'EMPATHY': {
                return <FooterEmpathy/>
            }
            case 'IMPROVE': {
                return <FooterImprove/>
            }
            case 'REFSAL': {
                return <FooterRefsal/>
            }
            default: {
                return <FooterDefault/>
            }
        }
    }

    return (
        <StyledDashboardWrapper>
            <Nav/>
            <StyledContent centerChildrenVertically={centerChildrenVertically}>
                <Container maxWidth={'xl'}>
                    <>{children}</>
                </Container>
            </StyledContent>
            {renderFooter()}
        </StyledDashboardWrapper>
    );
};

export default DashboardLayoutWrapper;
