import {
    StyledFooterWrapper,
    StyledFooterImg,
    StyledFooterSectionText,
    StyledFooterSectionTitle,
    StyledFooterContnetWrapper,
    StyledFooterGridItem,
    StyledFooterGridContainer,
} from "./Footer.styled";
import headerImg from "core/views/Home/Empathy/images/empathy-logo.svg";
import Box from "@mui/material/Box";
import Neo_CRF_logo from "commons/assets/Neo_CRF_logo.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <StyledFooterWrapper>
            <StyledFooterContnetWrapper>
                <StyledFooterGridContainer>
                    <StyledFooterGridItem>
                        <Box flexGrow={1} display="flex" alignItems="center" pb={1}>
                            <StyledFooterImg src={Neo_CRF_logo} alt="NeoCRF" marginRight={24}/>
                            <Box display="flex" flexDirection="column">
                                <StyledFooterSectionTitle>NEO-CRF</StyledFooterSectionTitle>
                                <StyledFooterSectionText>Neo-Vinci sp. z o.o.</StyledFooterSectionText>
                                <StyledFooterSectionText>ul. Puławska 14 | 02-512 Warszawa</StyledFooterSectionText>
                            </Box>
                        </Box>
                    </StyledFooterGridItem>
                    <StyledFooterGridItem>
                        <Box flexGrow={1} display="flex" pb={1}>
                            <Box display="flex" flexDirection="column">
                                <StyledFooterSectionTitle>
                                    {t('contact-technical-matters')}:
                                </StyledFooterSectionTitle>
                                <StyledFooterSectionText>
                                    e-mail: contact@empathytrial.com | {t('phone')}: 577 070 759
                                </StyledFooterSectionText>
                                <StyledFooterSectionText>
                                    {t('availability-hours')}: {t('monday')}-{t('friday')}, {t('hour')}: 9:00-17:00
                                </StyledFooterSectionText>
                            </Box>
                        </Box>
                    </StyledFooterGridItem>

                    <StyledFooterGridItem isLast>
                        <Box flexGrow={1} display="flex" pb={1}>
                            <StyledFooterImg src={headerImg} alt="Empathy logo"/>
                        </Box>
                    </StyledFooterGridItem>
                </StyledFooterGridContainer>
            </StyledFooterContnetWrapper>
        </StyledFooterWrapper>
    );
};

export default Footer;
