import { Route, Routes } from "react-router-dom";
import {
    ROUTES_CORE,
    ROUTES_DASHBOARD,
    ROUTES_USERS,
    ROUTES_MEDICAL_CENTERS,
    ROUTES_ECRF,
    ROUTES_DRUG,
    ROUTES_DOCUMENTATION_MANAGEMENT,
    ROUTES_DRUG_MANAGEMENT,
} from "commons/constants/paths";
import HomeEmpathy from "./views/Home/Empathy";
import HomeIdole from "./views/Home/Idole";
import HomeImprove from "./views/Home/Improve";
import HomeRefsal from "./views/Home/Refsal";
import HomeDefault from "./views/Home/Default";
import Logout from "./views/Logout";
import Dashboard from "features/dashboard/views/Dashboard";
import Page404 from "./views/Page404";
import Login from "./views/Login";
import React from "react";
import PrivateRoute from "commons/router/PrivateRoute";
import UsersList from "features/users/views/UsersList";
import UserAdd from "features/users/views/UserAdd";
import UserEdit from "features/users/views/UserEdit";
import ResetPassword from "./views/ResetPassword";
import ForgotPassword from "./views/ForgotPassword";
import MedicalCentersList from "features/medicalCenters/views/MedicalCentersList";
import MedicalCenterEdit from "features/medicalCenters/views/MedicalCenterEdit";
import MedicalCenterAdd from "features/medicalCenters/views/MedicalCenterAdd";
import EcrfForm from "features/ecrf/views/EcrfForm/EcrfForm";
import DrugEdit from "features/drugs/views/DrugEdit";
import DrugAdd from "features/drugs/views/DrugAdd";
import DrugsList from "features/drugs/views/DrugsList";
import Documentation from "features/documentation-management/views/Documentation";
import { RESEARCH } from "commons/constants/env";
import { roles } from 'commons/auth/roles';
import DrugsManagementSwitcher from "../features/drugs-management/views/DrugsManagementSwitcher";

export default function App() {
    const renderHomePage = () => {
        switch (RESEARCH) {
            case 'EMPATHY': { return <HomeEmpathy/> }
            case 'IDOLE': { return <HomeIdole/> }
            case 'IMPROVE': { return <HomeImprove/> }
            case 'REFSAL': { return <HomeRefsal/> }
            default: { return <HomeDefault/> }
        }
    }

    return (
        <Routes>
            {/* CORE ROUTES */}
            <Route path={ROUTES_CORE.HOME} element={renderHomePage()} />
            <Route path={ROUTES_CORE.FORGOT_PASSWORD} element={<ForgotPassword/>} />
            <Route path={ROUTES_CORE.RESET_PASSWORD(":token")} element={<ResetPassword/>} />
            <Route path={ROUTES_CORE.LOGIN} element={<Login />} />
            <Route path={ROUTES_CORE.LOGOUT} element={<Logout />} />

            {/*/!* ECRF ROUTES *!/*/}
            <Route path={ROUTES_ECRF.DETAILS(":id")} element={
                <PrivateRoute roles={[...roles.admin, ...roles.research]}><EcrfForm /></PrivateRoute>
            } />
            <Route path={ROUTES_ECRF.DETAILS_EXTENDED(":id", ":section")} element={
                <PrivateRoute roles={[...roles.admin, ...roles.research]}><EcrfForm /></PrivateRoute>
            } />
            <Route path={ROUTES_ECRF.DETAILS_FULL_EXTENDED(":id", ":section", ":element")} element={
                <PrivateRoute roles={[...roles.admin, ...roles.research]}><EcrfForm /></PrivateRoute>
            } />

            {/* DRUGS ROUTES */}
            <Route path={ROUTES_DRUG.ADD} element={
                <PrivateRoute roles={roles.admin}><DrugAdd /></PrivateRoute>
            } />
            <Route path={ROUTES_DRUG.EDIT(":id")} element={
                <PrivateRoute roles={roles.admin}><DrugEdit /></PrivateRoute>
            } />
            <Route path={ROUTES_DRUG.LIST} element={
                <PrivateRoute roles={roles.admin}><DrugsList /></PrivateRoute>
            } />

            {/* MEDICAL CENTERS ROUTES */}
            <Route path={ROUTES_MEDICAL_CENTERS.ADD} element={
                <PrivateRoute roles={roles.admin}><MedicalCenterAdd /></PrivateRoute>
            } />
            <Route path={ROUTES_MEDICAL_CENTERS.EDIT(":id")} element={
                <PrivateRoute roles={roles.admin}><MedicalCenterEdit /></PrivateRoute>
            } />
            <Route path={ROUTES_MEDICAL_CENTERS.LIST} element={
                <PrivateRoute roles={roles.admin}><MedicalCentersList /></PrivateRoute>
            } />

            {/* USERS ROUTES */}
            <Route path={ROUTES_USERS.ADD} element={
                <PrivateRoute roles={roles.admin}><UserAdd /></PrivateRoute>
            } />
            <Route path={ROUTES_USERS.EDIT(":id")} element={
                <PrivateRoute roles={roles.admin}><UserEdit /></PrivateRoute>
            } />
            <Route path={ROUTES_USERS.LIST} element={
                <PrivateRoute roles={roles.admin}><UsersList /></PrivateRoute>
            } />

            {/* DASHBOARD ROUTES */}
            <Route path={ROUTES_DASHBOARD.DETAILS(":type")} element={
                <PrivateRoute roles={roles.all}><Dashboard /></PrivateRoute>
            } />
            <Route path={ROUTES_DASHBOARD.DASHBOARD} element={
                <PrivateRoute roles={roles.all}><Dashboard /></PrivateRoute>
            } />

            {/* MANAGEMENTS MODULES ROUTES */}
            <Route path={ROUTES_DOCUMENTATION_MANAGEMENT.MODULE} element={
                <PrivateRoute roles={roles.all}><Documentation /></PrivateRoute>
            } />

            <Route path={ROUTES_DRUG_MANAGEMENT.MODULE} element={
                <PrivateRoute roles={[...roles.admin, ...roles.control]}><DrugsManagementSwitcher /></PrivateRoute>
            } />

            <Route path="*" element={<Page404 />}/>
        </Routes>
    );
}
