import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {useTheme} from "@mui/material/styles";
import {useAppDispatch} from "commons/store/hooks";
import SelectMultipleFormik from "components/Form/SelectMultiple";
import TextFieldFormik from "components/Form/TextFieldFormik";
import {
    fetchMedicalCentersDictionary,
} from "core/redux/dictionariesSlice";
import {Form} from "formik";
import React, {useEffect, useState} from "react";
import axiosSecureInstance from "../../../../commons/axios/axiosSecureInstance";
import {useTranslation} from "react-i18next";

const UserForm = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [options, setOptions] = useState([{key: '', value: ''}]);
    const { t } = useTranslation();

    /**
     * This function will fetch medical centers list
     * and create array for SelectMultipleFormik props
     */
    const fetchDataAndParse = async () => {
        const medicalCentersData = await dispatch(fetchMedicalCentersDictionary()).unwrap();
        const medicalCentersArray: any = [];

        for (const center of medicalCentersData) {
            const medicalCenter = {
                value: center.id,
                key: center.name,
            };

            medicalCentersArray.push(medicalCenter);
        }
        setOptions(medicalCentersArray);
    };

    const [rolesList, setRolesList] = useState<any[]>([])
    const fetchRoles = async () => {
        const response = await axiosSecureInstance.get<any>(`/api/roles`)
        setRolesList(Object.entries(response.data))
    }

    useEffect(() => {
        fetchDataAndParse();
        fetchRoles();
    }, [dispatch]);

    return (
        <Form>
            <Box>
                <TextFieldFormik
                    margin="dense"
                    name='firstname'
                    label={t('firstname')}
                    variant="filled"
                    fullWidth
                />
                <TextFieldFormik
                    margin="dense"
                    name='lastname'
                    label={t('lastname')}
                    variant="filled"
                    fullWidth
                />
                <TextFieldFormik
                    margin="dense"
                    name="email"
                    label={t('e-mail')}
                    variant="filled"
                    fullWidth
                />
                <TextFieldFormik
                    margin="dense"
                    name="phone"
                    label={t('phone')}
                    variant="filled"
                    fullWidth
                />
                <TextFieldFormik
                    margin="dense"
                    name="roles"
                    label={t('role')}
                    select
                    fullWidth
                    variant="filled"
                >
                    {rolesList.map((e: any) => (<MenuItem value={e[0]}>{e[1]}</MenuItem>))}
                </TextFieldFormik>
                <SelectMultipleFormik
                    options={options}
                    margin="dense"
                    name="medicalCenters"
                    label={t('medical-center')}
                    fullWidth
                    variant="filled"
                />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Box boxShadow={theme.shadows[4]}>
                    <Button type="submit" color="primary" variant="contained">
                        {t('save')}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
};

export default UserForm;
