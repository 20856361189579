import { Box, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import { fetchUserProfile, selectUserProfile } from "core/redux/userSlice";
import PageForbidden from "core/views/PageForbidden";
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ROUTES_DRUG_MANAGEMENT } from "commons/constants/paths";

type UserProfileWrapperProps = {
    children: React.ReactNode;
    roles?: string[];
};

const UserProfileWrapper = ({children, roles}: UserProfileWrapperProps) => {
    const dispatch    = useAppDispatch();
    const userProfile = useAppSelector(selectUserProfile);
    const userRoles   = userProfile?.roles || [];
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchUserProfile());
    }, [dispatch]);

    useEffect(() => {
        if (userRoles.includes('ROLE_DRUG_DATA_ENTRY')) {
            navigate(ROUTES_DRUG_MANAGEMENT.MODULE);
        }
    }, [userProfile]);

    if (!userProfile) {
        return (
            <Box minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={50}/>
            </Box>
        );
    }

    if (roles && !userRoles.some((role: string) => roles.includes(role))) {
        return <PageForbidden/>;
    }

    return children;
};

export default UserProfileWrapper;
