import styled from "styled-components";
import Drawer from "@mui/material/Drawer";

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 350px;
    z-index: 1000;
    background-color: #fff;
  }
`;

export const StyledDrawerContentWrapper = styled.div`
  padding-top: 56px;
`;

export const StyledNotificationBox = styled.div`
  display: flex;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;

  &:hover {
    background-color: #6bdfe1;
  }
`;
