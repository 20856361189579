/*
 * Project: ecrf-front
 * Author: Levan Ostrowski
 * User: cod3venom
 * Date: 05.12.2021
 * Time: 14:51
*/

import AbstractResource from "./AbstractResource";

export interface IEventStoreBus {
    eventName: string;
    eventCallback?: any;
    eventData?: CustomEvent;
}

export default class EventStore extends AbstractResource {
    private eventBus: IEventStoreBus[] = [];

    /**
     * @param eventName
     * @param eventData
     */
    public emit(eventName: string, eventData: any = {}) {
        this.eventBus.push({eventName: eventName, eventData: eventData});
        window.dispatchEvent(new CustomEvent(eventName, {detail: {...eventData}}));

        if (window.eventStoreDebugMode) {
            this.debug('emit', {eventName: eventName, eventData: eventData})
        }
    }

    /**
     * @param eventName
     * @param callBack
     */
    public on(eventName: string, callBack: any) {
        [].slice.call(this.eventBus).map((event: IEventStoreBus, idx: number) => {
            if (event.eventName === eventName) {
                return {};
            }
        })
        window.addEventListener(eventName, callBack);

        if (window.eventStoreDebugMode) {
            this.debug('on', {eventName: eventName, eventData: {} as CustomEvent})
        }
    }

    /**
     * @clearBus method is used  for deleting events from the
     * @eventBus collection.
     *
     * It should be invoked from
     * @componentDidMount
     * @componentWillUpdate
     */
    public clearBus() {
        [].slice.call(this.eventBus).map((event: IEventStoreBus, idx: number) => {
            document.removeEventListener(event.eventName, event.eventCallback)
        })
        this.eventBus = [];
    }

    private debug(commandType: string, event: IEventStoreBus): void {
        // console.log(`$eventStore:~$ ${commandType} # ${event.eventName} => ${JSON.stringify(event.eventData)}`)
    }
}