import { createGlobalStyle} from "styled-components";
import { COLOR_BG } from "../constants/env";

export default createGlobalStyle`
  *, body, html {
    margin: 0;
    padding: 0;
    font-family: ${({theme}) => theme.typography.fontFamily}; 
    letter-spacing: 0.5px;
  }
  body {
      background-color: ${COLOR_BG};
  }
`;
