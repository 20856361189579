import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { useField } from "formik";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import { fetchEcrf, selectEcrf } from "features/ecrf/redux/ecrfSlice";
import DateTime from "core/helpers/date/DateTime";
import ConfirmationAction from "components/ConfirmationAction";
import { randomizePatient } from "features/ecrf/redux/ecrfSlice";
import { useParams } from "react-router-dom";
import { Dialog, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useSnackbar } from "notistack";
import {DATETIME_FORMAT} from "../../commons/constants/env";
import {useTranslation} from "react-i18next";

export interface DicomButtonFormikProps
  extends Omit<ButtonProps, "name" | "value" | "onChange" | "onBlur" | "error"> {
  name: string;
  link: string;
  helperText?: string;
  buttonText?: string;
  options: Array<{ key: string; value: string }>;
  extra: string;
  data: any;
  status: boolean;
}

type EcrfFormParams = {
  id: string;
};

const RandomizeTypeFormik = ({
  name,
  link,
  helperText,
  buttonText,
  options,
  extra,
  data,
  status = false,
  ...props
}: DicomButtonFormikProps) => {
  const dispatch = useAppDispatch();
  const ecrf = useAppSelector(selectEcrf);
  const [field, meta] = useField(name);
  const [isDisabled, setIsDisabled] = useState(status);
  const [text, setText] = useState(buttonText);
  const { id } = useParams<EcrfFormParams>();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  /** handle modal options */
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Change button state when randomization is already done
   */
  const updateButtonStatus = () => {
    if (ecrf?.randomAt) {
      setIsDisabled(true);
      setText(`${t('randomized')}} - ${DateTime.toIso(ecrf?.randomAt, DATETIME_FORMAT)}`);
    }

    if (ecrf?.status === 6) {
      setIsDisabled(true);
      setText(`${t('blocked')}: ${t('screen-failure')}`);
    }
  };

  const [isProcessing, setIsProcessing] = useState(false)

  /**
   * Randomize patient using button instead auto-randomization
   */
  const randomize = async () => {
    setIsProcessing(true)

    try {
      id && await dispatch(randomizePatient(id));
      id && await dispatch(fetchEcrf(id));

      setOpen(true);
    } catch (error: any) {
      enqueueSnackbar(error?.detail || error?.message, {variant: 'error'});
    }

    setIsProcessing(false)
  };

  useEffect(() => {
    updateButtonStatus();
  }, [ecrf, dispatch]);

  return (
    <Box pt={0}>
      <ConfirmationAction
        confirmationTitle={t('are-you-sure-you-want-to-randomize')}
        onClick={() => randomize()} isProcessing={isProcessing}
      >
        {({ handleClick }) => (
          <Button
            name={field.name}
            id={field.name}
            value={field.value}
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={isDisabled || status}
            {...props}
          >
            {field.value}
            {text ?? t('randomize')}
          </Button>
        )}
      </ConfirmationAction>

      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box color="black" fontWeight="bold" fontSize="20px">
                {t('the-patient-was-randomized')}
                <Box fontSize="18px">{DateTime.toIso(ecrf?.randomAt, DATETIME_FORMAT)}</Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              {t('ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default RandomizeTypeFormik;
