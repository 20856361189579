import React, {useEffect, useState} from "react";
import { map } from "lodash";
import CheckboxFormik from "./CheckboxFormik";
import { useField } from "formik";
import { Box, Button } from "@mui/material";
import { CheckboxProps } from "@mui/material/Checkbox";
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import {useTranslation} from "react-i18next";

export interface CheckboxMultipleFormikProps
    extends Omit<CheckboxProps, "name" | "onChange" | "onBlur" | "error"> {
    id: string;
    options: any;
    status?: boolean;
}

const CheckboxMultipleFormik = ({status, id, options, ...props}: CheckboxMultipleFormikProps) => {
    const [field, meta, helpers] = useField(id);
    const { t } = useTranslation();

    // const { value } = meta;
    const { setValue } = helpers;

    /**
     * Button action controller toggle select all form
     */
    const [isAllSelected, setAllSelected] = useState<boolean>(false)
    const [valueOptions, setValueOptions] = useState<any>(options?.choices || [])

    /**
     *  This function is used to select all values and save it to field value
     */
    const handleSelectAll = () => {
        if (!valueOptions) return

        let allValues: any = []
        if (!isAllSelected) {
            for (const option of Object.entries(valueOptions)) {
                allValues.push(String(option[1]))
            }
        }

        // set all values to field
        setValue(allValues)
    }

    /**
     * This function listen status of form and change state of isAllSelected flag
     */
    const checkSelectedValues = () => {
        if (!field.value) return

        if (field.value.length === Object.entries(valueOptions).length) {
            setAllSelected(true)
        } else {
            setAllSelected(false)
        }
    }

    useEffect(() => {
        checkSelectedValues()
    }, [field.value])

    return (
        <>
            <Button
                disabled={status}
                startIcon={isAllSelected ? <LayersClearIcon/> : <LayersIcon/>}
                onClick={(() => handleSelectAll())}
                color={'primary'}
            >
                {isAllSelected ? t('unselect-all') : t('select-all')}
            </Button>
            <Box mt={1}>
                {map(valueOptions, (option, key) =>
                    <CheckboxFormik
                        status={status}
                        key={String(option)}
                        name={id}
                        label={key}
                        value={String(option)}
                    />
                )}
            </Box>
        </>
    )
};

export default CheckboxMultipleFormik;
