import React, {ReactNode, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { CheckboxProps } from "@mui/material/Checkbox";
import { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormField from "features/ecrf/views/EcrfForm/FormField";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import {
  editedElementData,
  fetchCollectionDependencies,
  fetchElementDatasChildren,
  selectEcrf
} from "features/ecrf/redux/ecrfSlice";
import { useParams } from "react-router-dom";
import Dependency from "core/helpers/Dependency";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { StyledEcrfTitle } from 'features/ecrf/views/EcrfForm/Ecrf.styled';
import getInitialValues from 'features/ecrf/utils/getInitialValues';
import { useFormikContext } from "formik";
import {useTranslation} from "react-i18next";

export interface CollectionFormikProps
  extends Omit<CheckboxProps, "name" | "onChange" | "onBlur" | "error"> {
  name: string;
  label?: ReactNode;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  children?: any[];
  dataId?: string;
  setIsOpenQueryPanel: (elementId: string) => void;
  isOpenQueryPanel: string | null;
  status?: number;
}

type EcrfFormParams = {
  id: string;
};

const CollectionFormik = ({
  name,
  label,
  value,
  labelPlacement,
  status,
  children,
  dataId,
  setIsOpenQueryPanel,
  isOpenQueryPanel,
  ...props
}: CollectionFormikProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar} = useSnackbar();
  const { setFieldValue, getFieldMeta } = useFormikContext<object>();
  const {id} = useParams<EcrfFormParams>();
  const ecrf = useAppSelector(selectEcrf);
  const editedEd = useAppSelector(editedElementData);
  const { t } = useTranslation();

  const [collectionData, setCollectionData] = useState<any>([]);
  const [collectionDependencies, setCollectionDependencies] = useState<any>([]);
  const [isInitDataLoading, setInitDataLoading] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);

  const loadData = async (elements: any, ecrf: any, init: boolean = false) => {
    try {
      // const data = await dispatch(fetchCollectionElementDatas(
      //     {elementId: name, ecrfId: ecrf.id}
      // )).unwrap();

      let data = [];
      if (dataId) {
        data = await dispatch(fetchElementDatasChildren(dataId)).unwrap();
        data && setCollectionData(data)

        // if (init) {
        const initialValues = await getInitialValues(elements, data);

        Object.entries(initialValues).forEach((element: any, b: any) => {
          setFieldValue(element[0], element[1]);
        })
        // }
      }

      const deps = await dispatch(fetchCollectionDependencies(name)).unwrap();

      // @todo check|valid new structure depends
      // dependencies
      const dependency = await Dependency.createDep(
          elements, data, ecrf.id, deps // dependencies
      );

      if (dependency) {
        setCollectionDependencies(dependency);
      }

      setDataLoading(false)
    } catch (error: any) {
      enqueueSnackbar(error?.message || error?.detail, {variant: 'error'});
    }
  }

  useEffect(() => {
    const fetch = async () => {
      setInitDataLoading(true)
      await loadData(children, ecrf, true);
      setInitDataLoading(false)
    };
    fetch();
  }, [id]);

  const reload = async () => {
    setDataLoading(true)
    await loadData(children, ecrf);
  };

  /** Using to re-render section elements */
  useEffect(() => {
    if (editedEd.id) {
      reload()
    }
  }, [editedEd.id]);

  return (
    <Box pt={3}>
      {isInitDataLoading ? (
        <Box display={'grid'} justifyContent={'center'} p={5}>
          <Box display={'grid'} style={{ placeItems: 'center', gap: '15px' }}>
            <CircularProgress/>
            <StyledEcrfTitle>{t('loading-data')}</StyledEcrfTitle>
          </Box>
        </Box>
      ) : (
          <>
          {collectionDependencies.length > 0 && collectionDependencies?.slice()?.map((element: any) => {
            const elementData = collectionData?.findLast(
              (ed: any) => ed?.element?.id === element.id
            );

            return (
              <FormField
                element={element}
                key={element.id}
                ecrfId={id}
                elementStatus={elementData?.status}
                elementData={elementData}
                hasActionPanel={element?.helper === 0}
                hasElementDataInstance={!!elementData}
                hasQueryPanel={element?.helper === 0}
                isOpenQueryPanel={isOpenQueryPanel}
                setIsOpenQueryPanel={setIsOpenQueryPanel}
                isAllSaving={isDataLoading}
                // statusElement={status === 3 || isDataLoading}
                statusElement={status === 3}
              />
            );
          })}
          </>
      )}
    </Box>
  );
};

export default CollectionFormik;
