import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./locales/en/translations.json";
import pl from "./locales/pl/translations.json";
import { PROJECT_LANG } from "../commons/constants/env";

i18n.use(initReactI18next).init({
    fallbackLng: PROJECT_LANG,
    lng: PROJECT_LANG,
    resources: {
        en: {
            translations: en
        },
        pl: {
            translations: pl
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = ['en', 'pl'];

export default i18n;