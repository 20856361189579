/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 03.03.2022
 * Time: 13:32
 */

import React, {useState} from 'react';
import {Box, Collapse, Tooltip} from "@mui/material";
import {StyledListTableHead} from "./ObservationList.styled";
import {COLOR_SECONDARY_LIGHT} from "../../../../commons/constants/env";
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {useTranslation} from "react-i18next";

interface IObservationListItemProps {
    visit: any;
    idx?: number;
    maindIdx?: number;
}

const ObservationListItem: React.FC<IObservationListItemProps> = (props) => {
    const { t } = useTranslation();
    const [showChildren, setShowChildren] = useState(false)
    return (
        <>
            <StyledListTableHead
                hasChild={(props.visit?.sections && props.visit?.sections.length > 0)
                    || (props.visit?.children && props.visit?.children.length > 0)}
                onClick={() => {
                    if ((props.visit?.sections && props.visit?.sections.length > 0)
                        || (props.visit?.children && props.visit?.children.length > 0)) {
                        setShowChildren(!showChildren)
                    }
                }}>
                <span>{props?.maindIdx}{props?.maindIdx ? '.' : ''}{props.idx}</span>
                <span>{props.visit?.name}</span>
                <span style={{textAlign: 'end'}}>
                         <Tooltip
                             title={t('visit-completion-status-number-of-completed-fields-number-of-all-fields')}
                             arrow
                         >
                            <span style={{cursor: 'help'}}>
                                {props.visit?.countElementsWithData} / {props.visit?.countElements}
                            </span>
                         </Tooltip>
                    </span>

                <>
                    {((props.visit?.children && props.visit?.children.length > 0)
                            || (props.visit?.sections && props.visit?.sections.length > 0)) &&
                        <span style={{textAlign: 'end'}}>
                            {showChildren ? <ExpandLess/> : <ExpandMore/>}
                        </span>}
                </>

            </StyledListTableHead>

            <Box ml={5} style={{borderLeft: `3px solid ${COLOR_SECONDARY_LIGHT}`}}>
                {props.visit?.sections && (
                    <Collapse in={showChildren} unmountOnExit mountOnEnter>
                        {[].slice.call(props.visit?.sections).map((section: any, idx: number) =>
                            <ObservationListItem key={section.id} visit={section} maindIdx={props.idx} idx={idx + 1}/>
                        )}
                    </Collapse>
                )}

                {props.visit?.children && (
                    <Collapse in={showChildren} unmountOnExit mountOnEnter>
                        {[].slice.call(props.visit?.children).map((section: any, idx: number) =>
                            <ObservationListItem key={section.id} visit={section} maindIdx={props.idx} idx={idx + 1}/>
                        )}
                    </Collapse>
                )}
            </Box>
        </>
    );
};

export default ObservationListItem