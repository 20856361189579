/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 14.02.2022
 * Time: 10:36
 */

import {
    StyledDashboardQueriesTable,
    StyledDashboardQueriesTableBody,
    StyledDashboardQueriesTableHead,
    StyledDashboardQueriesTitle,
    StyledDashboardQueriesWrapper
} from "./DashboardQueries.styled";
import QueryRow from "./QueryRow";
import axiosSecureInstance from "../../../../commons/axios/axiosSecureInstance";
import React, { Component } from 'react';
import { Box, Button, CircularProgress } from "@mui/material";
import { MoreHorizOutlined } from '@mui/icons-material';
import QueriesPanel from "../../../ecrf/components/QueriesPanel";
import { enqueueSnackbar } from 'notistack'
import { useAppDispatch, useAppSelector } from "../../../../commons/store/hooks";
import { useSearchParams } from "react-router-dom";
import { selectUserProfile } from "../../../../core/redux/userSlice";
import { FilterPanel } from "./FilterPanel";
import { useTranslation } from "react-i18next";

const withRouter = (WrappedComponent: any) => (props: any) => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const userProfile = useAppSelector(selectUserProfile);
    const { t } = useTranslation();

    return (
        <WrappedComponent
            {...props}
            dispatch={dispatch}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            userProfile={userProfile}
            t={t}
        />
    );
};

class DashboardQueries extends Component<any, any> {
    constructor(props: any) {
        super(props)
    }

    state = {
        page: 1,
        itemsPerPage: 30,
        totalItems: 0,
        isOpenQueryPanel: false,
        selectedQueryPanel: {
            currentEcrfID: null,
            elementDataId: null,
            elementName: null,
            queryId: null
        },
        data: [],
        isDataLoading: false,
        displayFilters: false,
        ecrfValue: '',
        authorValue: '',
        recipientValue: '',
        startDate: null,
        endDate: null
    }

    /**
     * This method is used to fetch queries from DB
     * @private
     */
    private async fetchData(initial: boolean = true, page = 1) {
        this.setState({isDataLoading: true});

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/hal+json',
                    Accept: 'application/hal+json',
                },
                params: {
                    pagination: true,
                    page: page,
                    itemsPerPage: this.state.itemsPerPage,
                    ...Object.fromEntries(this.props.searchParams)
                },
            };

            initial && this.setState({data: []});
            this.setState({page: page});

            const response = await axiosSecureInstance.get<any>(`/api/comments`, config);

            let newData: any = [];
            if (initial) {
                newData = response.data._embedded.item;
            } else {
                newData = [...this.state.data, ...response.data._embedded.item];
            }

            this.setState({
                data: newData,
                itemsPerPage: response.data?.itemsPerPage,
                totalItems: response.data?.totalItems,
            });
        } catch (error: any) {
            enqueueSnackbar(this.props.t('not-found'), {variant: 'warning'});
        }

        this.setState({isDataLoading: false});
    }

    /**
     * This method is used to open query panel
     * @private
     */
    private openQueryPanel() {
        this.setState({isOpenQueryPanel: true});
    }

    /**
     * This method is used to set props for query panel
     * @param ecrfId
     * @param elementId
     * @param elementName
     * @private
     */
    private selectRowData(ecrfId: string, elementId: string, elementName: string) {
        const newData = {
            currentEcrfID: ecrfId,
            elementDataId: elementId,
            elementName: {
                label: elementName
            },
            queryId: ecrfId
        }

        this.setState({selectedQueryPanel: newData});
    }

    /**
     * Load config and fetch initial data
     */
    componentDidMount() {
        this.fetchData().then()
    }

    componentDidUpdate(prevProps: any){
        if (prevProps?.searchParams !== this.props?.searchParams) {
            this.fetchData().then();
        }
    }

    render() {
        return (
            <>
                <StyledDashboardQueriesWrapper>
                    <FilterPanel/>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <StyledDashboardQueriesTitle>
                            {this.props.t('queries')}
                        </StyledDashboardQueriesTitle>
                    </Box>
                    <StyledDashboardQueriesTable isOpen={this.state.isOpenQueryPanel}>
                        <StyledDashboardQueriesTableHead>
                            <span>Lp.</span>
                            <span />
                            <span>{this.props.t('screening-number')}</span>
                            <span>{this.props.t('date-added')}</span>
                            <span>{this.props.t('added')}</span>
                            <span>{this.props.t('received')}</span>
                            <span>{this.props.t('section')}</span>
                            <span>{this.props.t('field')}</span>
                            <span>{this.props.t('field-content')}</span>
                            <span>{this.props.t('query-content')}</span>
                            <span>{this.props.t('status')}</span>
                            <span>{this.props.t('action')}</span>
                        </StyledDashboardQueriesTableHead>
                        <StyledDashboardQueriesTableBody>
                            {this.state.data
                                // .filter((e: any) => e?._embedded?.element?.id)
                                .map((row: any, idx) =>
                                    <QueryRow
                                        selectRowData={this.selectRowData.bind(this)}
                                        idx={idx + 1}
                                        openQueryPanel={this.openQueryPanel.bind(this)}
                                        user={this.props.userProfile}
                                        key={row?.id}
                                        data={row}
                                    />
                            )}
                        </StyledDashboardQueriesTableBody>
                    </StyledDashboardQueriesTable>

                    {this.state.isDataLoading &&
                        <Box width={'100%'} display={'grid'} mt={5} justifyContent={'center'}>
                            <CircularProgress />
                        </Box>
                    }

                    {(!this.state.isDataLoading && this.state.data.length > 0 &&  this.state.data.length < this.state.totalItems) && (
                        <Box width={'100%'} display={'grid'} mt={5} justifyContent={'center'}>
                            <Button
                                onClick={() => this.fetchData(false, this.state.page + 1)}
                                color={'secondary'}
                                disabled={this.state.isDataLoading}
                                startIcon={this.state.isDataLoading ?
                                    <CircularProgress color={'secondary'} size={12} /> :
                                    <MoreHorizOutlined />}
                                variant={'outlined'}>
                                {this.props.t('show-more')}
                            </Button>
                        </Box>
                    )}
                </StyledDashboardQueriesWrapper>

                {/* <--- Queries Panel ---> */}
                <QueriesPanel
                    currentEcrfId={this.state.selectedQueryPanel.currentEcrfID}
                    isOpen={this.state.isOpenQueryPanel}
                    queryId={this.state.selectedQueryPanel.queryId || '-'}
                    onClose={() => {
                        this.setState({isOpenQueryPanel: false})
                    }}
                    elementDataId={this.state.selectedQueryPanel.elementDataId}
                    element={this.state.selectedQueryPanel.elementName}
                />
            </>
        );
    }
}

export default withRouter(DashboardQueries);