import React, { ForwardedRef, forwardRef } from "react";
import { StyledColoredIconWrapper } from "./ColoredIconWrapper.styled";

export type color = "primary" | "secondary" | "white" | "default";

export interface ColoredIconWrapperProps {
  color?: color;
  children?: React.ReactNode;
}

const ColoredIconWrapper = forwardRef(
  (
    { color = "primary", children, ...rest }: ColoredIconWrapperProps,
    ref: ForwardedRef<HTMLSpanElement>
  ) => {
    return (
      <StyledColoredIconWrapper ref={ref} $color={color} {...rest}>
        {children}
      </StyledColoredIconWrapper>
    );
  }
);

export default ColoredIconWrapper;
