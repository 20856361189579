import Typography from "@mui/material/Typography";
import { ROUTES_DRUG } from "commons/constants/paths";
import { useAppDispatch } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import CurrentForm from "features/drugs/components/DrugForm";
import { addResource } from "features/drugs/redux/drugsSlice";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import yup from "commons/yup";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import Box from "@mui/material/Box";
import { InitialValuesState } from "features/drugs/components/DrugForm/DrugForm";

const validationSchema = yup.object({
  name: yup.string().required(),
});

const DrugAdd = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    name: ""
  };

  const handleSubmit = async (values: InitialValuesState) => {
    try {
      await dispatch(addResource(values));
      enqueueSnackbar("Nowy lek został dodany", {variant: 'success'});
      navigate(ROUTES_DRUG.LIST);
    } catch (error: any) {
      enqueueSnackbar(error?.detail, {variant: 'error'});
    }
  };

  return (
    <DashboardLayoutWrapper centerChildrenVertically>
      <ColoredBox p={1} width={352} maxWidth="100%" ml="auto" mr="auto">
        <Box mb={3.5}>
          <Typography variant="h5">Nowy ośrodek badawczy</Typography>
        </Box>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <CurrentForm />
        </Formik>
      </ColoredBox>
    </DashboardLayoutWrapper>
  );
};

export default DrugAdd;
