import styled from 'styled-components';
import {
    COLOR_BG_DARK,
    COLOR_BG_NAV,
    COLOR_PRIMARY,
    COLOR_PRIMARY_LIGHT,
    COLOR_SECONDARY
} from "../../../commons/constants/env";

export const StyledUploaderModalWrapper = styled.div`
   background-color: ${COLOR_BG_DARK};
    padding: 30px;
    padding-bottom: 50px;
    padding-top: 50px;
    border-radius: 10px;
    display: grid;
    place-items: center;
    color: #243A4E;
    max-width: 600px;
`;

export const StyledUploaderTitle = styled.div`
    font-size: 20px;
`;

export const StyledUploaderDesc = styled.div`
    font-size: 15px;
    opacity: 50%;
    transition: .2s ease;
    text-align: center;
    margin-top: 15px;
    
    &:hover {
    opacity: 100%;
    }
`;

export const StyledUploaderFieldTitle = styled.div`
    font-size: 15px;
    text-align: center;
    margin-top: 25px;
`;

export const StyledUploaderFieldDesc = styled.div`
    font-size: 15px;
    color: #929DA7;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const StyledUploaderField = styled.div<any>`
     margin-top: 25px;
     padding: 25px;
     border: 3px dotted #929DA7;
     border-radius: 3px;
     display: grid;
     place-items: center;
     width: calc(100% - 60px);
     transition: .2s ease;
     cursor: pointer;
     
     &:hover {
     background-color: ${COLOR_PRIMARY_LIGHT};
     border-color: ${COLOR_PRIMARY};
     }
`;

export const StyledUploaderFieldSelected = styled.div<any>`
     padding: 25px;
     border-radius: 3px;
     display: grid;
     place-items: center;
     width: calc(100% - 60px);
`;

export const StyledUploaderFilesWrapper = styled.div<any>`
    margin-bottom: 30px;
    width: 90%;
`;

export const StyledUploaderFilesItem = styled.div<any>`
    display: grid;
    gap: 20px;
    width: 100%;
    font-size: 15px;
    margin: 10px;
    align-items: center;
    grid-template-columns: ${(props) => (props.isAdmin ? '30px 1fr 0fr 0fr 0fr' : '30px 1fr 0fr 0fr')};
    transition: .2s ease;
    
    &:hover {
    background-color: ${COLOR_BG_NAV};
    }
`;

export const StyledUploaderFilesItemSize = styled.div<any>`
    font-size: 12px;
    font-weight: bold;
    padding: 6px 15px;
    background-color: ${COLOR_BG_NAV};
    border-radius: 3px;
    white-space: nowrap;
`;

export const StyledUploaderFilesItemText = styled.div<any>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
    width: 100%;
`;

export const StyledUploaderFilesSize = styled.div<any>`
    font-size: 12px;
    font-weight: bold;
    padding: 6px 15px;
    color: ${(props) => (props.limit ? '#EF4444' : COLOR_SECONDARY)} ;;
    background-color: ${(props) => (props.limit ? '#FEE2E2' : COLOR_BG_NAV)} ;
    border-radius: 3px;
    white-space: nowrap;
    margin-top: 20px;
    text-align: center;
`;