import {
  Button,
  CircularProgress,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import React, { ReactNode, useState } from "react";

type ChildProps = {
  handleClick: () => void;
};

type ConfirmationActionProps = {
  children(state: ChildProps): ReactNode;
  onClick: () => void;
  confirmationTitle?: string;
  isProcessing?: boolean;
  confirmationContent?: string | undefined;
};

const ConfirmationAction = ({
  children,
  onClick,
  isProcessing,
  confirmationTitle = "Are you sure?",
  confirmationContent = undefined,
}: ConfirmationActionProps) => {
  const [isShowingConfirmationModal, setIsShowingConfirmationModal] = useState(false);

  const handleSubmit = async () => {
    await onClick();
    setIsShowingConfirmationModal(false);
  };

  const handleClick = () => {
    setIsShowingConfirmationModal(true);
  };

  return (
    <>
      {children({ handleClick })}
      <Dialog
        open={isShowingConfirmationModal}
        onClose={() => setIsShowingConfirmationModal(false)}
        fullWidth
      >
        <DialogTitle>{confirmationTitle}</DialogTitle>
        {confirmationContent && (
          <DialogContent>
            <Button size="small" onClick={() => window.open(confirmationContent)}>PREVIEW PDF</Button>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={() => setIsShowingConfirmationModal(false)} color="primary">
            Nie
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus disabled={isProcessing}>
            {isProcessing ? <CircularProgress size={23} /> : "Tak"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationAction;
