/*
 * Project: ecrf-front
 * Author: Levan Ostrowski
 * User: cod3venom
 * Date: 05.12.2021
 * Time: 14:57
*/

export default class Store {
    private _store: CustomEvent[] = [];

    public exists(event: CustomEvent): boolean {
        return !!this._store.find(e => e === event);
    }

    public write(event: CustomEvent, duplicate: boolean = false){
        if (duplicate) {
            this._store.push(event);
            return;
        }

        if (!this.exists(event)) {
            this._store.push(event);
        }
    }

    public read(eventName: string) {
        return this._store.find(e => e.type === eventName);
    }

    public delete(eventName: string) {

    }
}
