import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import { Drug as CurrentData } from "types/api.types";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";

// @todo one url variable per module or from route list

export interface ResourcesState {
  drugs: {
    itemsPerPage: number;
    totalItems: number;
    data: CurrentData[];
  };
  drugDetails: CurrentData | null;
  drugTypes: {
    itemsPerPage: number;
    totalItems: number;
    data: any[];
  };
}

const initialState: ResourcesState = {
  drugs: {
    itemsPerPage: 0,
    totalItems: 0,
    data: [],
  },
  drugDetails: null,
  drugTypes: {
    itemsPerPage: 0,
    totalItems: 0,
    data: [],
  },
};

interface ResourceValues {
  name: string;
}

type fetchResourcesParams = {
  page: number;
};

export const fetchResources = createAsyncThunk(
  "drugs/fetchResources",
  async ({ page }: fetchResourcesParams) => {
    const response = await axiosSecureInstance.get<
      HalResource<CurrentData[]>
    >("/api/drugs", {
      headers: {
        accept: "application/hal+json",
      },
      params: {
        page,
        pagination: true,
      },
    });
    return response.data;
  }
);

export const fetchResource = createAsyncThunk(
  "drugs/fetchResource",
  async (id: string) => {
    const response = await axiosSecureInstance.get<CurrentData>(
      `/api/drugs/${id}`
    );
    return response.data;
  }
);

export const addResource = createAsyncThunk(
  "drugs/addResource",
  async (values: ResourceValues, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.post(`/api/drugs`, {
        ...values,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

type editResourceParams = {
  id: string | undefined;
  values: ResourceValues;
};

export const editResource = createAsyncThunk(
  "drugs/editResource",
  async ({ id, values }: editResourceParams, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.put(`/api/drugs/${id}`, {});
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteResource = createAsyncThunk(
  "drugs/deleteResource",
  async (id: string) => {
    await axiosSecureInstance.delete(`/api/drugs/${id}`);
  }
);

type fetchDrugTypesParams = {
    page?: number;
};

export const fetchDrugTypes = createAsyncThunk(
    "drugs/fetchDrugTypes",
    async ({ page }: fetchDrugTypesParams) => {
        const response = await axiosSecureInstance.get<
            HalResource<CurrentData[]>
        >("/api/drug_types", {
            headers: {
                accept: "application/hal+json",
            },
            params: {
                page,
                pagination: true,
            },
        });
        return response.data;
    }
);

export const drugsSlice = createSlice({
  name: "drugs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchResources.fulfilled, (state, action) => {
      state.drugs.data = action.payload._embedded?.item || [];
      state.drugs.itemsPerPage = action.payload.itemsPerPage;
      state.drugs.totalItems = action.payload.totalItems;
    });
    builder.addCase(fetchResource.fulfilled, (state, action) => {
      state.drugDetails = action.payload;
    });
    builder.addCase(fetchDrugTypes.fulfilled, (state, action) => {
      state.drugTypes.data = action.payload._embedded?.item || [];
      state.drugTypes.itemsPerPage = action.payload.itemsPerPage;
      state.drugTypes.totalItems = action.payload.totalItems;
    });
  },
});

export const selectResources = (state: RootState) => state.drugs.drugs.drugs;
export const selectResourceDetails = (state: RootState) => state.drugs.drugs.drugDetails;
export const selectDrugTypes = (state: RootState) => state.drugs.drugs.drugTypes;

export default drugsSlice.reducer;
