import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import {
  StyledStepperWrapper,
  StyledButton,
  StyledActionsContainer,
} from "./Steper.styled";
import { useState } from "react";

function getSteps() {
  return ["Brief title", "Primary endpoint", "Key secondary endpoints"];
}

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return `Study to evaluate the efficacy of immunosuppression in myocarditis or inflammatory cardiomyopathy. `;
    case 1:
      return "LVEF at 12 – months.";
    case 2:
      return (
          <ul>
            <li>Proportion of patients who responded to immunosuppressive therapy as defined by an LVEF increase of ≥ 10% over time.</li>
            <li>LVEF at 12 months in subgroups of patients with baseline LVEF ≤ 30% and {'>'} 30%.</li>
            <li>Change in the LV end-systolic and end-diastolic dimensions as well as the LV end-systolic and end-diastolic volumes over time.</li>
            <li>Change from baseline in NYHA class over time.</li>
            <li>Occurrence of adjudicated heart failure decompensation (hospitalization or ambulatory visit).</li>
          </ul>
      );
    default:
      return "Unknown step";
  }
}

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <StyledStepperWrapper>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <StyledActionsContainer>
                <div>
                  <StyledButton
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="contained"
                  >
                    BACK
                  </StyledButton>
                  {activeStep < steps.length - 1 && (
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      NEXT
                    </StyledButton>
                  )}
                </div>
              </StyledActionsContainer>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </StyledStepperWrapper>
  );
}
