import styled from "styled-components";

export const StyledSText1 = styled.div`
  text-align: center;
  font-size: 23px;
  font-weight: bold;

  @media (max-width: 1100px) {
    font-size: 16px;
  }
`;

export const StyledSText2 = styled.div`
  text-align: center;
  font-size: 16px;
  color: #7a7a7a;

  @media (max-width: 1100px) {
    font-size: 14px;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "header stats" "header date";
  max-width: 700px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-areas: "header" "stats" "date";
    place-items: center;
  }
`;

export const StyledHeaderTitle = styled.div`
  font-size: 50px;
  color: #c4c4c4;
  font-weight: bold;
  grid-area: header;
  margin-right: 20px;

  @media (max-width: 1100px) {
    text-align: center;
  }
`;

export const StyledHeaderSubtitle = styled.div`
  font-size: 23px;
  font-weight: bold;
  grid-area: stats;
`;

export const StyledHeaderDate = styled.div`
  font-size: 18px;
  font-weight: bold;
  grid-area: date;
  color: #7a7a7a;
`;

export const StyledGlobalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 55px;
  gap: 65px;
  width: 100%;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledGlobalMedicalCentersContainer = styled.div`
  margin-top: 55px;
`;

export const StyledGlobalMedicalCentersFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const StyledGlobalMedicalCentersFiltersButton = styled.div<any>`
  display: flex;
  padding: 8px 30px;
  gap: 12px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  cursor: ${(props) => (props.status ? 'pointer' : 'not-allowed')};
  transition: 0.3s ease;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  opacity: ${(props) => (props.status ? '100%' : '30%')};
  min-height: 24px;

  &:hover {
    background-color: ${(props) => (props.status ? '#FAFAFA' : '')};
    border: ${(props) => (props.status ? '1px solid black' : '')};
  }
`;

export const StyledGlobalMedicalCentersFiltersInput = styled.input<any>`
  display: flex;
  padding: 8px 20px;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  cursor: text;
  transition: 0.3s ease;
  align-items: center;
  width: 60%;
  background: none;
  white-space: nowrap;
  min-height: 24px;
  font-size: 12px;

  &:hover {
    background-color: #FAFAFA;
  }
`;

export const StyledGlobalStatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    gap: 5px;
  }
`;

export const StyledGlobalMedicalStatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 45px 30px;
  border: 2px solid #e7e7e7;
  border-radius: 10px;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    gap: 5px;
  }
`;

export const StyledStatsTitle = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  align-items: center;
`;

export const StyledCenterTitle = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 55px;
  margin-bottom: -17px;
  background: #FFFFFF;
  z-index: 999;
  padding: 0px 25px;
  width: 50%;
`;

export const StyledCenterTitleText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #7a7a7a;
  display: flex;
`;

export const StyledStatsTitleText = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const StyledStatsTitleNumber = styled.div`
  font-size: 26px;
  font-weight: bold;
  display: flex;
`;

export const StyledStatsDotPurple = styled.div`
  background-color: #9e39e2;
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

export const StyledStatsDotGreen = styled.div`
  background-color: #22a5a8;
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

export const StyledStatsDotYellow = styled.div`
  background-color: #ffc530;
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

export const StyledStatsTab = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  padding: 15px 30px;
  border: 2px solid #e7e7e7;
  border-radius: 10px;

  @media (max-width: 1100px) {
    padding: 10px 5px;
  }
`;

/** Filters */
