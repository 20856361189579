import Typography from "@mui/material/Typography";
import { ROUTES_MEDICAL_CENTERS } from "commons/constants/paths";
import { useAppDispatch } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import MedicalCenterForm from "features/medicalCenters/components/MedicalCenterForm";
import { addMedicalCenter } from "features/medicalCenters/redux/medicalCentersSlice";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import yup from "commons/yup";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import Box from "@mui/material/Box";
import { InitialValuesState } from "features/medicalCenters/components/MedicalCenterForm/MedicalCenterForm";
import {useTranslation} from "react-i18next";

const validationSchema = yup.object({
  name: yup.string().required(),
  address: yup.object({
    street: yup.string().required(),
    number: yup.string().required(),
    city: yup.string().required(),
    postcode: yup.string().required(),
    country: yup.string().required(),
  }),
  longitude: yup.number().required(),
  latitude: yup.number().required(),
  number: yup.string().required(),
});

const MedicalCenterAdd = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const initialValues = {
    name: "",
    address: {
      street: "",
      number: "",
      city: "",
      postcode: "",
      country: "",
    },
    longitude: "",
    latitude: "",
    number: "",
  };

  const handleSubmit = async (values: InitialValuesState) => {
    try {
      await dispatch(addMedicalCenter(values));
      enqueueSnackbar(t('a-new-research-facility-has-been-added'), {
        variant: 'success',
      });
      navigate(ROUTES_MEDICAL_CENTERS.LIST);
    } catch (error: any) {
      enqueueSnackbar(error?.detail, {variant: 'error'});
    }
  };

  return (
    <DashboardLayoutWrapper centerChildrenVertically>
      <ColoredBox p={1} width={352} maxWidth="100%" ml="auto" mr="auto">
        <Box mb={3.5}>
          <Typography variant="h5">{t('new-medical-center')}</Typography>
        </Box>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <MedicalCenterForm />
        </Formik>
      </ColoredBox>
    </DashboardLayoutWrapper>
  );
};

export default MedicalCenterAdd;
