import styled from "styled-components";
import Button from "@mui/material/Button";

export const StyledStepperWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledActionsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
