import Box from "@mui/material/Box";
import Button, {ButtonProps} from "@mui/material/Button";
import { useField } from "formik";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import {
    ButtonOptions,
    EcrfFormParams, fetchEcrf,
    fetchEcrfElements,
    putElementData,
    selectEcrf
} from "features/ecrf/redux/ecrfSlice";
import ConfirmationAction from "components/ConfirmationAction";
import { useParams } from "react-router-dom";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { API_URL } from "commons/constants/env";
import Ecrf from "../../core/helpers/Ecrf";
import {useTranslation} from "react-i18next";

export interface ConfirmButtonTypeFormikProps
    extends Omit<ButtonProps, "name" | "value" | "onChange" | "onBlur" | "error"> {
    name: string;
    buttonText?: string;
    options: ButtonOptions;
    ecrfId: string;
    data: any;
    element: any;
    elementData: any;
    status: boolean;
}

const ConfirmButtonTypeFormik = ({
    name,
    options,
    ecrfId,
    data,
    element,
    elementData,
    status,
    ...props
}: ConfirmButtonTypeFormikProps) => {
    const dispatch = useAppDispatch();
    const ecrf     = useAppSelector(selectEcrf);
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();

    const [field, meta] = useField(name);
    const [isDisabled, setIsDisabled] = useState(false);
    const [text, setText] = useState(
        (status ? options.buttonDisabledText : options.buttonText) || element.title || ''
    );
    const {id, section} = useParams<EcrfFormParams>();
    const [isProcessing, setIsProcessing] = useState(false);

    /** handle modal options */
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Change button state when action is already done
     */
    const updateButtonStatus = () => {
        let condition: boolean = false;

        if (options.disabledCondition === 'ecrfStatus') {
            condition = Ecrf.statusClosed(ecrf?.status);
        } else if (options.disabledCondition === 'elementStatus' && status) {
            condition = true;
        } else if (options.disabledCondition === 'randomization' && ecrf?.randomId) {
            condition = true;
        }

        if (condition) {
            setIsDisabled(true);
            setText((options.buttonDisabledText ?? options.buttonText) || '');
        }

        switch (elementData?.status) {
            case 5:
                if (options?.confirmDisabledText) {
                    setIsDisabled(false);
                }
                setText(options.buttonDisabledText);
                break;
            default:
                setText(options.buttonText);
        }
    };

    const getButtonType = () => {
        if (!options.type) return;

        if (options.type === 'pdf') return `${API_URL}/api/ecrves/${id}/${name}/pdf`;
    };

    /**
     * Send data using button instead formik
     */
    const send = async () => {
        setIsProcessing(true);

        try {
            await dispatch(putElementData({
                data: options?.elementData ? [options?.elementData] : [], elementId: name, ecrfId
            }));
            await dispatch(fetchEcrfElements({id, sectionId: section}));
            await dispatch(fetchEcrf(id || ''));

            setOpen(true);
        } catch (error: any) {
            enqueueSnackbar(error?.detail, {variant: 'error'});
        }

        setIsProcessing(false);
    };

    useEffect(() => {
        updateButtonStatus();
    }, [ecrf, elementData, dispatch]);

    return (
        <Box pt={0}>
            {options.description && (
                <Box mb={2}>
                    <Typography variant="body2">{options.description}</Typography>
                </Box>
            )}

            <ConfirmationAction
                confirmationTitle={(status ? options.confirmDisabledText : options.confirmText) || options?.confirmText}
                onClick={() => send()}
                isProcessing={isProcessing}
                confirmationContent={getButtonType()}
            >
                {({handleClick}) => (
                    <Button
                        name={field.name}
                        id={field.name}
                        value={field.value}
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        disabled={isDisabled}
                        {...props}
                    >
                        {text}
                    </Button>
                )}
            </ConfirmationAction>

            <Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Box color="black" fontWeight="bold" fontSize="20px">
                                {options.alertText || ''}
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>
                            {t('ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default ConfirmButtonTypeFormik;
