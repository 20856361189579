import styled from 'styled-components';

export const TableWrapper = styled.div`
    tbody tr td {
        height: 49px;
        padding-left: 20px;
        padding-right: 20px;
        transition: .2s ease;
    }
`;
