import React, {Component} from 'react';
import {
    StyledUploaderFilesItem,
    StyledUploaderFilesItemSize,
    StyledUploaderFilesItemText
} from "./UploadFileFormik.styled";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {CircularProgress, Tooltip} from "@mui/material";
import axiosSecureInstance from "../../../commons/axios/axiosSecureInstance";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {useAppSelector} from "../../../commons/store/hooks";
import {useTranslation} from "react-i18next";
import {selectUserProfile} from "../../../core/redux/userSlice";
import FileHelper from "./FileHelper";

interface IFileItem {
    name: string,
    size: string,
    link?: string,
    id: string,
    userProfile?: any;
    isUploading?: boolean,
    removeRow?: (id: string) => void;
    update?: (id: string) => void;
    dataId?: string;
    t?: any;
}

const withRouter = (WrappedComponent: any) => (props: any) => {
    const userProfile = useAppSelector(selectUserProfile);
    const { t } = useTranslation();

    return (
        <WrappedComponent
            {...props}
            userProfile={userProfile}
            t={t}
        />
    );
};

class FileItem extends Component<IFileItem, any> {
    /**
     * This method is used to convert file size to human units
     * @param bytes
     * @param decimals
     */
    public static formatBytes(bytes: any, decimals = 2) {

        if (bytes === null) return '';

        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    private async deleteFile(id: string) {
        if (this.props.userProfile.role === 'ROLE_ADMIN') {
            await axiosSecureInstance.delete(`/api/element_resource_transfers/${id}`)
            if (this.props.update) this.props.update(this.props.dataId || '-')
        }
    }

    render() {
        return (
            <StyledUploaderFilesItem isAdmin={this.props.userProfile.role === 'ROLE_ADMIN'}>
                <InsertDriveFileOutlinedIcon/>

                <Tooltip title={this.props.name} arrow>
                    <StyledUploaderFilesItemText>
                        {this.props?.name || this.props?.t('name')}
                    </StyledUploaderFilesItemText>
                </Tooltip>

                <StyledUploaderFilesItemSize>
                    {FileHelper.formatBytes(this.props?.size) || this.props?.t('size')}
                </StyledUploaderFilesItemSize>

                {this.props.isUploading && (
                    <CircularProgress size={22}/>
                )}

                {this.props.link && this.props.userProfile.role === "ROLE_ADMIN" && (
                    <Tooltip title={this.props?.t('delete-file')} arrow>
                        <StyledUploaderFilesItemSize onClick={() => this.deleteFile(this.props.id)}
                            style={{cursor: 'pointer'}}>
                            <DeleteRoundedIcon style={{fontSize: '15px'}}/>
                        </StyledUploaderFilesItemSize>
                    </Tooltip>
                )}
            </StyledUploaderFilesItem>
        );
    }
}

export default withRouter(FileItem)