import styled from "styled-components";

export const Page404Styled = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Page404Content = styled.div`
  flex: 1;
  max-width: 1660px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  max-width: 700px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const Content = styled.div`
  margin-left: 80px;
`;

export const Title = styled.span`
  font-size: 48px;
  display: block;
  color: #000;
`;
export const Subtitle = styled.span`
  font-size: 34px;
  color: rgba(0, 0, 0, 0.5);
  display: block;
  margin-bottom: 34px;
`;

export const Action = styled.button`
  border: 0;
  outline: none;
  background: none;
  text-decoration: underline;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
`;
