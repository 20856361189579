import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";

interface File {
  file: any;
  history: any;
  queries: any;
}

const initialState: File = {
  file: '',
  history: [],
  queries: [],
};

export const fetchFile = createAsyncThunk(
  'file',async (id: string, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.get<any>(`/api/files/${id}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export interface PutFileData {
    id: string;
    status?: number;
    comments: Array<any>;
}

export const putFile = createAsyncThunk(
  'file',async (data: PutFileData, thunkAPI) => {
    try {
      // const response = await axiosSecureInstance.put<any>(`/api/files/${data?.id}`, data);
      const response = await axiosSecureInstance.put<any>(`/api/files/${data?.id}`, {
          comments: data.comments
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchFileHistory = createAsyncThunk(
  'file/history',async (id: string, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.get<any>(`/api/files/${id}/histories`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchFileQueries = createAsyncThunk(
  'file/queries',async (id: string, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.get<any>(`/api/files/${id}/comments`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
      resetFileHistory(state) {
          state.history = initialState.history
      },
      resetFileQueries(state) {
          state.queries = initialState.queries
      }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFile.fulfilled, (state, action) => {
      state.file = action.payload;
    });
    builder.addCase(fetchFileHistory.fulfilled, (state, action) => {
      state.history = action.payload;
    });
    builder.addCase(fetchFileQueries.fulfilled, (state, action) => {
      state.queries = action.payload;
    });
  },
});

export const selectFile        = (state: RootState) => state.file.file.file;
export const selectFileHistory = (state: RootState) => state.file.file.history;
export const selectFileQueries = (state: RootState) => state.file.file.queries;

export const {
    resetFileHistory,
    resetFileQueries
} = fileSlice.actions;

export default fileSlice.reducer;
