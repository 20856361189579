/*
 * Project: ecrf-front.
 * Author: Levan Ostrowski
 * User: cod3venom
 * Date: 15.01.2022
 * Time: 09:28
 */

import { AxiosInstance } from 'axios';
import DocUploader, { IDocUploaderProps, IDocUploaderResponse } from './DocUploader';
import { API_URL, RESEARCH } from '../../../../../commons/constants/env';

export interface IDocManagerData {
    id: string;
    name: string;
    extension: string;
    category: string;
    version: string;
    contentUrl: string;
    size: string;
    status: number;
    statusName?: string;
    isSoftDeleted: boolean;
    isPublic: boolean;
    createdAt: string;
    type: number;
    variant?: string;
    fetchHistory?: any;
    fetchQueries?: any;
    onHistoryModal: (file?: any) => void;
    onQueryPanel: (file?: any) => void;
    userProfile?: any;
    changeData?: any;
    t?: any;
}

export interface IDocPutData {
    id: string;
    status: number;
    statusName?: string;
}

export interface IDocResponse {
    id: string;
}

export default class DocManager {
    /**
     * HttpClient instance
     * @protected
     */
    private httpClient: AxiosInstance;

    private readonly docUploader: DocUploader;

    /**
     * @param httpClient
     * @protected
     */
    constructor(httpClient: AxiosInstance) {
        this.httpClient = httpClient;
        this.docUploader = new DocUploader(httpClient);
    }

    /**
     * Returns collection of all available document Objects.
     * @return Promise<IDocumentManagerData[]>
     */
    public async all(): Promise<any[]> {
        try {
            const response = await this.httpClient.get('/api/file_categories');
            return [...response.data] as any[];
        } catch (e: any) {
            alert(e.response?.data?.detail);
            return [];
        }
    }

    /**
     * This method is used fetch filtered files
     * @param id
     */
    public async allWithFilters(id: string): Promise<any[]> {
        try {
            let docs = [];
            if (RESEARCH === 'IMPROVE') {
                const response = await this.httpClient.get(`/api/ecrves/files`, {
                    params: {
                        medicalCenter: id,
                    },
                });

                for (const item of response.data) {
                    const data = {
                        name: item?.screeningNumber,
                        files: item?.files ?? [],
                        children: item?.children ?? [],
                        ...item,
                    };
                    docs.push(data);
                }
            } else {
                const response = await this.httpClient.get(`/api/file_categories`, {
                    params: {
                        'files.medicalCenters': id,
                    },
                });
                docs = response.data;
            }

            return [...docs] as any[];
        } catch (e: any) {
            alert(e.response?.data?.detail);
            return [];
        }
    }

    /**
     * Fetch file history
     * @param id
     */
    public async history(id: string): Promise<any[]> {
        try {
            const response = await this.httpClient.get(`/api/files/${id}/histories`);
            return [...response.data] as any[];
        } catch (e: any) {
            alert(e.response?.data?.detail);
            return [];
        }
    }

    /**
     * Returns list of medical centers
     */
    public async centersList(): Promise<any[]> {
        try {
            const response = await this.httpClient.get('/api/medical_centers');
            return [...response.data] as any[];
        } catch (e: any) {
            alert(e.response?.data?.detail);
            return [];
        }
    }

    public async userCentersList(): Promise<any[]> {
        try {
            const response = await this.httpClient.get(`/api/me/medical_centers`);
            return [...response.data] as any[];
        } catch (e: any) {
            return [];
        }
    }
    /*
    public async category(id: string): Promise<any> {
        try {
            const response = await this.httpClient.get(`/api/file_categories/${id}`);
            return response.data
        } catch (e: any) {
            alert(e.response?.data?.detail)
            return undefined
        }
    }
*/
    /**
     * Returns list of participants
     */
    public async participants(): Promise<any[]> {
        try {
            const response = await this.httpClient.get('/api/ecrves/files');
            return [...response.data] as any[];
        } catch (e: any) {
            alert(e.response?.data?.detail);
            return [];
        }
    }

    /**
     * Returns list of file categories
     */
    public async categoriesList(): Promise<any[]> {
        try {
            const response = await this.httpClient.get('/api/file_categories');
            return [...response.data] as any[];
        } catch (e: any) {
            alert(e.response?.data?.detail);
            return [];
        }
    }

    public async categoryFiles(categoryId: string | null, medicalCenter: string): Promise<any[]> {
        try {
            const params = new URLSearchParams({
                category: categoryId as string,
            });
            if (medicalCenter) {
                params.append('medicalCenters', medicalCenter);
            }
            const response = await this.httpClient.get(`/api/files?` + params.toString());
            return [...response.data] as any[];
        } catch (e: any) {
            alert(e.response?.data?.detail);
            return [];
        }
    }

    public async ecrfFiles(id: string | null, medicalCenter: string): Promise<any[]> {
        try {
            const params = new URLSearchParams({
                ecrf: id as string,
            });
            if (medicalCenter) {
                params.append('medicalCenters', medicalCenter);
            }
            const response = await this.httpClient.get(`/api/files?` + params.toString());
            return [...response.data] as any[];
        } catch (e: any) {
            alert(e.response?.data?.detail);
            return [];
        }
    }

    /**
     * Returns collection of only public documents.
     * @return Promise<IDocumentManagerData[]>
     */
    public async allPublic(): Promise<IDocManagerData[]> {
        try {
            const response = await this.httpClient.get('api/files?type=0');
            return [...response.data] as IDocManagerData[];
        } catch (e: any) {
            alert(e.response?.data?.detail);
            return [];
        }
    }

    /**
     * Returns collection of only private documents.
     * @return Promise<IDocumentManagerData[]>
     */
    public async allPrivate(): Promise<IDocManagerData[]> {
        const data = await this.all();

        data.map((item: IDocManagerData, idx: number) => {
            // API_URL should be appended as host address.
            item.contentUrl = `${API_URL}/${item.contentUrl}`;
            if (!item.isPublic) {
                return item;
            }
        });
        return data;
    }

    /**
     * Current method is used to upload document file with its attributes.
     * @param data
     */
    public async upload(data: IDocUploaderProps): Promise<IDocUploaderResponse> {
        return await this.docUploader.upload(data);
    }

    public async put(data: IDocPutData): Promise<IDocResponse> {
        try {
            const response = await this.httpClient.put(`/api/files/${data?.id}`, data);
            return { ...response.data } as IDocResponse;
        } catch (error: any) {
            const { response } = error;
            const { request, ...errorObject } = response;
            alert(errorObject?.data?.detail);

            return errorObject;
        }
    }

    public async download(doc: IDocManagerData) {
        window.open(API_URL + doc.contentUrl, '_blank');
    }

    /**
     * @SoftDelete method is used to change @isDeleted from false to true.
     * @param id
     */
    public async softDelete(id: string) {
        try {
            await this.httpClient.delete(`/api/files/${id}`);
        } catch (error: any) {}
    }
}
