import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";

interface ElementDataCollection {
    history: any;
}

const initialState: ElementDataCollection = {
    history: {},
};

export const fetchElementDataCollectionHistory = createAsyncThunk(
    'ecrf/fetchElementDataCollectionHistory',
    async (id: string, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get(
                `/api/element_data_collections/${id}/histories`
            );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const elementDataCollectionSlice = createSlice({
  name: 'elementDataCollection',
  initialState,
  reducers: {
      resetElementDataCollectionHistory(state) {
          state.history = initialState.history;
      },
  },
  extraReducers: (builder) => {
      builder.addCase(fetchElementDataCollectionHistory.fulfilled, (state, action) => {
          state.history = action.payload;
      });
  },
});

export const selectElementDataCollectionHistory = (state: RootState) => state.ecrf.elementDataCollectionSlice.history;

export const {
    resetElementDataCollectionHistory,
} = elementDataCollectionSlice.actions;

export default elementDataCollectionSlice.reducer;
