import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { RootState } from "commons/store/store";

export interface NavigationsState {
    navigations: any
}

const initialState: NavigationsState = {
    navigations: []
};

type FetchNavigationsParams = {
    pagination: boolean
    page: number
    itemsPerPage: number
    location: number
};

export const fetchNavigations = createAsyncThunk(
    'navigations/fetchNavigations',
    async (params: FetchNavigationsParams) => {
        const response = await axiosSecureInstance.get<any[]>(`/api/navigations`, { params: params });
        return response.data;
    }
);

export const navigationSlice = createSlice({
    name: 'navigations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchNavigations.fulfilled, (state: NavigationsState, action) => {
            state.navigations = action.payload;
        });
    },
});

export const selectNavigations = (state: RootState) => state.page.navigation.navigations;

export default navigationSlice.reducer;
