/*
 * Project: ecrf-front.
 * Author: Levan Ostrowski
 * User: cod3venom
 * Date: 05.12.2021
 * Time: 14:57
*/

import Store from "./core/Store";

export default class AbstractResource {
    protected store: Store;

    constructor() {
        this.store = new Store();
    }
}
