import TextFieldFormik from "components/Form/TextFieldFormik";
import { Box, Button} from "@mui/material";
import React from "react";
import { Form } from "formik";
import { useTheme } from "@mui/material/styles";

export interface InitialValuesState {
  name: string;
}

const DrugForm = () => {
  const theme = useTheme();

  return (
    <Form>
      <Box>
        <TextFieldFormik
          margin="dense"
          name="name"
          label="Nazwa"
          variant="filled"
          fullWidth
        />
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Box boxShadow={theme.shadows[4]}>
          <Button type="submit" color="primary" variant="contained">
            Zapisz
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default DrugForm;
