import Footer from "components/Footer/Empathy";
import {
    Page404Styled,
    Page404Content,
    ImageWrapper,
    Content,
    Title,
    Subtitle,
    Action,
} from "./Page404.styled";
import { useNavigate } from "react-router-dom";
import Icon404 from "commons/assets/Icon404.png";
import {useTranslation} from "react-i18next";

const Page404 = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Page404Styled>
            <Page404Content>
                <ImageWrapper>
                    <img src={Icon404} alt="404"/>
                </ImageWrapper>
                <Content>
                    <Title>{t('error')} 404</Title>
                    <Subtitle>{t('page-not-found')}</Subtitle>
                    <div>
                        <Action onClick={() => navigate(-1)}>
                            {t('go-back-to-the-previous-page')}
                        </Action>
                    </div>
                </Content>
            </Page404Content>
            <Footer></Footer>
        </Page404Styled>
    );
};

export default Page404;
