import Box from "@mui/material/Box";
import Slider, { SliderProps } from "@mui/material/Slider";
import { useField } from "formik";

export interface SliderFormikProps
  extends Omit<SliderProps, "name" | "onChange" | "onBlur" | "error"> {
  name: string;
  status?: boolean;
}

const SliderFormik = ({ name, status, ...props }: SliderFormikProps) => {
  const [field, , fieldHelpers] = useField(name);

  return (
    <Box pt={3}>
      <Slider
        valueLabelDisplay="on"
        name={field.name}
        id={field.name}
        value={field.value || 0}
        disabled={status}
        onChange={(event, value) => fieldHelpers.setValue(value)}
        {...props}
      />
    </Box>
  );
};

export default SliderFormik;
