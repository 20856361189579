import React from 'react';
import DrugsManagementImprove from "./Improve/DrugsManagementImprove";
import DrugsManagement from "./DrugsManagement";
import { RESEARCH } from "commons/constants/env";

const DrugsManagementSwitcher = () => {
    const renderVariant = () => {
        switch (RESEARCH) {
            case 'IMPROVE':
                return <DrugsManagementImprove/>
            case 'EMPATHY':
            case 'IDOLE':
            default:
                return <DrugsManagement/>
        }
    }

    return renderVariant()
}

export default DrugsManagementSwitcher;