import React, { useEffect, useState } from 'react';
import { MenuItem, TextField, Tooltip } from "@mui/material";
import { Filter } from "../Filter";
import { fetchVisits } from "../../../ecrf/redux/ecrfSlice";
import { useAppDispatch } from "../../../../commons/store/hooks";
import { useTranslation } from "react-i18next";

export interface FilterPanelProps {}

export const FilterPanel = (props: FilterPanelProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    /**
     * This function is used to fetch visits
     */
    const [visits, setVisits] = useState<any[]>([]);
    const fetchVisitList = async () => {
        const visits = await dispatch(fetchVisits()).unwrap();
        setVisits(visits);
    }

    useEffect(() => {
        fetchVisitList().then()
    }, [dispatch]);

    const filters: any[] = [
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('randomization-number')} arrow>
                    <TextField
                        label={t('randomization-number')}
                        value={filter?.['ecrf.randomId'] || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'ecrf.randomId')
                        }
                        fullWidth
                        variant={'outlined'}
                        size={'small'}
                    />
                </Tooltip>
            ),
            key: 'ecrf.randomId',
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('select-visit')} arrow>
                    <TextField
                        select
                        fullWidth
                        variant={'outlined'}
                        label={t('visit')}
                        size={'small'}
                        value={filter?.['element.section.visit'] || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'element.section.visit')
                        }
                    >
                        {/** Display list of all numbers */}
                        {visits.map((value: any) => (
                            <MenuItem key={value?.id} value={value?.id}>
                                {value?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip>
            ),
            key: 'element.section.visit',
        },
    ];

    return <Filter elements={filters} />;
};
