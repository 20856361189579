/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 07.03.2022
 * Time: 13:38
*/

import React, {Component} from 'react';
import {
    StyledUploaderFilesItem,
    StyledUploaderFilesItemSize,
    StyledUploaderFilesItemText
} from "./S3UploaderFormik.styled";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {CircularProgress, Tooltip} from "@mui/material";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ConfirmationAction from 'components/ConfirmationAction/ConfirmationAction';
import GetAppIcon from '@mui/icons-material/GetApp';
import {API_URL} from 'commons/constants/env';
import axiosSecureInstance from "../../../commons/axios/axiosSecureInstance";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { roles } from 'commons/auth/roles';
import {useAppSelector} from "../../../commons/store/hooks";
import {selectUserProfile} from "../../../core/redux/userSlice";
import {useTranslation} from "react-i18next";
import FileHelper from "../File/FileHelper";

interface IFileItem {
    name: string,
    size: string,
    link?: string,
    id: string,
    userProfile?: any;
    isUploading?: boolean,
    removeRow?: (id: string) => void;
    update?: (id: string) => void;
    dataId?: string;
    t?: any;
}

const withRouter = (WrappedComponent: any) => (props: any) => {
    const userProfile = useAppSelector(selectUserProfile);
    const { t } = useTranslation();

    return (
        <WrappedComponent
            {...props}
            userProfile={userProfile}
            t={t}
        />
    );
};

class FileItem extends Component<IFileItem, any> {

    state = {
        isDataDeleting: false,
        isResearcher: this.props.userProfile.roles.some(
            (role: string) => roles.admin.includes(role) || roles.researcher.includes(role)
        ),
    }

    /**
     * This method is used to download file from list
     * @param id
     * @private
     */
    private static downloadFile(id: string) {
        window.open(`${API_URL}/api/element_resource_transfers/${id}/download`);
    }

    /**
     * This method deleting the file from list
     * @param id
     * @private
     */
    private async deleteFile(id: string) {
        this.setState({isDataDeleting: true});

        if (this.state.isResearcher) {
            await axiosSecureInstance.delete(`/api/element_resource_transfers/${id}`);
            if (this.props.update) this.props.update(this.props.dataId || '-');
        }

        this.setState({isDataDeleting: false});
    }

    render() {
        return (
            <StyledUploaderFilesItem isAdmin={this.props.userProfile.role === 'ROLE_ADMIN'}>
                <InsertDriveFileOutlinedIcon/>

                <Tooltip title={this.props.name} arrow>
                    <StyledUploaderFilesItemText>
                        {this.props?.name || this.props?.t('name')}
                    </StyledUploaderFilesItemText>
                </Tooltip>

                <StyledUploaderFilesItemSize>
                    {FileHelper.formatBytes(this.props?.size) || this.props?.t('size')}
                </StyledUploaderFilesItemSize>

                {/* <--- Remove file from selected list ---> */}
                {!this.props.isUploading && !this.props.link && this.props.size && (
                    <Tooltip title={this.props?.t('remove-from-list')} arrow>
                        <StyledUploaderFilesItemSize onClick={() => {
                            if (this.props.removeRow) {
                                this.props.removeRow(this.props.name)
                            }
                        }}
                            style={{cursor: 'pointer'}}>
                            <ClearRoundedIcon style={{fontSize: '15px'}}/>
                        </StyledUploaderFilesItemSize>
                    </Tooltip>
                )}

                {/* <--- Download file from transfers list ---> */}
                {this.props.link && (
                    <Tooltip title={this.props?.t('download-file')} arrow>
                        <StyledUploaderFilesItemSize onClick={() => FileItem.downloadFile(this.props.id)}
                            style={{cursor: 'pointer'}}>
                            <GetAppIcon style={{fontSize: '15px'}}/>
                        </StyledUploaderFilesItemSize>
                    </Tooltip>
                )}

                {this.props.isUploading && (
                    <CircularProgress size={22}/>
                )}

                {this.props.link && this.state.isResearcher && (
                    <ConfirmationAction
                        confirmationTitle={this.props?.t('are-you-sure-you-want-to-delete-this-file?')}
                        onClick={() => this.deleteFile(this.props.id)}
                        isProcessing={this.state.isDataDeleting}
                    >
                        {({handleClick}) => (
                            <Tooltip title={this.props?.t('delete-file')} arrow>
                                <StyledUploaderFilesItemSize
                                    onClick={handleClick}
                                    style={{cursor: 'pointer'}}
                                    disabled={this.state.isDataDeleting}
                                >
                                    <DeleteRoundedIcon style={{fontSize: '15px'}}/>
                                </StyledUploaderFilesItemSize>
                            </Tooltip>
                        )}
                    </ConfirmationAction>
                )}
            </StyledUploaderFilesItem>
        );
    }
}

export default withRouter(FileItem)