import React, { useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { StyledDrugsSelect } from '../views/DrugsManagement.styled';
import { Data } from './RowItem';

interface Props {
    id: number;
    setRowData: (rowData: Data) => void;
    rowData: any;
    actualNumber?: string;
    centersList: any[],
    readonly?: boolean
}

export const SelectNumber: React.FC<Props> = ({id, setRowData, rowData, readonly, centersList, actualNumber}) => {
    /** Open/close menu list status */
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    /** Actual selected number */
    const [selectedNumber, setSelectedNumber] = useState(actualNumber || '-');

    /** Edit row edit-data */
    const handleChangeNumber = async (e: any) => {
        setSelectedNumber(e.target.value?.number);
        const newData: any = Object.assign({}, rowData, {medicalCenter: `/api/medical_centers/${e.target.value?.id}`});
        setRowData(newData);
    };

    return (
        <>
            <StyledDrugsSelect readonly={readonly} id={id} onClick={() => {
                if (!readonly) setIsMenuOpen(true)
            }}>
                {selectedNumber}
            </StyledDrugsSelect>

            <Select
                value={selectedNumber}
                disabled={readonly}
                open={isMenuOpen}
                style={{display: 'none'}}
                onClose={() => setIsMenuOpen(false)}
                MenuProps={{anchorEl: document.getElementById(`${id}`)}}
                onChange={(e: any) => handleChangeNumber(e)}>
                {/** Display list of all numbers */}
                {centersList.map((value: any) => (
                    <MenuItem key={value?.id} value={value}>
                        {value?.number}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};
