import TextField, {TextFieldProps} from "@mui/material/TextField";
import { useField } from "formik";
import React from "react";

export interface TextAreaFormikProps
  extends Omit<TextFieldProps, "name" | "value" | "onChange" | "onBlur" | "error"> {
  name: string;
}

const TextAreaFormik = ({
  name,
  helperText,
  rows=4,
  ...props
}: TextAreaFormikProps) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      multiline={true}
      rows={rows}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={(meta.touched && meta.error) || helperText}
      {...props}
    />
  );
};

export default TextAreaFormik;