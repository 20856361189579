import { useField } from "formik";
import Select, { SelectProps } from "@mui/material/Select";
import { Checkbox, FormHelperText, ListItemText, MenuItem} from "@mui/material";
import { isArray } from "lodash";

export interface SelectMultipleFormikProps
  extends Omit<SelectProps, "name" | "value" | "onChange" | "onBlur" | "error"> {
  name: string;
  helperText?: string;
  options: Array<{ key: string; value: string }>;
  status?: boolean;
}

const SelectMultipleFormik = ({
  name,
  helperText,
  options,
  status,
  ...props
}: SelectMultipleFormikProps) => {
  const [field, meta, form] = useField(name);

  return (
    <>
      <Select
        name={field.name}
        value={isArray(field.value) ? field.value : [field.value]}
        disabled={status}
        onChange={(event: any) => {
          form.setValue(event.target.value as string[]);
        }}
        onBlur={field.onBlur}
        error={meta.touched && Boolean(meta.error)}
        multiple
        {...props}
        renderValue={(selected) =>
          (selected as string[])
            .map(
              (value) => options.find((option) => option.value === value)?.key
            )
            .join(", ")
        }
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox
              checked={field.value && field.value.indexOf(option.value) > -1}
            />
            <ListItemText primary={option.key} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {(meta.touched && meta.error) || helperText}
      </FormHelperText>
    </>
  );
};

export default SelectMultipleFormik;
