import {
  GoogleMap as ReactGoogleMap,
  GoogleMapProps as ReactGoogleMapProps,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { memo } from "react";
import { GOOGLE_MAP_KEY } from "commons/constants/env";
import CircularProgress from "@mui/material/CircularProgress";

export interface GoogleMapProps extends Omit<ReactGoogleMapProps, "center"> {
  lat: number;
  lng: number;
  isMarkerShown?: boolean;
}

function GoogleMap({
  lat,
  lng,
  zoom = 15,
  isMarkerShown = true,
  mapContainerStyle = { width: "100%", height: "400px" },
  ...rest
}: GoogleMapProps) {
  return (
    <LoadScript
      googleMapsApiKey={`${GOOGLE_MAP_KEY}`}
      loadingElement={<CircularProgress />}
    >
      <ReactGoogleMap
        mapContainerStyle={mapContainerStyle}
        center={{
          lat,
          lng,
        }}
        zoom={zoom}
        {...rest}
      >
        {isMarkerShown && (
          <Marker
            position={{
              lat,
              lng,
            }}
          />
        )}
      </ReactGoogleMap>
    </LoadScript>
  );
}

export default memo(GoogleMap);
