const getValueByType = (type: string) => {
  switch (type) {
    case 'association':
    case 'choice':
    case 'number':
    case 'radio':
    case 'select':
    case 'text':
    case 'textarea': {
      return '';
    }
    case 'autocomplete':
    case 'checkbox_multiple':
    case 'dicom_button':
    case 'select_multiple': {
      return [];
    }
    case 'range': {
      return 5;
    }
    case 'checkbox': {
      return false;
    }
    case 'date':
    case 'datetime':
    case 'time':
    default:
      return undefined;
  }
};

const getValueByElementData = (
  // TODO swagger model for element data
  data: Array<any> | undefined | null,
  type: string
) => {
  if (!data) {
    return getValueByType(type);
  }

  if (['checkbox_multiple', 'select_multiple'].includes(type)) {
    return data;
  }

  return data[0];
};

const getInitialValues = (
  // TODO swagger model for element
  elements: Array<any>,
  // TODO swagger model for element data
  data: Array<any>,
  multi: boolean = false,
) => {
  const el = elements.slice();
  elements.forEach((element)=>{
    if (element.type === 'collection' || element.type === 'adverse_event') {
      element.children.forEach((child: any) => {
        el.push(child);
      });
    }
  })

  return el.reduce((initialValues, element, index) => {
    if (element.type === 'divider') {
      return initialValues;
    }

    const elementData = (multi) ?
      data.findLast(
          (data, i) =>
              (element?.id === data.element?.id && i === data.element?.position) ||
              i === data.element?.position ||
              i === index
      ) :
      data.findLast(
          (data, i) => data?.element?.id === element.id
      );

    initialValues = {
      ...initialValues,
      [(multi) ? elementData?.element?.id : element.id]: elementData
        ? getValueByElementData(elementData.data, (multi) ? elementData?.element?.type : element.type)
        : getValueByType((multi) ? elementData?.element?.type : element.type),
    };
    return initialValues;
  }, {});
};

export default getInitialValues;