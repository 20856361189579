import React, { useEffect, useState } from 'react';
import { Checkbox } from "@mui/material";
import DateTime from 'core/helpers/date/DateTime';
import { statusDictionary } from '../static/DrugsManagementDictionary';
import { StyledDrugsButton, StyledDrugsTableRow } from '../views/DrugsManagement.styled';
import { PickDateTime, SelectNumber, SerialNumber} from '../components';
import { DATETIME_FORMAT } from "commons/constants/env";
import { editDrugManagement } from "../redux/drugManagementSlice";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import { selectMedicalCenters } from "../../medicalCenters/redux/medicalCentersSlice";
import { useTranslation } from "react-i18next";

export interface Data {
    data: {
        medicalCenterNumber: string | undefined;
        deliveryAt: string | undefined;
        deliveredAt: string | undefined;
        expiredAt: string | undefined;
    };
}

export interface IRowItemProps {
    data: any;
    selectRow: any;
    selectedRows: any[];
    displaySerial?: boolean;
    readRandomId?: boolean;
    unmaskDrugType?: boolean;
}

export const RowItem: React.FC<IRowItemProps> = (props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    /** This state is holding date of row */
    const [rowData, setRowData] = useState<any>();
    const [updatedDateOfRequest, setUpdatedDateOfRequest] = useState(props.data.requestAt);
    const [updatedStatus, setUpdatedStatus] = useState(props.data.status)
    const [isSend, setIsSend] = useState(false);
    const medicalCenters = useAppSelector(selectMedicalCenters);

    /** This function will update row data */
    const handleSave = async () => {
        setIsSend(false);

        let newData: any = {};
        /** Add last values to provider */
        if (props.data.medicalCenter && rowData && !rowData.hasOwnProperty('medicalCenter')) {
            Object.assign(newData, rowData, {medicalCenter: props.data.medicalCenter});
        }
        if (props.data.deliveryAt && rowData && !rowData.hasOwnProperty('deliveryAt')) {
            Object.assign(newData, rowData, {deliveryAt: props.data.deliveryAt});
        }
        if (props.data.deliveredAt && rowData && !rowData.hasOwnProperty('deliveredAt')) {
            Object.assign(newData, rowData, {deliveredAt: props.data.deliveredAt});
        }
        if (props.data.expiredAt && rowData && !rowData.hasOwnProperty('expiredAt')) {
            Object.assign(newData, rowData, {expiredAt: props.data.expiredAt});
        }

        const response = await dispatch(editDrugManagement({id: props.data.id, data: rowData})).unwrap();
        setUpdatedDateOfRequest(response?.requestAt);
        setUpdatedStatus(response?.status)
    };

    const isChecked = () => {
        return props.selectedRows.includes(props.data);
    }

    /** Update button save status */
    useEffect(() => {
        setIsSend(true);
    }, [rowData]);

    return (
        <StyledDrugsTableRow>
            <Checkbox
                disabled={!!props.data.deliveredAt}
                checked={isChecked()}
                onClick={() => props.selectRow(props.data)}
            />
            <span>{props.unmaskDrugType ? (props.data?._embedded?.type?.name || '-') : t('hidden')}</span>
            <span>{props.data?.number}</span>
            {props.displaySerial &&
                <SerialNumber actualValue={props.data?.serial} rowData={rowData} setRowData={setRowData}/>
            }
            <SelectNumber
                setRowData={setRowData}
                rowData={rowData}
                id={props.data.id}
                actualNumber={props.data?._embedded.medicalCenter?.number}
                centersList={medicalCenters?.data}
                readonly={!!props.data.deliveredAt}
            />
            <span>{updatedDateOfRequest ? DateTime.toIso(updatedDateOfRequest, DATETIME_FORMAT) : '-'}</span>
            <PickDateTime
                setRowData={setRowData}
                rowData={rowData}
                id={props.data.id}
                fieldType="expiredAt"
                actualValue={rowData?.expiredAt ?? props.data.expiredAt}
                readonly={!!props.data.deliveredAt}
            />
            <PickDateTime
                setRowData={setRowData}
                rowData={rowData}
                id={props.data.id}
                fieldType="deliveryAt"
                actualValue={rowData?.deliveryAt ?? props.data.deliveryAt}
                readonly={!!props.data.deliveredAt}
            />
            <PickDateTime
                setRowData={setRowData}
                rowData={rowData}
                id={props.data.id}
                fieldType="deliveredAt"
                actualValue={rowData?.deliveredAt ?? props.data.deliveredAt}
                readonly={!!props.data.deliveredAt}
            />
            {props.readRandomId ?
                <span>{props.data?._embedded?.ecrf?.randomId ?? '-'}</span> :
                <span>{DateTime.toIso(props.data?._embedded?.ecrf?.randomAt, DATETIME_FORMAT) || '-'}</span>
            }
            <span>{t(statusDictionary[updatedStatus]) ?? '-'}</span>
            {rowData && isSend && <StyledDrugsButton onClick={handleSave}>{t('save')}</StyledDrugsButton>}
        </StyledDrugsTableRow>
    );
};
