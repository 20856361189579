import { Box, Typography } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { useField } from "formik";
import { useState, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import {
    ButtonOptions,
    EcrfFormParams,
    fetchCollection,
    fetchElementDatas,
    selectCollection,
    selectEcrf,
    selectElementDatas
} from "features/ecrf/redux/ecrfSlice";
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";

export interface ConfirmChildrenButtonFormikProps
    extends Omit<ButtonProps, "name" | "value" | "onChange" | "onBlur" | "error"> {
    name: string;
    buttonText?: string;
    options: ButtonOptions;
    ecrfId: string;
    dataId: string;
    data: any;
    element: any;
    children?: any[]
    elementData: any;
    status: boolean;
}

const ConfirmChildrenButtonFormik = ({
    name,
    options,
    ecrfId,
    data,
    dataId,
    status,
    element,
    children,
    elementData,
    ...props
}: ConfirmChildrenButtonFormikProps) => {
    const dispatch             = useAppDispatch();
    const ecrf                 = useAppSelector(selectEcrf);
    const collection           = useAppSelector(selectCollection)
    const selectedElementDatas = useAppSelector(selectElementDatas)
    const { t } = useTranslation();

    const [field, meta] = useField(name);
    const {id, section} = useParams<EcrfFormParams>();

    const [isDisabled, setIsDisabled] = useState(false);
    const [text, setText] = useState(
        (status ? options.buttonDisabledText : options.buttonText) || element.title || ''
    );
    const [elementCollection, setElementCollection] = useState<any>({})
    const [isLoading, setIsLoading] = useState(false)

    /** handle modal options */
    const [open, setOpen] = useState(false);

    const fetch = useCallback(async () => {
        if (selectedElementDatas?.element?.id === name) {
            setIsLoading(true)
        }

        if (elementData?.id) {
            const ed = await dispatch(fetchElementDatas(elementData?.id)).unwrap()
            ed?.collection && setElementCollection(ed?.collection[0])
        } else if (selectedElementDatas?.element?.id === name) {
            const ed = await dispatch(fetchElementDatas(selectedElementDatas?.id)).unwrap()
            ed?.collection && setElementCollection(ed?.collection[0])
        }

        setIsLoading(false)
    }, [elementData, collection]);

    useEffect(() => {
        if (collection && (collection?.id === element.id) && (children && children?.length > 0)) {
            fetch();
        }
    }, [collection, element, elementData])

    const handleClose = () => {
        setOpen(false)
    }

    /**
     * Change button state when action is already done
     */
    const updateButtonStatus = () => {
        let condition: boolean = false;

        if (options.disabledCondition === 'ecrfStatus' && (ecrf?.status > 0)) {
            condition = true;
        } else if (options.disabledCondition === 'elementStatus' && status) {
            condition = true;
        } else if (options.disabledCondition === 'randomization' && ecrf?.randomId) {
            condition = true;
        }

        if (condition) {
            setIsDisabled(true);
            setText((options.buttonDisabledText ?? options.buttonText) || '');
        }

        switch (elementData?.status) {
            case 5:
                if (options?.confirmDisabledText) {
                    setIsDisabled(false);
                }
                setText(options.buttonDisabledText);
                break;
            default:
                setText(options.buttonText);
        }
    };

    useEffect(() => {
        updateButtonStatus();
    }, [ecrf, elementData, dispatch]);

    const openModalCollection = async (elementId: string, type: string) => {
        if (elementCollection?.children?.length === 0) {
            return;
        }

        handleClose();
        await dispatch(
            fetchCollection({
                id: elementCollection.id,
                children,
                elements: elementCollection?.elements,
                dataId: elementData?.id,
                ecrfId: id || '',
                status: elementData?.status,
                elementId: element?.id,
                elementName: elementData?.id ? `${t('edit')} ${element.label}` : `${t('add')} ${element.label}`,
                elementType: element.type,
                options,
                type: 'save'
            }),
        ).unwrap()
    }

    return (
        <Box pt={0}>
            {options.description && (
                <Box mb={2}>
                    <Typography variant="body2">{options.description}</Typography>
                </Box>
            )}

            <Button
                name={field.name}
                id={field.name}
                value={field.value}
                variant="contained"
                color="primary"
                onClick={() => openModalCollection(element?.id, 'save')}
                disabled={isDisabled}
                {...props}
            >
                {text}
            </Button>
        </Box>
    );
};

export default ConfirmChildrenButtonFormik;
