import { Box, Typography } from "@mui/material";
import { ROUTES_MEDICAL_CENTERS } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import MedicalCenterForm from "features/medicalCenters/components/MedicalCenterForm";
import {
  editMedicalCenter,
  fetchMedicalCenter,
  selectMedicalCenterDetails,
} from "features/medicalCenters/redux/medicalCentersSlice";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import yup from "commons/yup";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import { InitialValuesState } from "features/medicalCenters/components/MedicalCenterForm/MedicalCenterForm";
import {useTranslation} from "react-i18next";

type MedicalCenterEditParams = {
  id: string;
};

const validationSchema = yup.object({
  name: yup.string().required(),
  address: yup.object({
    street: yup.string().required(),
    number: yup.string().required(),
    city: yup.string().required(),
    postcode: yup.string().required(),
    country: yup.string().required(),
  }),
  longitude: yup.string().required(),
  latitude: yup.string().required(),
  number: yup.string().required(),
});

const MedicalCenterEdit = () => {
  const { id } = useParams<MedicalCenterEditParams>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const medicalCenterDetails = useAppSelector(selectMedicalCenterDetails);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const initialValues = {
    name: medicalCenterDetails?.name || "",
    address: {
      street: medicalCenterDetails?.address?.street || "",
      number: medicalCenterDetails?.address?.number || "",
      city: medicalCenterDetails?.address?.city?.id || "",
      postcode: medicalCenterDetails?.address?.postcode || "",
      country: medicalCenterDetails?.address?.city?.country?.id || "",
    },
    longitude: medicalCenterDetails?.longitude || "",
    latitude: medicalCenterDetails?.latitude || "",
    number: medicalCenterDetails?.number || "",
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchMedicalCenter(id));
    }
  }, [dispatch, id]);

  const handleSubmit = async (values: InitialValuesState) => {
    try {
      await dispatch(editMedicalCenter({ id, values }));
      enqueueSnackbar(t('the-research-facility-has-been-updated'), {
        variant: 'success'
      });
      navigate(ROUTES_MEDICAL_CENTERS.LIST);
    } catch (error: any) {
      enqueueSnackbar(error?.detail, {variant: 'error'});
    }
  };

  return (
    <DashboardLayoutWrapper centerChildrenVertically>
      <ColoredBox p={1} width={352} maxWidth="100%" ml="auto" mr="auto">
        <Box mb={3.5}>
          <Typography variant="h5">{t('edit-medical-center')}</Typography>
        </Box>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <MedicalCenterForm />
        </Formik>
      </ColoredBox>
    </DashboardLayoutWrapper>
  );
};

export default MedicalCenterEdit;
