import { isTokenExpired, getToken } from "commons/auth/tokens";
import { ROUTES_CORE } from "commons/constants/paths";
import UserProfileWrapper from "commons/wrappers/UserProfileWrapper";
import { Navigate, useLocation } from "react-router-dom";
import React from "react";

export interface PrivateRouteProps {
  children: JSX.Element
  roles?: string[]
}

const PrivateRoute = ({
  children,
  roles
}: PrivateRouteProps) => {
  let location = useLocation();
  const token = getToken();

  if (!token || isTokenExpired(token)) {
    return <Navigate to={ROUTES_CORE.LOGIN} state={{ from: location }} />;
  }

  return <UserProfileWrapper roles={roles}>{children}</UserProfileWrapper>;
};

export default PrivateRoute;
