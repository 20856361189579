import React from 'react';
import { Box, Checkbox, FormControlLabel, MenuItem, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DATE_FORMAT } from "commons/constants/env";
import { useAppSelector } from "commons/store/hooks";
import { isValid, parse } from "date-fns";
import { ecrfStatusDictionary } from "../../../ecrf/views/EcrfForm/Menu/LeftPanel";
import { selectMedicalCenters } from "../../../medicalCenters/redux/medicalCentersSlice";
import { Filter } from "../Filter";
import {useTranslation} from "react-i18next";

export interface FilterPanelProps {
    displayMedicalCenter?: boolean;
}

export const FilterPanel = (props: FilterPanelProps) => {
    const { displayMedicalCenter = true } = props;
    const medicalCenters = useAppSelector(selectMedicalCenters);
    const { t } = useTranslation();

    const filters: any[] = [
        {
            render: (filter: any, fn: any) => (
                <Box display={'flex'} gap={'20px'} alignItems="center">
                    <Tooltip title={t('start-randomization-range')} arrow>
                        <>
                            <DatePicker
                                label={t('start')}
                                value={
                                    isValid(parse(filter?.['randomAt[after]'], DATE_FORMAT, new Date()))
                                        ? parse(filter?.['randomAt[after]'], DATE_FORMAT, new Date())
                                        : null
                                }
                                format={DATE_FORMAT}
                                onChange={(newValue) => fn(newValue, 'randomAt[after]')}
                                slotProps={{
                                    actionBar: {
                                        actions: ['today', 'clear']
                                    },
                                    textField: {
                                        size: 'small',
                                    }
                                }}
                            />
                        </>
                    </Tooltip>
                    -
                    <Tooltip title={t('end-randomization-range')} arrow>
                        <>
                            <DatePicker
                                label={t('end')}
                                value={
                                    isValid(parse(filter?.['randomAt[before]'], DATE_FORMAT, new Date()))
                                        ? parse(filter?.['randomAt[before]'], DATE_FORMAT, new Date())
                                        : null
                                }
                                format={DATE_FORMAT}
                                onChange={(newValue) => fn(newValue, 'randomAt[before]')}
                                slotProps={{
                                    actionBar: {
                                        actions: ['today', 'clear']
                                    },
                                    textField: {
                                        size: 'small',
                                    }
                                }}
                            />
                        </>
                    </Tooltip>
                </Box>
            ),
            key: 'randomAt',
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('select-research-center')} arrow>
                    <TextField
                        label={t('medical-center')}
                        select
                        fullWidth
                        variant={'outlined'}
                        size={'small'}
                        value={filter?.medicalCenter || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'medicalCenter')
                        }
                    >
                        {/** Display list of all numbers */}
                        {medicalCenters?.data?.map((center: any, index) => (
                            <MenuItem key={center?.id} value={center.id}>
                                {center?.number} {' | '} {center?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip>
            ),
            key: 'medicalCenter',
            // hide: displayMedicalCenter,
            hide: false,
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('randomization-number')} arrow>
                    <TextField
                        label={t('randomization-number')}
                        value={filter?.randomId || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'randomId')
                        }
                        fullWidth
                        variant={'outlined'}
                        size={'small'}
                    />
                </Tooltip>
            ),
            key: 'randomId',
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('study-status')} arrow>
                    <TextField
                        label={t('study-status')}
                        select
                        fullWidth
                        variant={'outlined'}
                        value={filter?.status || ''}
                        size={'small'}
                        onChange={(event) => fn(event.target.value, 'status')}
                    >
                        {/** Display list of all numbers */}
                        {ecrfStatusDictionary.map((status: string, index) => (
                            <MenuItem key={status} value={String(index)}>
                                {status}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip>
            ),
            key: 'status',
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('show-studies-with-unclosed-queries')} arrow>
                    <FormControlLabel
                        control={<Checkbox
                            onChange={(event) =>
                                fn(event.target.checked, 'exists[elements.comment.children]')
                            }
                            checked={filter?.['exists[elements.comment.children]'] || false}
                        />}
                        label={t('containing-queries')}/>
                </Tooltip>
            ),
            key: 'exists[elements.comment.children]',
        },
    ];

    return <Filter elements={filters} />;
};
