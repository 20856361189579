/*
 * Project: refsal-ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 13.01.2022
 * Time: 16:31
 */

import styled from 'styled-components';
import {
    COLOR_BG,
    COLOR_BG_DARK,
    COLOR_PRIMARY,
    COLOR_PRIMARY_DARK,
    COLOR_PRIMARY_LIGHT,
    COLOR_SECONDARY, RESEARCH
} from "../../../../commons/constants/env";

/** Main layout container */
export const StyledDrugsWrapper = styled.div`
    margin-top: 120px;
    display: grid;
    color: ${COLOR_SECONDARY};
    place-items: center;
`;

export const StyledDrugsLayout = styled.div`
    width: 100%;
    max-width: 90vw;
    color: #060a32;
`;

export const StyledDrugsTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
     white-space: nowrap;
`;

export const StyledDrugsTitle2 = styled.div`
    font-size: 15px;
    opacity: 50%;
    white-space: nowrap;
`;

/** Filters */
export const StyledDrugsFiltersWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
`;

export const StyledDrugsFiltersItems = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
`;

export const StyledDrugsFiltersItemsFilters = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const StyledDrugsButton = styled.div<any>`
    display: flex;
    padding: 8px 30px;
    color: ${COLOR_PRIMARY};
    gap: 12px;
    background-color: ${(props) => (props?.selectedFilter === props?.id && props?.id ? "#e0eafd" : COLOR_PRIMARY_LIGHT)};
    border-radius: 10px;
    border: ${(props) => (props?.selectedFilter === props?.id && props?.id ? "1px solid #94b5ff" : `1px solid ${COLOR_PRIMARY_LIGHT}`)};
    cursor: pointer;
    transition: 0.3s ease;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    &:hover {
        border: 1px solid ${COLOR_PRIMARY_DARK};
    }
`;

export const StyledDrugsButtonFilter = styled.div<any>`
    display: flex;
    padding: 8px 30px;
    color: ${(props) => (props?.selectedFilter === props?.id && props?.id ? COLOR_PRIMARY : '#7a7f98;')};
    gap: 12px;
    background-color: ${(props) => (props?.selectedFilter === props?.id && props?.id ? COLOR_PRIMARY_LIGHT : COLOR_BG)};
    border: ${(props) => (props?.selectedFilter === props?.id && props?.id ? `1px solid ${COLOR_PRIMARY}` : `1px solid ${COLOR_PRIMARY_LIGHT}`)};
    cursor: pointer;
    transition: 0.3s ease;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    &:hover {
        border: 1px solid ${COLOR_PRIMARY};
    }
`;

/** Table */
export const StyledDrugsTable = styled.div`
    display: grid;
    position: relative;
`;

export const StyledDrugsTableHead = styled.div`
	position: -webkit-sticky;
	position: sticky;	
	z-index: 1;
	top: 55px;
    display: grid;
    background-color: ${COLOR_BG_DARK};
    color: ${COLOR_SECONDARY};
    place-items: center;
    text-align: center;
    padding: 20px 3px;
    margin-top: ${RESEARCH === 'IMPROVE' ? '20px' : '50px'};
    border-radius: 10px;
    font-size: 14px;
    gap: 20px;
    margin-bottom: 5px;
    grid-template-columns: 30px minmax(100px, 1fr) 80px minmax(100px, 1fr) 80px repeat(1, minmax(100px, 1fr)) 200px 200px 200px repeat(2, minmax(100px, 1fr)) 100px;
`;

export const StyledDrugsTableBody = styled.div`
    display: grid;
`;

export const StyledDrugsTableRow = styled.div`
    display: grid;
    font-size: 14px;
    padding: 3px;
    marign-top: 5px;
    place-items: center;
    text-align: center;
    marignl-bottom: 5px;
    gap: 20px;
    grid-template-columns: 30px minmax(100px, 1fr) 80px minmax(100px, 1fr) 80px repeat(1, minmax(100px, 1fr)) 200px 200px 200px repeat(2, minmax(100px, 1fr)) 100px;
    transition: 0.2s ease;
    border-radius: 5px;

    &:hover {
        background-color: ${COLOR_BG_DARK};
    }
`;

export const StyledDrugsSelect = styled.div<any>`
    display: flex;
    padding: 8px 20px;
    gap: 12px;
    border: ${(props) => (props.readonly ? `1px solid ${COLOR_PRIMARY_LIGHT}` : `1px solid ${COLOR_PRIMARY}`)};
    border-radius: 5px;
    color: ${(props) => (props.readonly ? '#6D6F82' : '')};
    cursor: ${(props) => (props.readonly ? '' : 'pointer')};
    transition: 0.3s ease;
    align-items: center;
    width: 60%;
    justify-content: center;

    &:hover {
        background-color: ${(props) => (props.readonly ? '' : COLOR_PRIMARY_LIGHT)};
        border: ${(props) => (props.readonly ? `1px solid ${COLOR_PRIMARY_LIGHT}` : `1px solid ${COLOR_PRIMARY}`)};
    }
`;

export const StyledDrugsDate = styled.input<any>`
    display: flex;
    padding: 8px 10px;
 border: ${(props) => (props.readonly ? `1px solid ${COLOR_PRIMARY_LIGHT}` : `1px solid ${COLOR_PRIMARY}`)};
    border-radius: 5px;
    cursor: text;
    transition: 0.3s ease;
    align-items: center;
    width: 90%;
    background: none;
    color: ${(props) => (props.readonly ? '#6D6F82' : '')};

    &:hover {
        background-color: ${(props) => (props.readonly ? '' : COLOR_PRIMARY_LIGHT)};
        border: ${(props) => (props.readonly ? `1px solid ${COLOR_PRIMARY_LIGHT}` : `1px solid ${COLOR_PRIMARY}`)};
    }
`;

export const StyledDrugsMultiSaveWindow = styled.div`
    width: 100%;
    height: 120px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${COLOR_BG_DARK};
    display: grid;
    place-items: center;
    grid-template-columns: repeat(7, minmax(100px, 1fr));
    z-index: 10;
    gap: 10px;
`;

export const StyledDrugsMultiSaveSelected = styled.div`
   display: flex;
   gap: 10px;
   flex-wrap: wrap;
   overflow-y: scroll;
   max-height: 80px;
   padding: 20px 30px;
   
    &::-webkit-scrollbar {
        all: unset;
        height: 3px;
        width: 3px;
        padding-top: 15px;
        background-color: #EEF5FF;
    }

    ::-webkit-scrollbar-thumb {
        background: #175aef;
        height: 3px;
        border-radius: 9999px;
        cursor: auto;
        opacity: 50%;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #175aef;
        cursor: auto;
    }
`;

export const StyledDrugsMultiSaveItem = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
`;

export const StyledDrugsMultiSaveItemTitle = styled.div`
    color: #7a7f98;
    font-size: 14px;
    margin-bottom: 10px;
`;