import Box, { BoxProps } from "@mui/material/Box";
import { COLOR_BG_DARK } from "commons/constants/env";

export interface ColoredBoxProps
    extends Omit<BoxProps, "bgcolor" | "borderRadius" | "boxShadow"> {
}

const ColoredBox = (props: ColoredBoxProps) => {
    return <Box mt={3} bgcolor={COLOR_BG_DARK} borderRadius="10px" {...props}/>;
};

export default ColoredBox;
