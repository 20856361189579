export const ROUTES_CORE = {
    HOME: '/',
    LOGOUT: '/logout',
    LOGIN: '/login',
    RESET_PASSWORD: (token: string) => `/reset-password/${token}`,
    FORGOT_PASSWORD: '/forgot-password',
};

export const ROUTES_DASHBOARD = {
    DASHBOARD: '/dashboard',
    DETAILS: (type: string) => `/dashboard/${type}`,
};

export const ROUTES_USERS = {
    LIST: '/users',
    ADD: `/users/add`,
    EDIT: (id: string) => `/users/${id}/edit`,
    DETAILS: (id: string) => `/users/${id}/details`,
};

export const ROUTES_MEDICAL_CENTERS = {
    LIST: '/medical-centers',
    ADD: `/medical-centers/add`,
    EDIT: (id: string) => `/medical-centers/${id}/edit`,
    DETAILS: (id: string) => `/medical-centers/${id}/details`,
};

export const ROUTES_ECRF = {
    DETAILS: (id: string) => `/ecrf/${id}`,
    DETAILS_EXTENDED: (id: string, section: string) => `/ecrf/${id}/${section}`,
    DETAILS_FULL_EXTENDED: (id: string, section: string, element?: string) => `/ecrf/${id}/${section}/${element}`,
};

export const ROUTES_DRUG = {
    LIST: '/drugs',
    ADD: `/drugs/add`,
    EDIT: (id: string) => `/drugs/${id}/edit`,
    DETAILS: (id: string) => `/drugs/${id}/details`,
};

export const ROUTES_DRUG_MANAGEMENT = {
    MODULE: '/drugs-management',
};

export const ROUTES_DOCUMENTATION_MANAGEMENT = {
    MODULE: '/documentation-management',
};

export const ROUTES_COMMENTS = {
    LIST: '/comments',
    ADD: `/comments/add`,
    EDIT: (id: string) => `/comments/${id}/edit`,
    DETAILS: (id: string) => `/comments/${id}/details`,
};
