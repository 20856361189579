import { removeToken } from "commons/auth/tokens";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { ROUTES_CORE } from "commons/constants/paths";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "commons/store/hooks";
import { logout } from "core/redux/userSlice";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetch = async () => {
      await dispatch(logout());
      removeToken();
      navigate(ROUTES_CORE.LOGIN);
    };
    fetch();
  }, [dispatch, navigate]);

  return (
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={50} />
    </Box>
  );
};

export default Logout;
