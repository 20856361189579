/*
 * Project: ecrf-front
 * Author: Levan Ostrowski
 * User: cod3venom
 * Date: 15.01.2022
 * Time: 09:26
*/

import {AxiosInstance} from "axios";
import DocUploader from "./AbstractResources/DocUploader";
import DocManager from "./AbstractResources/DocManager";

export default class AbstractResource {

    /**
     * HttpClient instance
     * @protected
     */
    protected httpClient: AxiosInstance;

    /**
     * @param httpClient
     * @protected
     */
    protected constructor(httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }

    public docManager(): DocManager {
        return (new DocManager(this.httpClient));
    }

    public docUploader(): DocUploader {
        return (new DocUploader(this.httpClient));
    }
}