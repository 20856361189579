import { createTheme } from '@mui/material/styles';
import { plPL } from '@mui/material/locale';
import {
    COLOR_BG,
    COLOR_PRIMARY,
    COLOR_PRIMARY_DARK,
    COLOR_PRIMARY_LIGHT,
    COLOR_SECONDARY,
    COLOR_SECONDARY_DARK,
    COLOR_SECONDARY_LIGHT
} from "../constants/env";

export default createTheme({
    palette: {
        primary: {
            light: COLOR_PRIMARY_LIGHT,
            main: COLOR_PRIMARY,
            dark: COLOR_PRIMARY_DARK,
            contrastText: COLOR_BG,
        },
        secondary: {
            light: COLOR_SECONDARY_LIGHT,
            main: COLOR_SECONDARY,
            dark: COLOR_SECONDARY_DARK,
            contrastText: COLOR_BG,
        },
    },
    typography: {
        body1: {
            fontSize: "14px"
        }
    }
}, plPL);
