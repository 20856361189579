export const getRoleByRoleId = (role: string | string[] | undefined) => {
  if (!role) {
    return '-';
  }

  if (Array.isArray(role)) {
    role = role[0];
  }

  switch (role) {
    case 'ROLE_ADMIN':
      return 'Administrator';
    case 'ROLE_CRO':
      return 'CRO';
    case 'ROLE_MONITOR':
      return 'Monitor';
    case 'ROLE_MEDICAL_MONITOR':
      return 'Monitor medyczny';
    case 'ROLE_COORDINATOR':
      return 'Koordynator badania';
    case 'ROLE_DATA_ENTRY':
      return 'Data entry';
    case 'ROLE_DATA_MANAGER':
      return 'Manager danych';
    case 'ROLE_DATA_MANAGER_ECHO':
      return 'Manager danych ECHO';
    case 'ROLE_DATA_MANAGER_RTG':
      return 'Manager danych RTG';
    case 'ROLE_SPONSOR':
      return 'Sponsor';
    case 'ROLE_C_RESEARCHER':
      return 'Główny badacz';
    case 'ROLE_RESEARCHER':
      return 'Badacz';
    case 'ROLE_PHARMACIST':
      return 'Farmaceuta';
    default:
      return role;
  }
};
