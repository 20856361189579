import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { RootState } from "commons/store/store";
import { Notification } from 'types/api.types'

export interface NotificationsState {
    notifications: Notification[]
}

const initialState: NotificationsState = {
    notifications: []
};

type fetchNotificationsParams = {
    page: number
    itemsPerPage: number
}

export const fetchNotifications = createAsyncThunk(
    "notifications/fetchNotifications",
    async ({page, itemsPerPage}: fetchNotificationsParams) => {
        const response =
            await axiosSecureInstance.get<Notification[]>(
                `/api/notifications`, {
                    params: {
                        pagination: true,
                        page,
                        itemsPerPage
                    },
                });
        return response.data;
    }
);

export const readNotifications = createAsyncThunk(
    "notifications/readNotifications",
    async () => {
        const response = await axiosSecureInstance.put<any[]>(`/api/notifications/seen`, {});
        return response.data;
    }
);

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchNotifications.fulfilled, (state: NotificationsState, action) => {
            state.notifications = action.payload;
        });
    },
});

export const selectNotifications = (state: RootState) => state.notifications.notification.notifications;

export default notificationsSlice.reducer;
