import styled, { css } from "styled-components";
import { color } from "./ColoredIconWrapper";

export interface StyledIconProps {
  $color: color;
}

const getFillColor = css<StyledIconProps>`
  fill: ${({ $color, theme }) => {
    if ($color === "default") {
      return `rgba(0, 0, 0, 0.54)`;
    } else if ($color === "white") {
      return "#ffffff";
    } else {
      return theme.palette[$color].main;
    }
  }};
`;

export const StyledColoredIconWrapper = styled.span<StyledIconProps>`
  ${getFillColor}
  font-size: 0;
  .MuiSvgIcon-root {
    ${getFillColor};
    & * {
      ${getFillColor};
    }
  }
`;
